import MembersTruncatedList from 'components/MembersTruncatedList/MembersTruncatedList';
import {TableCell, TableRow} from 'components/Table';
import {Tag} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {setCheckedTagsProjectAC} from 'store/project/setCheckedTags/actionCreator';
import {setProjectFilters} from 'store/project/setProjectFilters/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {isAdmin} from 'utils/getUserRole';
import {displayTime} from 'utils/helpers/displayTime';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

export default function DashboardRows() {
  const dispatch = useSetGlobalState();

  const projectState = useGlobalState((state) => state.project);
  const {projects, projectFilters, checkedTags} = projectState;
  const {user} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();

  const handleFavoriteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, project: SingleProject) => {
    e.stopPropagation();
    const payload = {id: project.id, filters: projectFilters};
    if (project.favorite) dispatch(removeFavoriteProjectAC(payload));
    else dispatch(addFavoriteProjectAC(payload));
  };

  const handleTagFilter = (e: React.MouseEvent, tagID: number) => {
    e.stopPropagation();

    let checkedTagPayload: number[] = [];
    if (checkedTags.includes(tagID)) checkedTagPayload = checkedTags.filter((tag) => tag !== tagID);
    else checkedTagPayload = [...checkedTags, tagID];

    dispatch(setCheckedTagsProjectAC(checkedTagPayload));

    const tagIds = checkedTagPayload.map(String).join(',');
    const payload = {...projectFilters, tagIds};
    if (tagIds.length) dispatch(setProjectFilters(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setProjectFilters(newPayload));
    }
  };

  const renderTags = (tags: SingleProject['tags']) => {
    if (tags.length <= 3) {
      return tags.map((tag) => (
        <div key={`project-tag-${tag.id}`} role="button" tabIndex={0} onClick={(e) => handleTagFilter(e, tag.id)}>
          <Tag variant={tag.color}>{tag.name}</Tag>
        </div>
      ));
    }

    return (
      <>
        {tags.slice(0, 3).map((tag) => (
          <Tag key={`project-tag-${tag.id}`} variant={tag.color}>
            {tag.name}
          </Tag>
        ))}
        <TruncateBadge num={tags.length - 3} />
      </>
    );
  };

  const handleSingleView = (project: SingleProject) => {
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(project));
  };

  const renderRows = () => {
    if (projects.length) {
      return projects.map((project) => {
        return (
          <TableRow key={`projects-${project.id}`} onClick={() => handleSingleView(project)}>
            <TableCell className="dashboard-table__star">
              <button onClick={(e) => handleFavoriteClick(e, project)}>
                <i className={`far fa-star ${project.favorite && 'starred'}`} />
              </button>
            </TableCell>
            <TableCell colSpan={user && isAdmin(user) ? 2 : 1} className="dashboard-table__project-column">
              {renderProjectTitle(project.title, 40)}
            </TableCell>
            <TableCell>{project.date && moment(project.date).format('DD/MM/YYYY')}</TableCell>
            <TableCell className="dashboard-table__tag-column">{renderTags(project.tags)}</TableCell>
            <TableCell>
              <div>
                <div className="table__truncated">
                  {project.organizers && (
                    <MembersTruncatedList
                      membersLength={project.organizers.length}
                      firstMemberName={project.organizers[0]?.name || ''}
                    />
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="table__truncated">
                {project.members.length > 0 ? (
                  <MembersTruncatedList
                    membersLength={project.members.length}
                    firstMemberName={project.members[0]?.name || ''}
                  />
                ) : (
                  '/'
                )}
              </div>
            </TableCell>
            <TableCell className="light">
              <div className="d-flex">
                <i className="far fa-paperclip" style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}} />
                {project.documents.length > 0 && `(${project.documents.length})`}
              </div>
            </TableCell>
            <TableCell className="light"> {project.updatedOn && displayTime(project.updatedOn, t)}</TableCell>
            <TableCell className="light" />
          </TableRow>
        );
      });
    }

    return null;
  };
  return <>{renderRows()}</>;
}
