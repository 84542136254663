import {useEffect} from 'react';

import {Pagination} from 'components/Pagination/Pagination';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import getArchivedProjectsACS from 'store/archive/getArchivedProjects/actionCreator';
import {setArchiveFiltersAC} from 'store/archive/setArchiveFilters/actionCreator';
import './ArchiveTable.scss';
import {showPagination} from 'utils/helpers/showPagination';

import {DesktopTable} from './DesktopScreen/DesktopTable';
import {MobileAndTabletTable} from './MobileAndTabletScreen/MobileAndTabletTable';

export default function ArchiveTable() {
  const dispatch = useSetGlobalState();

  const {archivedProjects, filters, pagination} = useGlobalState((state) => state.archive);
  const isTabletAndUp = useMediaBreakpoint('up', 'lg');
  const filtersCopy = cloneDeep(filters);

  useEffect(() => {
    dispatch(getArchivedProjectsACS(filters));
    // eslint-disable-next-line
  }, [filters]);

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();
    let payload;

    let page = filtersCopy.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...filtersCopy, page: (page += 1)};
    else payload = {...filtersCopy, page: (page -= 1)};
    dispatch(setArchiveFiltersAC(payload));
  };

  return (
    <div>
      {isTabletAndUp ? (
        <DesktopTable archivedProjects={archivedProjects} />
      ) : (
        <MobileAndTabletTable archivedProjects={archivedProjects} />
      )}
      {showPagination({list: archivedProjects, filters}) && (
        <Pagination first={pagination.first} last={pagination.last} handleClick={handlePageChange} />
      )}
    </div>
  );
}
