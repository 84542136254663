import {GetUsersDTO, UserActivity} from 'typescript/dto/GetUsersDTO';

export const sortByStatusHelper = (filters: GetUsersDTO) => {
  let activity: UserActivity;
  let payload;
  if (filters.active === 'ALL') {
    activity = 'ACTIVE';
    payload = {...filters, active: activity};
  } else if (filters.active === 'ACTIVE') {
    activity = 'INACTIVE';
    payload = {...filters, active: activity};
  } else {
    activity = 'ALL';
    payload = {...filters, active: activity};
  }

  return payload;
};
