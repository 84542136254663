import {Routes} from 'constants/routes';

import Button from 'components/Button/Button';
import {Field, Form, Formik} from 'formik';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import LogoSmall from 'images/logo-small.png';
import Logo from 'images/logo.png';
import {AuthLayout} from 'layouts/AuthLayout/AuthLayout';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {createSetup} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';

import apiSetupRegistrationSchema from './apiSetupRegistrationSchema';

interface IInputProps {
  name: string;
  password: string;
  email: string;
}

export const ApiSetupRegistration = () => {
  const dispatch = useSetGlobalState();

  const history = useHistory();
  const initValues = {name: '', password: '', email: '', confirmPassword: ''};

  const handleRegister = async (inputValues: IInputProps) => {
    const {name, password, email} = inputValues;

    await createSetup({name, password, email, unitName: 'Admin', positionName: 'Administrator'})
      .then(() => {
        history.push(Routes.LOGIN);
      })
      .catch(() => {
        dispatch(setGlobalNotification({message: 'User created unsuccessfully', variant: 'error'}));
      });
  };

  return (
    <AuthLayout pageName="register">
      <img className="d-none d-md-block" width="157" src={Logo} alt="" />
      <img className="d-md-none" width="94" src={LogoSmall} alt="" />
      <h1>Otvorite Vaš nalog</h1>
      <Formik
        initialValues={initValues}
        onSubmit={handleRegister}
        validationSchema={apiSetupRegistrationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          const {errors} = props;
          return (
            <Form className="form form--login">
              <div className="form__group">
                <label className="d-block" htmlFor="name">
                  Ime i prezime <span className="required">*</span>
                </label>
                <Field name="name" type="text" className={`${errors.name ? 'form__error' : ''}`} id="name" />
                {errors.name && <span className="form__validation">{errors.name}</span>}
              </div>
              <div className="form__group">
                <label className="d-block" htmlFor="email">
                  E-mail <span className="required">*</span>
                </label>
                <Field name="email" type="text" className={`${errors.email ? 'form__error' : ''}`} id="email" />
                {errors.email && <span className="form__validation">{errors.email}</span>}
              </div>
              <div className="form__group">
                <label className="d-block" htmlFor="password">
                  Lozinka <span className="required">*</span>
                </label>
                <Field
                  name="password"
                  type="password"
                  className={`${errors.password ? 'form__error' : ''}`}
                  id="password"
                />
                {errors.password && <span className="form__validation">{errors.password}</span>}
              </div>
              <div className="form__group">
                <label className="d-block" htmlFor="confirm-password">
                  Potvrdite lozinku <span className="required">*</span>
                </label>
                <Field
                  name="confirmPassword"
                  type="password"
                  className={`${errors.confirmPassword ? 'form__error' : ''}`}
                  id="confirm-password"
                />
                {errors.confirmPassword && <span className="form__validation">{errors.confirmPassword}</span>}
              </div>
              <div className="button__wrap justify-content-center">
                <Button type="submit" variant="primary">
                  prijavite se
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <p className="auth-layout__link">
        Već imate nalog? <Link to={Routes.LOGIN}>Prijavite se.</Link>
      </p>
    </AuthLayout>
  );
};
