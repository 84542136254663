import {IUpdateUser} from 'typescript/interfaces/IUserActions';

import {UserActions} from '../actions';

export const updateUserACS = ({data, refreshData}: IUpdateUser['payload']) => {
  return {
    type: UserActions.UPDATE_USER,
    payload: {data, refreshData},
  };
};
