import {AxiosResponse} from 'axios';
import {cloneDeep} from 'lodash';
import {call, put, select} from 'redux-saga/effects';
import {disableUser} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {IDisableUserModel} from 'typescript/interfaces/IUserActions';
import {isRequestSuccessful} from 'utils/helpers/isRequestSuccessful';

import {setUsersAC} from '../../setUsers/actionCreator';

export function* disableUsersSaga({payload}: IDisableUserModel) {
  const {userFilters} = yield select((state) => state.users);

  try {
    const response: AxiosResponse<string> = yield call(disableUser, payload.id!);

    if (isRequestSuccessful(response)) {
      const newListOfUsers = cloneDeep(payload.users).map((member) => {
        const matchingUser = member.id === payload.id;
        if (matchingUser) member.active = false;
        return member;
      });
      yield put(setUsersAC(newListOfUsers));
      yield put(toggleModalAC({isOpen: false, component: null}));
      yield put(
        setGlobalNotification({
          variant: 'success',
          message: 'User disabled successfully',
          icon: 'fa-user',
        }),
      );
      yield put(getUsersACS(userFilters));
    }
  } catch (error) {
    yield put(setGlobalNotification({variant: 'error', message: 'User disabled unsuccessfully'}));
  }
}
