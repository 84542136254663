import React from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import SingleTag from 'components/Modal/Project/SingleTag/SingleTag.';
import {TableCell, TableRow} from 'components/Table';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagDetail} from 'typescript/models/TagDetails';

interface IProps {
  tag: TagDetail;
  filters: GetTagDetailsDTO;
}

export const TagList = ({tag}: IProps) => {
  const dispatch = useSetGlobalState();

  const {checkedTags} = useGlobalState((state) => state.tag);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} =
    useDropdown<HTMLTableCellElement>({
      position: 'bottom-start',
      offset: [2, 3],
    });

  const handleEdit = () => {
    toggleOpen();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) toggleOpen();
    if (e.key === 'Enter' && !isOpen) toggleOpen();
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleOnKeyDown,
  };

  const checkRow = (value: number) => {
    const checkedTagId = value;

    let checkedPayload: number[] = [];
    if (checkedTags.includes(checkedTagId)) {
      const filteredProjects = checkedTags.filter((checkedTag) => checkedTag !== checkedTagId);
      checkedPayload = filteredProjects;
    } else {
      checkedPayload = [...checkedTags, checkedTagId];
    }
    dispatch(setCheckedTagsAC(checkedPayload));
  };

  return (
    <TableRow key={tag.id} className="tag-table__row">
      <TableCell className="tag-table__row-checkbox">
        <div className="table__check-wrap">
          <Checkbox id={`${tag.id}`} onChange={checkRow} checked={checkedTags.includes(tag.id)} className="checkbox" />
        </div>
      </TableCell>
      <TableCell className="table__empty-row" />
      <TableCell className="tag-table__row-icon">
        <span className="tag-table__row-tag" style={{backgroundColor: `${tag.color}`}}>
          {tag.name}
        </span>
        <div ref={containerRef}>
          <div ref={setReferenceElement}>
            <button
              onClick={handleEdit}
              className={`tag-table__row-icon-edit ${isOpen ? 'tag-table__row-icon-edit--active' : ''}`}
            >
              <i className="far fa-pencil" />
            </button>
          </div>
          <div ref={setPopperElement} className="project__tags-overview" style={styles.popper} {...attributes.popper}>
            {isOpen && <SingleTag handleEdit={handleEdit} {...dropdownProps} tag={tag} />}
          </div>
        </div>
      </TableCell>
      <TableCell className="tag-table__row-project">{tag.numberOfProject}</TableCell>
      <TableCell colSpan={2} />
    </TableRow>
  );
};
