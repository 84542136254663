import CloseModalButton from '../shared/CloseModalButton';

export default function SuccessDialog() {
  return (
    <div className="confirm-dialog">
      <CloseModalButton />

      <div className="confirm-dialog__wrap confirm-dialog__wrap--text">
        <div className="confirm-dialog--success">
          <i className="far fa-check-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>Uspešno ste dodelili novog organizatora.</h6>
        </div>
      </div>
    </div>
  );
}
