import {SingleProject} from 'typescript/models/SingleProject';
import {User} from 'typescript/models/User';

export const isUserOrganizer = (project: SingleProject | null, user: User | null) => {
  let payload = false;

  if (project?.id && user) {
    const isOrganizer = project.organizers.find((organizer) => organizer.id === user.id);
    payload = isOrganizer ? true : false;
  }
  return payload;
};
