import {useState} from 'react';

import FileChooser from 'components/FileChooser/FileChooser';
import MobileDocumentsList from 'components/MobileDocumentsList/MobileDocumentsList';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setDeletedProjectAC} from 'store/deletedProjects/setDeletedProject/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSelectedProjectAC} from 'store/project/setSelectedProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {isDeleted} from 'utils/helpers/isDeleted';
import isMobileView from 'utils/helpers/isMobileView';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';
import isSmallScreen from 'utils/helpers/isSmallScreen';

import {DocumentList} from './DocumentList';
import {DocumentZeroState} from './DocumentZeroState';

interface IProps {
  singleProject: SingleProject;
}

export default function Documents({singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const [isTogglerOpen, setIsTogglerOpen] = useState(false);

  const isArchived = singleProject.archived;
  const hasDocumentsList = singleProject.documents.length > 0;
  const setStart = () => (isMobileView() ? 'bottom-start' : 'left');

  const handleUnarchiveProject = () => {
    dispatch(setSelectedProjectAC(singleProject));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_UNARCHIVE_PROJECT}));
  };

  const handleActivateProject = () => {
    dispatch(setDeletedProjectAC(singleProject.id));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ACTIVATE_PROJECT}));
  };

  const renderToggler = () => {
    if (isSmallScreen()) {
      return (
        <button className="project__document--toggler">
          <i className="far fa-paperclip" />
        </button>
      );
    }
    return (
      <button className={`project__title-more${isTogglerOpen ? ' project__title-more--open' : ''}`}>
        <i className="far fa-plus" />
      </button>
    );
  };

  const renderDropdown = () => {
    if (!isSmallScreen()) {
      return (
        <FileChooser
          toggler={renderToggler()}
          start={setStart()}
          singleProject={singleProject}
          setIsTogglerOpen={setIsTogglerOpen}
        />
      );
    }
    return <MobileDocumentsList documents={singleProject?.documents || []} />;
  };

  return (
    <>
      <div className="project__title">
        <h3 className="d-none d-md-block">{t('documents')}</h3>
        {isNotArchivedOrDeleted(singleProject) && renderDropdown()}
        {isArchived && isSmallScreen() && (
          <div className="project-archived__action" role="button" onClick={handleUnarchiveProject}>
            <i className="far fa-undo" />
          </div>
        )}
        {isDeleted(singleProject) && isSmallScreen() && (
          <div className="project-archived__action" role="button" onClick={handleActivateProject}>
            <i className="far fa-inbox-out" />
          </div>
        )}
      </div>
      <div className="project__documents">
        {hasDocumentsList ? (
          <DocumentList singleProject={singleProject} isArchived={isArchived} />
        ) : (
          <DocumentZeroState />
        )}
      </div>
    </>
  );
}
