import {ChangeEvent} from 'react';

import './NavbarProjectSearch.scss';

import {SearchInput} from 'components/SearchInput/SearchInput';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';

import {dipslayProperPlaceholder} from './Helpers/dipslayProperPlaceholder';
import {dispatchProperFetchAndSearchFunction} from './Helpers/dispatchProperFetchFunction';

export default function NavbarProjectSearch() {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {projectFilters} = useGlobalState((state) => state.project);

  const location = useLocation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const defaultPayload = {dispatch, pathname: location.pathname, projectFilters};
    let payload;
    if (e.target.value === '') payload = {...defaultPayload, shouldSearch: false};
    else payload = {...defaultPayload, shouldSearch: true, value: e.target.value};

    dispatchProperFetchAndSearchFunction(payload);
  };

  const debounced = debounce(handleChange, 700);

  return (
    <div className="navbar-search">
      <SearchInput
        id="project_search"
        placeholder={t(dipslayProperPlaceholder(location.pathname))}
        onChange={debounced}
      />
    </div>
  );
}
