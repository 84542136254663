import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setRemainderTypeAC} from 'store/project/setRemainderType/actionCreator';
import {RemainderType} from 'typescript/models/RemainderType';

interface IProps {
  errorMessage: boolean;
  setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TypeOfNotifications = ({setErrorMessage, errorMessage}: IProps) => {
  const dispatch = useSetGlobalState();
  const typeOfNotifications: RemainderType = ['e-mail', 'e-protocol'];
  const {remainderTypes} = useGlobalState((state) => state.project);

  const {t} = useTranslation();

  const handleChoseTypeOfNotifications = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenType = e.target.id;
    setErrorMessage(false);

    let notificationTypePayload;
    if (remainderTypes.includes(chosenType)) {
      const filteredNotificationType = remainderTypes.filter((notificationType) => notificationType !== chosenType);
      notificationTypePayload = filteredNotificationType;
    } else notificationTypePayload = [...remainderTypes, chosenType];

    dispatch(setRemainderTypeAC(notificationTypePayload));
  };

  return (
    <div className="project-remainder__wrapper">
      <p className="paragraph">{t('add-way-off-remainder')}</p>
      <div className="project-remainder__notifications">
        {typeOfNotifications.map((notificationType) => {
          return (
            <label htmlFor={notificationType} key={notificationType}>
              <input
                type="checkbox"
                id={notificationType}
                name={notificationType}
                onChange={handleChoseTypeOfNotifications}
                checked={remainderTypes.includes(notificationType)}
              />
              {notificationType === 'e-mail' ? <p>{t('E-mail')}</p> : <p>{t('eProtokol')}</p>}
              {errorMessage && <div className="error">{t('add-remainder-description')}</div>}
            </label>
          );
        })}
      </div>
    </div>
  );
};
