import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import {Pagination} from 'components/Pagination/Pagination';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setCheckedDeletedProjectsAC} from 'store/deletedProjects/setCheckedDeletedProjects/actionCreator';
import {setDeletedProjectsFiltersAC} from 'store/deletedProjects/setDeletedProjectsFilters/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {showPagination} from 'utils/helpers/showPagination';

import {Tags} from '../Tags';
import {Header} from './Header';

import './MobileTable.scss';

export const MobileAndTabletTable = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {checkedProjects, projects, projectFilters, pagination} = useGlobalState((state) => state.deletedProjects);

  const hasProjectsList = projects.length > 0;

  const checkRow = (value: number) => {
    const checkProjectId = value;
    let checkedPayload;
    if (checkedProjects.includes(checkProjectId)) {
      checkedPayload = checkedProjects.filter((project) => project !== checkProjectId);
    } else {
      checkedPayload = [...checkedProjects, checkProjectId];
    }
    dispatch(setCheckedDeletedProjectsAC(checkedPayload));
  };

  const handleProjectClick = (project: SingleProject) => {
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC({...project, deleted: true}));
  };

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();

    let payload;
    let page = projectFilters.page!;
    const isValueIncrement = value === 'increment';

    if (isValueIncrement) payload = {...projectFilters, page: (page += 1)};
    else payload = {...projectFilters, page: (page -= 1)};

    dispatch(setDeletedProjectsFiltersAC(payload));
  };

  return (
    <div className="grid">
      <Header deletedProjects={projects} checkedProjects={checkedProjects} />
      {hasProjectsList &&
        projects.map((project) => {
          return (
            <div className="grid__row" key={project.id} role="button" onClick={() => handleProjectClick(project)}>
              <div className="grid__check" role="button" onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  id={`${project.id}`}
                  onChange={checkRow}
                  checked={checkedProjects.includes(project.id)}
                  className="checkbox"
                />
              </div>
              <div className="grid__content">
                <p className="deleted-projects-mobile-table__row-title">{project.title}</p>
                <span className="grid__date">{project.date}</span>
                <div className="d-flex align-items-center">
                  <Tags project={project} />
                </div>
              </div>
              <div className="grid__document">
                <i className="far fa-paperclip" style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}} />
                {project.documents.length > 0 && `(${project.documents.length})`}
              </div>
            </div>
          );
        })}
      {!hasProjectsList && (
        <TableZeroState type="regular">
          <Icons icon="zeroState" />
          <h3 className="first-letter-capital">{t('currently there are no deleted projects')}</h3>
        </TableZeroState>
      )}
      {showPagination({list: projects, filters: projectFilters}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </div>
  );
};
