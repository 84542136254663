import MemberList from 'components/MemberLst/MemberList';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {addRemoveOrganizersACS} from 'store/project/addRemoveOrganizers/actionCreators';
import {IAddRemoveOrganizersPayload} from 'typescript/models/AddRemoveOrganizers';
import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';
import isMobileView from 'utils/helpers/isMobileView';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import {OrganizersDropdown} from './OrganizersDropdown';

interface IProps {
  singleProject: SingleProject;
}
export default function Organizators({singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {users} = useGlobalState((state) => state.users);
  const {user: authUser} = useGlobalState((state) => state.auth);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [5, 5],
  });

  const shouldDisplayButton = showSingleProjectButton(singleProject, authUser);
  const organizersLength = singleProject?.organizers.length;
  const isUserOrganizer = singleProject?.organizers.some((organizer) => organizer.id === authUser?.id);
  const firstOrganizersName = singleProject.organizers[0].name;

  const handleCheck = (user: Member) => {
    const isOrganizerInList = singleProject.organizers.find((organizer) => organizer.id === user.id);
    if (user.id === authUser?.id && isUserOrganizer) return;
    if (isOrganizerInList && organizersLength! < 2) return;

    const cloneOfSingleProject = cloneDeep(singleProject);
    const payload: IAddRemoveOrganizersPayload = {dto: {id: singleProject.id, userId: user.id}};

    if (isOrganizerInList) {
      payload.addOrRemove = false;
      const filteredList = cloneOfSingleProject.organizers.filter((organizer) => organizer.id !== user.id);
      cloneOfSingleProject.organizers = filteredList;
      payload.singleProject = cloneOfSingleProject;
    } else {
      payload.addOrRemove = true;
      cloneOfSingleProject.organizers.push(user);
      payload.singleProject = cloneOfSingleProject;
    }

    dispatch(addRemoveOrganizersACS(payload));
  };

  const renderTitle = () => {
    let payload = t('organizer');
    if (singleProject.organizers.length > 1) payload = t('organizers');
    return payload;
  };

  const handleToggle = () => {
    toggleOpen();
  };

  const organizersDropdownProps = {
    singleProject,
    users,
    handleCheck,
    styles,
    setPopperElement,
    attributes,
    handleToggle,
  };

  return (
    <div>
      <div className="project__title">
        <h3>{renderTitle()}:</h3>
        <div className="project__text">
          <div className="project__text-container">
            {organizersLength! >= 2 && (
              <>
                {!isMobileView() ? (
                  singleProject.organizers
                    .slice(0, 2)
                    .map((organizer) => <div key={organizer.id}>{organizer.name}, </div>)
                ) : (
                  <>{firstOrganizersName}</>
                )}
                <MemberList title="organizers" subtitle="group organizers" members={singleProject.organizers || []} />
              </>
            )}
            {organizersLength! === 1 && firstOrganizersName}
            <div className="dropdown" ref={containerRef}>
              {shouldDisplayButton && (
                <button
                  className={`project__title-more${isOpen ? ' project__title-more--open' : ''} organizer-more`}
                  onClick={handleToggle}
                  ref={setReferenceElement}
                >
                  <i className="far fa-pencil" />
                </button>
              )}
              {isOpen && <OrganizersDropdown {...organizersDropdownProps} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
