import {useTranslation} from 'react-i18next';

export const DocumentZeroState = () => {
  const {t} = useTranslation();

  return (
    <div className="project__document__zero-state">
      <p className="first-letter-capital">{t('currently there are no attached files for this project')}.</p>
    </div>
  );
};
