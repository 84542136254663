import {useEffect, useState} from 'react';

import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getProjects} from 'services/projectService';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {NotificationContent} from 'typescript/models/Notifications';
import {SingleProject} from 'typescript/models/SingleProject';
import {translateMonths} from 'utils/helpers/translateMonths';

import {displayProperIconText} from './Helpers/displayProperIcon';
import {renderNotificationTitle} from './Helpers/renderNotificationTitle';

interface IProps {
  notification: NotificationContent;
  handleRead: (id: number, status: boolean) => void;
}

export const ListOfNotifications = ({handleRead, notification}: IProps) => {
  const dispatch = useSetGlobalState();

  const {
    t,
    i18n: {language},
  } = useTranslation();
  const isLatin = language === 'latin';

  const [projects, setProject] = useState<SingleProject[]>([]);

  const isProjectDeleted = !projects.find((project) => project.id === notification.project.id);
  const mathingProjectFromList = projects.find((project) => project.id === notification.project.id);

  const getAllProjects = async () => {
    const response = await getProjects({own: false, size: 100});
    setProject(response.data.content);
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const handleSingleProject = () => {
    if (mathingProjectFromList) {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
      dispatch(setSingleProjectAC(mathingProjectFromList));
      if (!notification.read) handleRead(notification.id, notification.read);
    }
  };

  const formatMentionCommentNotification = () => {
    const regex = new RegExp(/>>>.+<<</g);
    const formattedStringArray = notification.comment?.text.split(regex);
    const taggedUser = formattedStringArray && formattedStringArray[0];
    const commentBody = formattedStringArray?.slice(1).join(' ');

    return {
      taggedUser,
      commentBody,
    };
  };

  const displayProperTextField = () => {
    const {taggedUser, commentBody} = formatMentionCommentNotification();
    if (notification.type === 'COMMENT') {
      return (
        <p className="notification-item-section__description">
          <span>{taggedUser}</span>
          {commentBody}
        </p>
      );
    }
    return <p>{t(`${displayProperIconText(notification).notification}`)}</p>;
  };

  return (
    <div
      className="notification-popup-body__item"
      role="button"
      tabIndex={0}
      onClick={handleSingleProject}
      style={{pointerEvents: isProjectDeleted ? 'none' : 'auto'}}
    >
      <div className="header">
        <span>
          <i className={`far fa-star ${mathingProjectFromList?.favorite ? 'starred' : ''}`} aria-hidden="true" />
        </span>
        <h2>{renderNotificationTitle(notification.project.title, 60)}</h2>
      </div>
      <div>
        <div className="notification-item-section">
          <p className="notification-item-section__author">{notification.author?.name}</p>
          <p className="notification-item-section__date">
            {notification.time && isLatin
              ? moment(notification.time).format('MMMM DD. u HH.mm')
              : translateMonths(moment(notification.time).format('MMMM'), moment(notification.time).format('DD. YYYY'))}
          </p>
        </div>
        <div className="notification-item-section">
          <span className="notification-item-section__body-icon">
            <i className={displayProperIconText(notification).icon} />
          </span>
          {notification.text && displayProperTextField()}
        </div>
      </div>
    </div>
  );
};
