import React, {useState} from 'react';

import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setDeletedProjectAC} from 'store/deletedProjects/setDeletedProject/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSelectedProjectAC} from 'store/project/setSelectedProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {isAdmin} from 'utils/getUserRole';
import {isDeleted} from 'utils/helpers/isDeleted';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';
import isSmallScreen from 'utils/helpers/isSmallScreen';
import {isUserOrganizer} from 'utils/helpers/isUserOrganizator';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import {Remainder} from './Remainder/Remainder';

interface IProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
  singleProject: SingleProject;
}

export default function Actions({setErrorMessage, singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {user} = useGlobalState((state) => state.auth);
  const [showTooltip, setShowTooltip] = useState(false);

  const shouldDisplayButton = showSingleProjectButton(singleProject, user);
  const isArchived = singleProject.archived;
  const isOrganizer = isUserOrganizer(singleProject, user);
  const isMember = singleProject.members.find((member) => member.id === user?.id);
  const isNotMemberOrOrganizer = !isMember && !isOrganizer;

  let offset: [number, number] = [10, 2];
  if (isSmallScreen()) offset = [97, 2];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} =
    useDropdown<HTMLLIElement>({
      position: 'bottom',
      offset,
    });

  const handleUnarchiveProject = () => {
    dispatch(setSelectedProjectAC(singleProject));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_UNARCHIVE_PROJECT}));
  };

  const handleArchive = () => {
    dispatch(setSelectedProjectAC(singleProject));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ARCHIVE}));
  };

  const handleLeaveProject = () => {
    dispatch(setSelectedProjectAC(singleProject));

    if (singleProject.organizers.length === 1 && isOrganizer) {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.ADD_ORGANIZER, allowOverflow: true}));
    } else {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_LEAVE_PROJECT}));
    }
  };

  const handleRemainderOpen = () => {
    if (!singleProject.date) {
      setErrorMessage(true);
      return;
    }
    toggleOpen();
  };

  const shouldDisableRemainder = () => {
    return singleProject.date && shouldDisplayButton ? false : true;
  };

  const handleMouseOver = () => {
    if (!shouldDisableRemainder() || !isNotArchivedOrDeleted(singleProject) || !shouldDisplayButton) return;
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (!shouldDisableRemainder()) return;
    setShowTooltip(false);
  };

  const handleActivateDeleteProject = (action: 'activate' | 'delete') => {
    dispatch(setDeletedProjectAC(singleProject.id));
    let actionCreator;
    switch (action) {
      case 'activate':
        actionCreator = toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ACTIVATE_PROJECT});
        break;
      case 'delete':
        actionCreator = toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_DELETE_PROJECT});
        break;
      default:
        actionCreator = toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ACTIVATE_PROJECT});
        break;
    }
    dispatch(actionCreator);
  };

  const shouldRenderDocumentsIcon = () => {
    if ((isDeleted(singleProject) || isArchived) && isSmallScreen()) return true;

    return false;
  };

  return (
    <>
      {isNotArchivedOrDeleted(singleProject) && <h3 className="d-none d-md-block">{t('actions')}</h3>}
      <ul className="project__actions">
        {isArchived && !isSmallScreen() && (
          <div className="project-archived">
            <h1 className="project-archived__title">{t('archived')}</h1>
            <div className="project-archived__action" role="button" onClick={handleUnarchiveProject}>
              <i className="far fa-undo" />
              <p className="first-letter-capital">{t('return from archive')}</p>
            </div>
          </div>
        )}
        {singleProject.deleted && !isSmallScreen() && (
          <div className="project-archived">
            <h1 className="project-archived__title">{t('deleted')}</h1>
            <div
              className="project-archived__action"
              role="button"
              onClick={() => handleActivateDeleteProject('activate')}
            >
              <i className="far fa-inbox-out" />
              <p className="first-letter-capital">{t('activate project')}</p>
            </div>
          </div>
        )}
        {shouldDisplayButton && (
          <li ref={containerRef}>
            <div
              ref={setReferenceElement}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onFocus={() => {}}
            >
              {showTooltip && <div className="project__tooltip">{t('add-date-tooltip')}</div>}
              <button onClick={handleRemainderOpen} disabled={shouldDisableRemainder()}>
                <i className="far fa-clock" />
                <span className="d-none d-md-block first-letter-capital">{t('set remainder')}</span>
              </button>
              <Remainder
                handleClose={() => toggleOpen()}
                singleProject={singleProject}
                setPopperElement={setPopperElement}
                styles={styles}
                attributes={attributes}
                isOpen={isOpen}
                handleToggle={handleRemainderOpen}
              />
            </div>
          </li>
        )}
        <li>
          <button
            onClick={handleLeaveProject}
            disabled={isNotMemberOrOrganizer || !isNotArchivedOrDeleted(singleProject)}
          >
            <i className="far fa-door-open" />
            <span className="d-none d-md-block first-letter-capital">{t('leave project')}</span>
          </button>
        </li>
        <li>
          <button onClick={handleArchive} disabled={!isNotArchivedOrDeleted(singleProject)}>
            <i className="far fa-archive" />
            <span className="d-none d-md-block first-letter-capital">{t('archive')}</span>
          </button>
        </li>
        {user && isAdmin(user) && (
          <li>
            <button
              disabled={!isNotArchivedOrDeleted(singleProject)}
              onClick={() => handleActivateDeleteProject('delete')}
            >
              <i className="far fa-trash-alt" />
              <span className="d-none d-md-block first-letter-capital">{t('delete')}</span>
            </button>
          </li>
        )}
        {shouldRenderDocumentsIcon() && (
          <li>
            <button disabled>
              <i className="far fa-paperclip" />
            </button>
          </li>
        )}
      </ul>
    </>
  );
}
