import {call, put} from '@redux-saga/core/effects';
import {AxiosResponse} from 'axios';
import {getNotifications} from 'services/notificationService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetNotifications} from 'typescript/interfaces/INotifications';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {NotificationContent} from 'typescript/models/Notifications';

import {getNotificationCountACS} from '../getNotificationCount/actionCreators';
import {setNotificationsRedux} from './actionCreators';

export function* getNotificationsSaga({payload}: IGetNotifications) {
  try {
    const response: AxiosResponse<ResponseWithFilters<NotificationContent[]>> = yield call(getNotifications, payload);

    yield put(setNotificationsRedux(response.data.content));
    yield put(getNotificationCountACS(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
