import {useEffect} from 'react';

import ContextMenu from 'components/Sidebar/ContextMenu';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {firstLetterCapital} from 'utils/helpers/firstLetterCapital';

export const DeleteProjectsDropdown = () => {
  const dispatch = useSetGlobalState();

  const {checkedProjects} = useGlobalState((state) => state.deletedProjects);
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset: [-10, 10],
  });
  const isCheckedLengthZero = checkedProjects.length === 0;
  const shouldDisableClick = isOpen && isCheckedLengthZero;

  const handleDeleteAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!checkedProjects.length) return;
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ACTIVATE_PROJECT}));
  };

  const handleBulkTogglerOpen = () => {
    if (checkedProjects.length > 0) toggleOpen();
  };

  useEffect(() => {
    if (shouldDisableClick) toggleOpen();
    // eslint-disable-next-line
  }, [checkedProjects]);

  return (
    <div ref={containerRef}>
      <button
        className={`header__action header__action--more ${isCheckedLengthZero ? 'pe-none' : ''}`}
        onClick={handleBulkTogglerOpen}
        ref={setReferenceElement}
      >
        <i className="far fa-ellipsis-v" />
      </button>
      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ContextMenu>
            <ul className="sidebar__dropdown">
              <li>
                <a href="/" onClick={handleDeleteAll}>
                  <i className="far fa-inbox-out" />
                  {checkedProjects.length > 1
                    ? firstLetterCapital(t('activate projects'))
                    : firstLetterCapital(t('activate project'))}
                </a>
              </li>
            </ul>
          </ContextMenu>
        </div>
      )}
    </div>
  );
};
