import produce, {castDraft, Draft} from 'immer';
import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';
import {IUserActions} from 'typescript/interfaces/IUserActions';
import {Member} from 'typescript/models/Member';
import {PaginateData} from 'typescript/models/Paginations';
import {INewUserPayload, IUpdateUserPayload} from 'typescript/models/User';

import {UserActions} from './actions';

export interface IState {
  users: Member[] | null;
  checkedMembers: number[];
  selectedUsers: Member[];
  pagination: PaginateData;
  newUser: INewUserPayload;
  userFilters: GetUsersDTO;
  deleteUserId: number | null;
  inviteUserId: number | null;
  activateUser: boolean;
  confirmUserId: number | null;
  userCredentials: IUpdateUserPayload | null;
}

export const initialState: IState = {
  users: null,
  checkedMembers: [],
  selectedUsers: [],
  pagination: {last: false, first: false},
  newUser: {id: null, roles: ['USER'], email: '', name: '', unitName: '', positionName: ''},
  userFilters: {sort: 'name', order: 'DESC', active: 'ALL', page: 0, size: 15},
  deleteUserId: null,
  inviteUserId: null,
  activateUser: false,
  confirmUserId: null,
  userCredentials: null,
};

const userReducer = (state = initialState, action: IUserActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case UserActions.SET_USERS:
        draft.users = castDraft(action.payload);
        break;
      case UserActions.CHECKED_USERS:
        draft.checkedMembers = castDraft(action.payload);
        break;
      case UserActions.SET_PAGINATE_USERS:
        draft.pagination = castDraft(action.payload);
        break;
      case UserActions.SET_ADD_USERS_DATA:
        draft.newUser = castDraft(action.payload);
        break;
      case UserActions.SET_USER_FILTERS:
        draft.userFilters = castDraft(action.payload);
        break;
      case UserActions.SET_USER_DELETE:
        draft.deleteUserId = castDraft(action.payload);
        break;
      case UserActions.SET_USER_INVITE_ID:
        draft.inviteUserId = castDraft(action.payload);
        break;
      case UserActions.CONFIRM_USER_ID:
        draft.confirmUserId = castDraft(action.payload);
        break;
      case UserActions.SET_USER_ACTIVATE_DEACTIVATE:
        draft.activateUser = castDraft(action.payload);
        break;
      case UserActions.SET_USER_CREDENTIALS:
        draft.userCredentials = castDraft(action.payload);
        break;
      case UserActions.SET_SELECTED_USERS:
        draft.selectedUsers = action.payload;
    }
  });

export default userReducer;
