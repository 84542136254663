import produce, {castDraft, Draft} from 'immer';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {IDeletedProjectsActions} from 'typescript/interfaces/IDeletedProjectActions';
import {PaginateData} from 'typescript/models/Paginations';
import {SingleProject} from 'typescript/models/SingleProject';

import {DeletedProjectsActions} from './actions';

export interface IState {
  projects: SingleProject[];
  isLoading: boolean;
  checkedProjects: number[];
  pagination: PaginateData;
  projectFilters: IGetProjectsDTO;
  checkedTags: number[];
  deletedProject: number | null;
}

export const initialState: IState = {
  projects: [],
  isLoading: false,
  checkedProjects: [],
  pagination: {last: false, first: false},
  projectFilters: {page: 0, size: 15, deleted: true},
  checkedTags: [],
  deletedProject: null,
};

const deletedProjectsReducer = (state = initialState, action: IDeletedProjectsActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case DeletedProjectsActions.SET_DELETED_PROJECTS:
        draft.projects = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_DELETED_PROJECTS_LOADING:
        draft.isLoading = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_DELETED_CHECKED_PROJECTS:
        draft.checkedProjects = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_DELETED_PROJECTS_PAGINATION:
        draft.pagination = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_DELETED_PROJECTS_FILTERS:
        draft.projectFilters = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_CHECKED_DELETE_TAGS:
        draft.checkedTags = castDraft(action.payload);
        break;
      case DeletedProjectsActions.SET_DELETED_PROJECT_ID:
        draft.deletedProject = castDraft(action.payload);
        break;
    }
  });

export default deletedProjectsReducer;
