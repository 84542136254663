import {Routes} from 'constants/routes';

import React, {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from 'components/Table';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {setArchiveFiltersAC} from 'store/archive/setArchiveFilters/actionCreator';
import {setCheckedArchiveProjectsAC} from 'store/archive/setCheckedArchiveProjects/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {displayTime} from 'utils/helpers/displayTime';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import {addArchiveFilters} from '../Helpers/addArchiveFilters';
import {Tags} from '../Tags';
import {ArchiveTagFilter} from './ArchiveTagFilter';
import {Header} from './Header';
import {Members} from './Members';
import {Organizers} from './Organizers';
import {SideBarDropdown} from './SideBarDropdown';

interface IProps {
  archivedProjects: SingleProject[];
}

export const DesktopTable = ({archivedProjects}: IProps) => {
  const dispatch = useSetGlobalState();
  const {checkedProjects, filters} = useGlobalState((state) => state.archive);
  const filtersClone = cloneDeep(filters);
  const {t} = useTranslation();

  const [showTagModal, setShowTagModal] = useState(false);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} =
    useDropdown<HTMLTableCellElement>({
      position: 'bottom',
      offset: [4, 13],
    });

  const hasArchivedProjectsList = archivedProjects.length > 0;

  const handleCheckAll = () => {
    const listOfArchivedProjects = archivedProjects.map((project) => project.id);

    let checkedPayload: number[] = [];
    if (!checkedProjects.length) checkedPayload = [...listOfArchivedProjects];
    dispatch(setCheckedArchiveProjectsAC(checkedPayload));
  };

  const checkRow = (value: number) => {
    const checkedProjectId = value;
    let checkedPayload: number[] = [];

    if (checkedProjects.includes(checkedProjectId)) {
      const filteredProjects = checkedProjects.filter((project) => project !== checkedProjectId);
      checkedPayload = filteredProjects;
    } else {
      checkedPayload = [...checkedProjects, checkedProjectId];
    }

    dispatch(setCheckedArchiveProjectsAC(checkedPayload));
  };

  const handleClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, project: SingleProject) => {
    // @ts-ignore
    if (e.target.type === 'checkbox') return;

    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(project));
  };

  const handleSort = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {value} = e.currentTarget;
    const payload = addArchiveFilters(filtersClone, value);

    dispatch(setArchiveFiltersAC({...payload, active: false}));
  };

  const showTagModalOnHover = () => {
    if (isOpen) setShowTagModal(false);
    else setShowTagModal(true);
  };
  const hideTagModalOnMouseLeave = () => {
    setShowTagModal(false);
  };
  const handleShowTagModal = () => {
    setShowTagModal(false);
    toggleOpen();
  };

  return (
    <Table className="archive-table">
      <TableHead>
        <TableRow>
          <TableHeader className="archive-table__header">
            <Checkbox
              id="checkMainMobile"
              onChange={handleCheckAll}
              checked={Boolean(checkedProjects.length)}
              className="checkbox"
            />
          </TableHeader>
          <TableHeader className="archive-table__dropdown-header">
            <SideBarDropdown />
          </TableHeader>
          <Header
            primaryTitle={`${t('projects')}`}
            sort
            className="archive-table__project-header"
            sortValue="title"
            handleSort={handleSort}
          />
          <Header
            primaryTitle={`${t('date')}`}
            secondaryTitle={`${t('event')}`}
            sort
            newRow
            date
            className="archive-table__project-date-header"
            sortValue="date"
            handleSort={handleSort}
          />
          <TableHeader
            className={`archive-table__tags-header ${
              !hasArchivedProjectsList ? 'archive-table__tags-header--zero-state' : ''
            } `}
          >
            <div ref={containerRef} className="position-relative">
              <div ref={setReferenceElement}>
                {t('tag')}
                <button
                  onClick={handleShowTagModal}
                  onMouseEnter={showTagModalOnHover}
                  onMouseLeave={hideTagModalOnMouseLeave}
                >
                  <i className={`fa${isOpen ? 's' : 'r'} fa-filter`} />
                </button>
                {showTagModal && <span className="table__tag-tooltip">{t('search-by-tags')}</span>}
              </div>
              {isOpen && (
                <div
                  id="popup_tag-filter"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="tag-dropdown"
                >
                  <ArchiveTagFilter />
                </div>
              )}
            </div>
          </TableHeader>
          <Header
            primaryTitle={`${t('organizer')}`}
            className="archive-table__organizers-header"
            sortValue="organizer"
          />
          <Header primaryTitle={`${t('member')}`} className="archive-table__members-header" />
          <Header primaryTitle={`${t('documents')}`} className="archive-table__documents-header" />
          <Header
            primaryTitle={`${t('time')}`}
            sort
            handleSort={handleSort}
            sortValue="updatedOn"
            className="archive-table__time-header"
          />
          <Header primaryTitle="" dropdown className="archive-table__page-dropdown-header" />
        </TableRow>
      </TableHead>
      <TableBody>
        {hasArchivedProjectsList ? (
          archivedProjects.map((project) => {
            return (
              <TableRow key={project.id} onClick={(e) => handleClick(e, project)}>
                <TableCell className="table__check">
                  <div className="table__check-wrap">
                    <Checkbox
                      id={`${project.id}`}
                      onChange={checkRow}
                      checked={checkedProjects.includes(project.id)}
                      className="checkbox"
                    />
                  </div>
                </TableCell>
                <TableCell className="light table__empty-row" />
                <TableCell className="table__project-title">{renderProjectTitle(project.title, 40)}</TableCell>
                <TableCell>{project.date && moment(project.date).format('DD/MM/YYYY')}</TableCell>
                <TableCell className="archive-table__tags" onClick={(e) => e.stopPropagation()}>
                  <Tags project={project} />
                </TableCell>
                <TableCell>
                  <Organizers project={project} />
                </TableCell>
                <TableCell>
                  <Members project={project} />
                </TableCell>
                <TableCell className="light">
                  <div className="d-flex">
                    <i
                      className="far fa-paperclip"
                      style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}}
                    />
                    {project.documents.length > 0 && `(${project.documents.length})`}
                  </div>
                </TableCell>
                <TableCell className="light">{project.updatedOn && displayTime(project.updatedOn, t)}</TableCell>
                <TableCell className="light" />
              </TableRow>
            );
          })
        ) : (
          <TableZeroState>
            <Icons icon="zeroState" />
            <h3 className="first-letter-capital">{t('currently there are no archived projects')}</h3>
            <p className="first-letter-capital">
              {t('if you want to archive a project, you')} <br />
              {t('can do that trough your')}{' '}
              <Link style={{pointerEvents: 'auto'}} to={Routes.DASHBOARD}>
                {t('projects list')}.
              </Link>
            </p>
          </TableZeroState>
        )}
      </TableBody>
    </Table>
  );
};
