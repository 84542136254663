import {AuthActions} from 'store/auth/actions';
import {LoginDTO} from 'typescript/dto/loginDTO';
import {ILoginUser} from 'typescript/interfaces/IAuthActions';

export function loginUserSagaACS(payload: LoginDTO): ILoginUser {
  return {
    type: AuthActions.LOGIN_USER,
    payload,
  };
}
