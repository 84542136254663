import React from 'react';

import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {deleteRemainderACS} from 'store/project/Remainders/DeleteRemainder/actionCreator';
import {Remainder} from 'typescript/models/Remainder';

interface IProps {
  remainder: Remainder;
  projectID: number;
  remaindersList: Remainder[];
}

export const RemainderComponent = ({remaindersList, remainder, projectID}: IProps) => {
  const dispatch = useSetGlobalState();

  const handleDeleteRemainder = (remainderId: number, projectId: number, remainders: Remainder[]) => {
    const deleteRemainderPayload = {remainderId, projectId, remainders};
    dispatch(deleteRemainderACS(deleteRemainderPayload));
  };

  return (
    <div className="remainders-dropdown__item">
      <div className="d-flex">
        <p>{moment(remainder.startDate).format('DD/MM/YYYY')}</p>
        <p>{remainder.time.slice(0, -3)}</p>
      </div>
      <button onClick={() => handleDeleteRemainder(remainder.id, projectID, remaindersList)}>x</button>
    </div>
  );
};
