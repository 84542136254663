import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setConfirmUserIdAC} from 'store/user/setConfirmUserId/actionCreator';
import {disableUsersACS} from 'store/user/setUserActivity/Disable/disableUsers';
import {enableUsersACS} from 'store/user/setUserActivity/Enable/enableUsers';
import {setUserActivateDeactivateAC} from 'store/user/setUserId/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export const ConfirmUserActivationDeactivaiton = () => {
  const dispatch = useSetGlobalState();
  const {activateUser, confirmUserId, users} = useGlobalState((state) => state.users);
  const matchingUser = users?.find((user) => user.id === confirmUserId);
  const {t} = useTranslation();

  const deactivateUser = () => {
    if (matchingUser?.id && users) {
      if (activateUser) dispatch(enableUsersACS({id: matchingUser.id, users}));
      else dispatch(disableUsersACS({id: matchingUser.id, users}));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setConfirmUserIdAC(null));
      dispatch(setUserActivateDeactivateAC(false));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className={`confirm-dialog${activateUser ? '--warning' : '--danger'}`}>
            <i className={`far fa-${activateUser ? 'question' : 'exclamation'}-circle`} />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('confirm-to')} {activateUser ? t('active-confirm') : t('deactive-confirm')}
              {t('user-a')}
              <b>{matchingUser?.name}</b>?
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={deactivateUser}>
                {activateUser ? t('Activate') : t('Deactivate')}
              </button>
              <button
                className="btn btn--secondary btn--large"
                onClick={() => dispatch(toggleModalAC({isOpen: false, component: null}))}
              >
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
