import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {IUnarchiveProject} from 'typescript/interfaces/IArchiveActions';

import {ArchiveActions} from '../actions';

export function unarchiveProjectAC(id: number, filters: IGetProjectsDTO): IUnarchiveProject {
  return {
    type: ArchiveActions.UNARCHIVE_PROJECT,
    payload: {id, filters},
  };
}
