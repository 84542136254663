import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getTagDetails} from 'services/tagService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetTagDetails} from 'typescript/interfaces/ITagActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {TagDetail} from 'typescript/models/TagDetails';

import {setPaginationAC} from '../setPagination/actionCreator';
import {setTagDetailsRedux} from './actionCreators';

export function* getTagDetailsSaga({payload}: IGetTagDetails) {
  try {
    const response: AxiosResponse<ResponseWithFilters<TagDetail[]>> = yield call(getTagDetails, payload);
    const {last, first} = response.data;
    yield put(setPaginationAC({last, first}));
    yield put(setTagDetailsRedux(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
