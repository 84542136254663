import {ISetSelectedProject} from 'typescript/interfaces/IProjectActions';
import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from '../actions';

export function setSelectedProjectAC(project: SingleProject | null): ISetSelectedProject {
  return {
    type: ProjectActions.SET_SELECTED_PROJECT,
    payload: project,
  };
}
