import React from 'react';

import './Pagination.scss';

interface IProps {
  last: boolean;
  first: boolean;
  handleClick: (e: React.MouseEvent, value: 'increment' | 'decrement') => void;
}

export const Pagination = ({last, first, handleClick}: IProps) => {
  return (
    <div className="pagination">
      <button disabled={first} className="pagination__btn btn btn--clear" onClick={(e) => handleClick(e, 'decrement')}>
        <i className="fal fa-angle-left" aria-hidden="true" />
      </button>
      <button disabled={last} className="pagination__btn btn btn--clear" onClick={(e) => handleClick(e, 'increment')}>
        <i className="fal fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
};
