import {useEffect, useState} from 'react';

import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';

import {ListOfMembers} from './ListOfMembers';

interface IProps {
  users: Member[] | null;
  singleProject: SingleProject;
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  styles: {[key: string]: React.CSSProperties};
  attributes: {[key: string]: {[key: string]: string} | undefined};
  handleToggle: () => void;
  handleCheck: (user: Member) => void;
}

export const MembersDropdown = (props: IProps) => {
  const {users, singleProject, setPopperElement, styles, attributes, handleToggle, handleCheck} = props;
  const {t} = useTranslation();
  const [listOfMembers, setListOfMembers] = useState<Member[]>([]);

  const listOfActiveUsers = users?.filter((user) => user.status === 'ACTIVE');

  useEffect(() => {
    if (listOfActiveUsers) setListOfMembers(listOfActiveUsers);
    // eslint-disable-next-line
  }, []);

  const handleMemberSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    if (listOfActiveUsers) {
      if (searchValue === '') {
        setListOfMembers(listOfActiveUsers);
      } else {
        const filteredListOfMembers = listOfActiveUsers.filter(
          (user) =>
            user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            user.email.toLowerCase().includes(searchValue.toLowerCase()),
        );

        setListOfMembers(filteredListOfMembers);
      }
    }
  };

  const debouncedUserSearch = debounce(handleMemberSearch, 1000);

  return (
    <div id="popup_organizer" className="popup" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <header className="popup__header">
        <h4>{t('members')}</h4>
        <button className="btn-close" onClick={handleToggle}>
          <i className="fal fa-times" />
        </button>
      </header>
      <section className="popup__body popup__body--borderless">
        <input type="text" placeholder={`${t('Search member')}`} onChange={debouncedUserSearch} />
        <h3>{t('project members')}</h3>
        <ul className="popup__select">
          {listOfMembers.map((user) => (
            <ListOfMembers key={`uid-${user.id}`} user={user} singleProject={singleProject} handleCheck={handleCheck} />
          ))}
        </ul>
      </section>
    </div>
  );
};
