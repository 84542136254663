/* eslint-disable prefer-destructuring */
import validator from 'validator';

// get string between 'x' and 'y' characters
export const splitBetween = (word: string, x: string, y: string) => {
  return word.split(x).pop()?.split(y)[0] || '';
};

// Parses multiple mentions in single string
export function parseMentions(text: string) {
  const words = text.split(' ');

  return words.length < 2
    ? words[0].split('>>>')[0]
    : words
        .map((word) => {
          const email = splitBetween(word, '>>>', '<<<');

          if (email && validator.isEmail(email)) {
            return '';
          }

          return word;
        })
        .join(' ');
}
