import {API} from 'constants/api';

import {useEffect, useState} from 'react';

import FileChooser from 'components/FileChooser/FileChooser';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import {deleteComment} from 'store/comments/delete/actionCreator';
import {editComment} from 'store/comments/edit/actionCreator';
import {setIsReplying, setParentId} from 'store/comments/reply/actionCreator';
import {setUploadedFrom, setUploadedDocument} from 'store/documents/upload/actionCreator';
import {EditCommentDTO} from 'typescript/dto/EditCommentDTO';
import {Comment} from 'typescript/models/Comment';
import {Member} from 'typescript/models/Member';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';

import AddEmoji from '../AddEmoji/AddEmoji';
import AddMention from '../AddMention/AddMention';
import AddReaction from '../AddReaction/AddReaction';
import ReactionList from '../ReactionList/ReactionList';
import SingleCommentMarkdown from './SingleCommentMarkdown';

interface IProps {
  comment: Comment;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setIsReplied: React.Dispatch<React.SetStateAction<boolean>>;
  isReplied: boolean;
}

const SingleComment = ({comment, isReplied, setIsReplied, setComment}: IProps) => {
  const {user, text, time, id, reactions} = comment;

  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {uploadedDocument, uploadedFrom} = useGlobalState((state) => state.documents);
  const {singleProject} = useGlobalState((state) => state.project);
  const {user: currentUser} = useGlobalState((state) => state.auth);
  const {users} = useGlobalState((state) => state.users);

  const [commentText, setCommentText] = useState<string>(text);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [listOfUsers, setListOfUsers] = useState<Member[] | null>([]);

  const isAuthUser = currentUser?.id === user.id;

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [20, 2],
  });

  const handleDeleteComment = () => {
    if (!isNotArchivedOrDeleted(singleProject)) return;
    if (singleProject?.id) dispatch(deleteComment(singleProject.id, id));
  };

  const handleEditComment = () => {
    if (singleProject?.id) {
      const updatedData: EditCommentDTO = {
        text: commentText,
      };

      dispatch(editComment(singleProject.id, id, updatedData));
      setIsEditing(false);
    }
  };

  const handleReplyToComment = () => {
    if (!isNotArchivedOrDeleted(singleProject)) return;
    setIsReplied(true);

    setComment((prevState) => {
      const uniqueMentions = comment.mentions.filter((mention) => {
        if (currentUser?.email === mention.user.email) return false;
        return prevState.includes(`${mention.user.email}`) ? false : true;
      });

      const mentionedUsers = uniqueMentions.map((mention) => `@${mention.user.name.split(' ').join('')}`);

      mentionedUsers.push(`@${user.name.split(' ').join('')}`);

      return `${prevState} ${mentionedUsers.join(' ')}`;
    });

    dispatch(setParentId(id));
    dispatch(setIsReplying(true));
  };

  useEffect(() => {
    if (uploadedFrom === 'edit-comment' && uploadedDocument) {
      let documentName = uploadedDocument.name;

      if (!uploadedDocument.name) documentName = uploadedDocument.url!.replace(/ /g, '');

      let isExternalLink: boolean = false;

      try {
        const docUrl = new URL(uploadedDocument.url ? uploadedDocument.url : '');
        isExternalLink = process.env.REACT_APP_API_URL !== docUrl.origin;
      } catch (error) {
        isExternalLink = false;
      }

      const documentUrl = isExternalLink
        ? uploadedDocument.url
        : `${process.env.REACT_APP_API_URL}${API.PROJECT_SERVICE.BASE}/${singleProject!.id}/document/${
            uploadedDocument.id
          }/download`;

      setCommentText((currComment) => `${currComment} [${documentName}](${documentUrl})`);

      dispatch(setUploadedDocument(null));
      dispatch(setUploadedFrom(null));
    }
    // eslint-disable-next-line
  }, [uploadedDocument, uploadedFrom]);

  const handleTexboxChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.currentTarget;

    const isThereEmptySpaceBefore = value[value.length - 2] === ' ';
    const isEnterPressed = value[value.length - 1] === '\n';

    const isFirstCharacterAtSign = value.length === 1 && value[value.length - 1] === '@';
    if (isFirstCharacterAtSign) toggleOpen(true);
    else if (value[value.length - 1] === '@' && isThereEmptySpaceBefore) {
      toggleOpen(true);
    } else if (isOpen && isEnterPressed) {
      toggleOpen(false);
    }

    if (isOpen && users) {
      const filteredUsers = users.filter((member) => {
        return (
          member.name.toLowerCase().includes(value.slice(1).toLowerCase()) ||
          member.email.toLowerCase().includes(value.slice(1).toLowerCase())
        );
      });

      let payload = filteredUsers;
      if (value.slice(1) === '') payload = users;
      setListOfUsers(payload);
    }

    setCommentText(value);
  };

  return (
    <div>
      <label>
        <strong>{user.name}</strong>
        <span className="project__comment-date">
          <span className="first-letter-capital">{moment(time).format('MMMM DD. YYYY')}</span>
        </span>
      </label>
      <div className="project__textarea project__textarea--comment">
        {!isEditing && (
          <div className="project__comment project__comment--text">
            <SingleCommentMarkdown text={text} />
          </div>
        )}
        {isEditing && (
          <div className="project__comment">
            <TextareaAutosize id="comment" value={commentText} onChange={handleTexboxChange} minRows={1} maxRows={4} />
            <div className="project__comment__action">
              <div className="project__comment__inner">
                <button className="btn btn--primary" disabled={commentText ? false : true} onClick={handleEditComment}>
                  <span className="first-letter-capital">{t('save-2')}</span>
                </button>
                <div className="project__comment__controls">
                  {singleProject && (
                    <FileChooser
                      toggler={
                        <span>
                          <i className="far fa-paperclip" />
                        </span>
                      }
                      singleProject={singleProject}
                      from="edit-comment"
                      togglerClassName="project__comment__controls__control"
                      start="left"
                      offset={[104, -10]}
                    />
                  )}
                  <AddMention
                    listOfUsers={listOfUsers}
                    setComment={setCommentText}
                    styles={styles}
                    attributes={attributes}
                    isOpen={isOpen}
                    toggleOpen={toggleOpen}
                    setReferenceElement={setReferenceElement}
                    setPopperElement={setPopperElement}
                    containerRef={containerRef}
                  />
                  <AddEmoji setComment={setCommentText} />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="project__reactions">
          <ReactionList commentId={id} reactions={reactions} />
          <AddReaction commentId={id} reactions={reactions} />
          {!isAuthUser && (
            <button
              className="project__reactions__response first-letter-capital"
              onClick={handleReplyToComment}
              disabled={isReplied}
            >
              {t('reply')}
            </button>
          )}
        </div>
        {isAuthUser && (
          <div
            className={`project__reactions-container ${
              isNotArchivedOrDeleted(singleProject) ? '' : 'project__item-disabled'
            }`}
          >
            {!isEditing && (
              <span
                className="project__reactions__response first-letter-capital"
                role="button"
                tabIndex={0}
                onClick={() => (isNotArchivedOrDeleted(singleProject) ? setIsEditing(true) : {})}
              >
                {t('change')}
              </span>
            )}
            {isEditing && (
              <span
                className="project__reactions__response first-letter-capital"
                role="button"
                onClick={() => setIsEditing(false)}
              >
                {t('cancel')}
              </span>
            )}
            <span
              className="project__reactions__response first-letter-capital"
              role="button"
              onClick={handleDeleteComment}
            >
              {t('delete')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleComment;
