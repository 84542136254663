import React, {useState} from 'react';

import {Placement} from '@popperjs/core';
import {usePopper} from 'react-popper';

import './Dropdown.scss';

interface IProps {
  content: React.FC<IDropdownTogglerProps>;
  toggler: React.FC<IDropdownTogglerProps>;
  position?: Placement;
  className?: string;
}

export interface IDropdownTogglerProps {
  onClick: (e: React.MouseEvent) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
}

const Dropdown = ({content: Content, toggler: Toggler, position = 'auto', className = ''}: IProps) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
    modifiers: [],
    placement: position,
  });

  const toggleDropdown = (isOpen: boolean) => {
    setToggle(isOpen);

    if (update) {
      update();
    }
  };

  const togglerAndContentProps = {
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();
      toggleDropdown(!toggle);
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Escape' && toggle) {
        toggleDropdown(false);
      }
      if (e.key === 'Enter' && !toggle) {
        toggleDropdown(true);
      }
    },
  };

  return (
    <div className={`dropdown ${className}`}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <Toggler {...togglerAndContentProps} />
      </div>
      <div
        className={`dropdown__content ${toggle ? 'dropdown__content--active' : ''}`}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {toggle && <Content {...togglerAndContentProps} />}
      </div>
    </div>
  );
};

export default Dropdown;
