export const stringToMatchingTimeValue = (periodOfTime: 'dana' | 'nedelje' | 'meseca') => {
  let period: 'days' | 'months' | 'weeks' | '' = '';

  switch (periodOfTime) {
    case 'dana':
      period = 'days';
      break;
    case 'nedelje':
      period = 'weeks';
      break;
    case 'meseca':
      period = 'months';
      break;
  }

  return period;
};
