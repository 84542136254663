import {useEffect} from 'react';

import {Pagination} from 'components/Pagination/Pagination';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {setTagFiltersAC} from 'store/tag/setTagFilters/actionCreator';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {PaginateData} from 'typescript/models/Paginations';
import {TagDetail} from 'typescript/models/TagDetails';
import {showPagination} from 'utils/helpers/showPagination';
import './TagTable.scss';

import DesktopTable from './DesktopTable/DesktopTable';
import MobileTable from './MobileTable/MobileTable';

interface IProps {
  listOfTags: TagDetail[];
  pagination: PaginateData;
  filters: GetTagDetailsDTO;
}

export const TagTable = ({listOfTags, pagination, filters}: IProps) => {
  const dispatch = useSetGlobalState();
  const cloneOfTagFilters = cloneDeep(filters);
  const isTabletView = useMediaBreakpoint('down', 'lg');

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();
    let payload;
    let page = cloneOfTagFilters.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...cloneOfTagFilters, page: (page += 1)};
    else payload = {...cloneOfTagFilters, page: (page -= 1)};
    dispatch(setTagFiltersAC(payload));
  };

  useEffect(() => {
    return () => {
      dispatch(setTagFiltersAC({page: 0, size: 15}));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tag-table__responsive">
      {isTabletView ? (
        <MobileTable filters={filters} tagsList={listOfTags} />
      ) : (
        <DesktopTable filters={filters} tagsList={listOfTags} />
      )}
      {showPagination({list: listOfTags, filters}) && (
        <Pagination first={pagination.first} last={pagination.last} handleClick={handlePageChange} />
      )}
    </div>
  );
};
