import {useState} from 'react';

import {Placement} from '@popperjs/core';
import {usePopper} from 'react-popper';

import useClickAway from './useClickAway';

interface IProps {
  offset?: [number, number];
  position?: Placement;
  open?: boolean;
}

const useDropdown = <T extends Element = HTMLDivElement>({
  offset = [0, 0],
  position = 'right-start',
  open = false,
}: IProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
    placement: position,
  });
  const {containerRef} = useClickAway<T>(() => {
    setIsOpen(false);
  });

  const toggleOpen = (x?: boolean) => {
    setIsOpen(x !== undefined ? x : !isOpen);
    if (update) update();
  };

  return {
    styles,
    containerRef,
    attributes,
    update,
    isOpen,
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
    toggleOpen,
  };
};

export default useDropdown;
