import {useTranslation} from 'react-i18next';

interface IProps {
  name: string;
}

export const CalendarHeader = ({name}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className="col">
      <div>{t(`${name}`)}</div>
    </div>
  );
};
