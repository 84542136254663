import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {resendInvitationBulk} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setCheckedMembers} from 'store/user/setCheckedMembersList/actionCreator';
import {setSelectedUsersAC} from 'store/user/setSelectedUsers/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import CloseModalButton from '../shared/CloseModalButton';

export default function SendBulkUsersInvitation() {
  const {selectedUsers, userFilters} = useGlobalState((state) => state.users);

  const {t} = useTranslation();
  const dispatch = useSetGlobalState();
  const selectedUsersLength = selectedUsers.length;

  // eslint-disable-next-line consistent-return
  const pluralizeText = () => {
    if (selectedUsersLength < 2) return t('selected-single');
    if (selectedUsersLength >= 2 && selectedUsersLength < 5) return t('selected-plural-1');
    if (selectedUsersLength >= 5) return t('selected-plural-2');
  };

  const handleInvite = async () => {
    if (!selectedUsersLength) return;

    const ids = selectedUsers.map((user) => user.id);

    await resendInvitationBulk(ids)
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'Users invited successfully'}));
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'Users invited unsuccessfully'}));
      })
      .finally(() => {
        batch(() => {
          dispatch(setCheckedMembers([]));
          dispatch(setSelectedUsersAC([]));
          dispatch(getUsersACS(userFilters));
          dispatch(toggleModalAC({isOpen: false, component: ModalComponents.SEND_BULK_USERS_INVITATION}));
        });
      });
  };

  const handleCancel = () =>
    dispatch(toggleModalAC({isOpen: false, component: ModalComponents.SEND_BULK_USERS_INVITATION}));

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--warning">
            <i className="far fa-question-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('Are you sure you want to reinvite')} {selectedUsersLength} {pluralizeText()} {t('users-2')}?
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={handleInvite}>
                {t('send')}
              </button>
              <button className="btn btn--secondary btn--large" onClick={handleCancel}>
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
