import {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import {Pagination} from 'components/Pagination/Pagination';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from 'components/Table';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import {Tag} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {setCheckedDeletedProjectsAC} from 'store/deletedProjects/setCheckedDeletedProjects/actionCreator';
import {setCheckedDeleteTags} from 'store/deletedProjects/setCheckedDeleteTags/actionCreators';
import {setDeletedProjectsFiltersAC} from 'store/deletedProjects/setDeletedProjectsFilters/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {displayTime} from 'utils/helpers/displayTime';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';
import {showPagination} from 'utils/helpers/showPagination';

import {getDeletedProjectsFilters} from '../utils/getDeletedProjectsFilters';
import {DeleteProjectsDropdown} from './DeleteProjectsDropdown';
import {DeleteTagFilters} from './DeleteTagFilters';
import {Header} from './Header';
import {Members} from './Members';
import {Organizers} from './Organizers';

import './DeletedProjectsTable.scss';

interface IProps {
  projects: SingleProject[];
}

export const DesktopTable = ({projects}: IProps) => {
  const dispatch = useSetGlobalState();
  const {checkedProjects, checkedTags, projectFilters, pagination} = useGlobalState((state) => state.deletedProjects);
  const {t} = useTranslation();
  const [showTagModal, setShowTagModal] = useState(false);

  const hasProjectsList = projects.length > 0;

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom',
    offset: [40, 12],
  });

  const checkRow = (checkedProjectId: number) => {
    let checkedPayload: number[] = [];
    if (checkedProjects.includes(checkedProjectId)) {
      checkedPayload = checkedProjects.filter((project) => project !== checkedProjectId);
    } else checkedPayload = [...checkedProjects, checkedProjectId];

    dispatch(setCheckedDeletedProjectsAC(checkedPayload));
  };

  const handleCheckAll = () => {
    const listOfDeletedProjects = projects.map((project) => project.id);
    let checkedPayload: number[] = [];
    if (!checkedProjects.length) checkedPayload = [...listOfDeletedProjects];
    dispatch(setCheckedDeletedProjectsAC(checkedPayload));
  };

  const handleClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, project: SingleProject) => {
    // @ts-ignore
    if (e.target.type === 'checkbox') return;

    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC({...project, deleted: true}));
  };

  const handleSort = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {value} = e.currentTarget;
    const payload = getDeletedProjectsFilters(projectFilters, value);

    dispatch(setDeletedProjectsFiltersAC({...payload, deleted: true}));
  };

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();

    let payload;
    let page = projectFilters.page!;
    const isValueIncrement = value === 'increment';

    if (isValueIncrement) payload = {...projectFilters, page: (page += 1)};
    else payload = {...projectFilters, page: (page -= 1)};

    dispatch(setDeletedProjectsFiltersAC(payload));
  };

  const handleTagFilter = (e: React.MouseEvent, tagID: number) => {
    e.stopPropagation();

    let checkedTagPayload: number[] = [];
    if (checkedTags.includes(tagID)) checkedTagPayload = checkedTags.filter((tag) => tag !== tagID);
    else checkedTagPayload = [...checkedTags, tagID];

    dispatch(setCheckedDeleteTags(checkedTagPayload));

    const tagIds = checkedTagPayload.map(String).join(',');
    const payload = {...projectFilters, tagIds};
    if (tagIds.length) dispatch(setDeletedProjectsFiltersAC(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setDeletedProjectsFiltersAC(newPayload));
    }
  };

  const renderTags = (tags: SingleProject['tags']) => {
    if (tags.length <= 3) {
      return tags.map((tag) => (
        <div key={`project-tag-${tag.id}`} role="button" tabIndex={0} onClick={(e) => handleTagFilter(e, tag.id)}>
          <Tag variant={tag.color}>{tag.name}</Tag>
        </div>
      ));
    }

    return (
      <>
        {tags.slice(0, 3).map((tag) => (
          <Tag key={`project-tag-${tag.id}`} variant={tag.color}>
            {tag.name}
          </Tag>
        ))}
        <TruncateBadge num={tags.length - 3} />
      </>
    );
  };

  const renderProjects = (project: SingleProject) => {
    return (
      <TableRow key={project.id} onClick={(e) => handleClick(e, project)}>
        <TableCell className="table-checkbox">
          <div className="table__check-wrap ">
            <Checkbox
              id={`${project.id}`}
              onChange={checkRow}
              checked={checkedProjects.includes(project.id)}
              className="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className="light table__empty-row" />
        <TableCell className="table-title">{renderProjectTitle(project.title, 40)}</TableCell>
        <TableCell>{project.date && moment(project.date).format('DD/MM/YYYY')}</TableCell>
        <TableCell className="deleted-projects-table__tags">{renderTags(project.tags)}</TableCell>
        <TableCell>
          <Organizers project={project} />
        </TableCell>
        <TableCell className="deleted-projects-table__members">
          <Members project={project} />
        </TableCell>
        <TableCell className="light">
          <div className="d-flex">
            <i className="far fa-paperclip" style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}} />
            {project.documents.length > 0 && `(${project.documents.length})`}
          </div>
        </TableCell>
        <TableCell className="light">{project.updatedOn && displayTime(project.updatedOn, t)}</TableCell>
        <TableCell className="light" />
      </TableRow>
    );
  };

  const showTagModalOnHover = () => {
    if (isOpen) setShowTagModal(false);
    else setShowTagModal(true);
  };
  const hideTagModalOnMouseLeave = () => {
    setShowTagModal(false);
  };
  const handleShowTagModal = () => {
    setShowTagModal(false);
    toggleOpen();
  };

  return (
    <>
      <Table className="deleted-projects-table">
        <TableHead>
          <TableRow>
            <TableHeader className="deleted-projects-table__header">
              <Checkbox
                id="checkMainMobile"
                onChange={handleCheckAll}
                checked={Boolean(checkedProjects.length)}
                className="checkbox"
              />
            </TableHeader>
            <TableHeader className="deleted-projects-table__dropdown-header">
              <DeleteProjectsDropdown />
            </TableHeader>
            <Header
              primaryTitle={`${t('projects')}`}
              sort
              deleted
              className="deleted-projects-table__project-header"
              sortValue="title"
              handleSort={handleSort}
            />
            <Header
              primaryTitle={`${t('date')}`}
              secondaryTitle={`${t('event')}`}
              sort
              newRow
              deleted
              date
              className="deleted-projects-table__project-date-header"
              sortValue="date"
              handleSort={handleSort}
            />
            <TableHeader className="deleted-projects-table__tags-header">
              <div ref={containerRef} className="position-relative">
                {t('tag')}
                <button
                  onClick={handleShowTagModal}
                  onMouseEnter={showTagModalOnHover}
                  onMouseLeave={hideTagModalOnMouseLeave}
                  ref={setReferenceElement}
                >
                  <i className={`fa${isOpen ? 's' : 'r'} fa-filter`} />
                </button>
                {showTagModal && <span className="table__tag-tooltip">{t('search-by-tags')}</span>}
              </div>
              {isOpen && (
                <div
                  id="popup_tag-filter"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="tag-dropdown"
                >
                  <DeleteTagFilters />
                </div>
              )}
            </TableHeader>
            <Header
              primaryTitle={`${t('organizer')}`}
              className="deleted-projects-table__organizators-header"
              sortValue="organizer"
            />
            <Header primaryTitle={`${t('member')}`} className="deleted-projects-table__members-header" />
            <Header primaryTitle={`${t('documents')}`} />
            <Header primaryTitle={`${t('time')}`} deleted sort handleSort={handleSort} sortValue="updatedOn" />
            <Header primaryTitle="" dropdown deleted />
          </TableRow>
        </TableHead>
        <TableBody>
          {hasProjectsList ? (
            projects.map((project) => {
              return renderProjects(project);
            })
          ) : (
            <TableZeroState>
              <Icons icon="zeroState" />
              <h3 className="first-letter-capital">{t('currently there are no deleted projects')}</h3>
            </TableZeroState>
          )}
        </TableBody>
      </Table>
      {showPagination({list: projects, filters: projectFilters}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </>
  );
};
