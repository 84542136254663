import React, {useEffect} from 'react';

import {Tag as TagComponent} from 'components/Tag/Tag';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep, debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {addRemoveTagsACS} from 'store/project/addRemoveTag/actionCreator';
import {getTagsACS} from 'store/tag/getTags/actionCreator';
import {IAddRemoveTagPayload} from 'typescript/models/IAddRemoveTag';
import {Tag} from 'typescript/models/Tag';
import './TagOverview.scss';

interface IProps {
  onClick: (e: React.MouseEvent) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
}
export default function TagOverview({onClick, onKeyDown}: IProps) {
  const dispatch = useSetGlobalState();
  const {singleProject} = useGlobalState((state) => state.project);
  const {tags} = useGlobalState((state) => state.tag);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getTagsACS({size: 100}));
    // eslint-disable-next-line
  }, []);

  const isCheckedTagExceededLimit = singleProject && singleProject?.tags.length <= 20;

  const handleCheck = (clickedTag: Tag) => {
    if (singleProject?.id) {
      const cloneOfSingleProject = cloneDeep(singleProject);
      const payload: IAddRemoveTagPayload = {dto: {id: singleProject.id, tagId: clickedTag.id}};

      const isTagInList = singleProject.tags.find((tag) => tag.id === clickedTag.id);
      if (isTagInList) {
        payload.addRemoveTag = false;
        const filteredList = cloneOfSingleProject.tags.filter((tag) => tag.id !== clickedTag.id);
        cloneOfSingleProject.tags = filteredList;
        payload.singleProject = cloneOfSingleProject;
      } else if (isCheckedTagExceededLimit) {
        payload.addRemoveTag = true;
        cloneOfSingleProject.tags.push(clickedTag);
        payload.singleProject = cloneOfSingleProject;
      }

      dispatch(addRemoveTagsACS(payload));
    }
  };

  const handleTagSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(getTagsACS({name: e.target.value}));
  };

  const debouncedHandleSearch = debounce(handleTagSearch, 1000);

  return (
    <div id="popup_badge" className="popup project__tags-overview">
      <header className="popup__header">
        <h4>{t('tags')}</h4>
        <button className="btn-close" onClick={onClick} onKeyDown={onKeyDown}>
          <i className="fal fa-times" />
        </button>
      </header>
      <section className="popup__body popup__body--borderless ">
        <input type="text" placeholder={`${t('Search tags')}`} onChange={debouncedHandleSearch} />
        <h3>{t('tags')}</h3>
        <ul className="popup__select project__tags-list">
          {tags.map((tag) => {
            return (
              <li key={`tags-${tag.id}`}>
                <p>
                  <button className="btn btn--clear" onClick={() => handleCheck(tag)}>
                    <TagComponent variant={tag.color}>{tag.name}</TagComponent>
                  </button>
                  {singleProject?.tags.find((x) => x.id === tag.id) && (
                    <span className="popup__select__check">
                      <i className="far fa-check" />
                    </span>
                  )}
                </p>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}
