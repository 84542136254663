import React, {useRef} from 'react';

import Icons from 'components/Icons/Icons';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {mediaBreakpointBetween} from 'hooks/useMediaBreakpoint/media';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setPreviewProjectAC} from 'store/project/Calendar/SetPreviewProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import {isToday} from '../../Helpers/isToday';
import {listOfFilteredProjects} from '../../Helpers/listOfFilteredProjects';
import {styleDays} from '../../Helpers/styleDays';
import {SingleDay} from './Day';

import './CalendarDay.scss';

interface IProps {
  dayInWeek: moment.Moment;
  currentlySelectedDay: moment.Moment;
  isFirstWeek: boolean;
}

export const CalendarDay = ({dayInWeek, currentlySelectedDay, isFirstWeek}: IProps) => {
  const dispatch = useSetGlobalState();
  const {projects} = useGlobalState((state) => state.project);
  const filteredList = listOfFilteredProjects(dayInWeek, projects);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const {t} = useTranslation();

  const isDesktop = useMediaBreakpoint('up', 'lg');
  const isDesktopSmall = mediaBreakpointBetween('md', 'lg').matches;
  const isTablet = useMediaBreakpoint('down', 'lg');
  const isMobile = useMediaBreakpoint('down', 'md');

  const setOffset = (): [number, number] => {
    if (isDesktopSmall) {
      if (isFirstWeek) return [0, -30];
      return [-80, -30];
    }

    if (isTablet) {
      if (isFirstWeek) return [-60, -30];
      return [-170, -30];
    }

    if (isMobile) {
      if (isFirstWeek) return [-60, -20];

      return [-220, -20];
    }

    if (isFirstWeek) return [-10, -40];

    return [-80, -40];
  };

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    offset: setOffset(),
  });

  const handleSingleDatePreview = (selectedDate: moment.Moment) => {
    const formatedDate = selectedDate.format('yyyy-MM-DD');
    const listOfProjects = listOfFilteredProjects(selectedDate, projects);

    if (!listOfProjects.length) toggleOpen();
    else {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT_DAY_PREVIEW}));

      dispatch(setPreviewProjectAC({listOfProjects, date: formatedDate}));
    }
  };

  const handleCloseDropdown = () => {
    toggleOpen();
  };

  return (
    <div ref={containerRef} className="calendar__day--wrapper">
      <div
        ref={setReferenceElement}
        className={`calendar__day ${styleDays(currentlySelectedDay, dayInWeek)}`}
        tabIndex={0}
        role="button"
        onClick={() => handleSingleDatePreview(dayInWeek)}
      >
        <div className={`calendar__date ${isToday(dayInWeek) ? 'calendar__date--curent' : ''}`}>
          {dayInWeek.format('D')}
        </div>
        {isDesktop && (
          <div className="calendar__events-wrapper" ref={wrapperRef}>
            <ul className="calendar__events" ref={ulRef}>
              {filteredList.map((singleDay) => {
                return (
                  <li key={singleDay.id}>
                    <SingleDay
                      isFirstWeek={isFirstWeek}
                      currentlySelectedDay={currentlySelectedDay}
                      singleDay={singleDay}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {isTablet && filteredList.length > 0 && (
          <div className="calendar__overflow">
            <TruncateBadge num={filteredList.length} />
          </div>
        )}
      </div>
      {isOpen && (
        <div
          role="button"
          onClick={(e) => e.stopPropagation()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="calendar-day__zero-state"
        >
          <button className="calendar-day__zero-state-close" onClick={handleCloseDropdown}>
            <i className="fal fa-times" />
          </button>
          <Icons icon="zeroState" />
          <p className="first-letter-capital">{t('currently there are no created projects')}.</p>
        </div>
      )}
    </div>
  );
};
