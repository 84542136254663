import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {cancelInvitation} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setConfirmUserIdAC} from 'store/user/setConfirmUserId/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export const ConfirmUnsend = () => {
  const dispatch = useSetGlobalState();

  const {confirmUserId, users, userFilters} = useGlobalState((state) => state.users);
  const matchingUser = users?.find((user) => user.id === confirmUserId);
  const {t} = useTranslation();

  const undoCall = async () => {
    if (matchingUser?.id) {
      await cancelInvitation(matchingUser?.id)
        .then(() => {
          dispatch(getUsersACS(userFilters));
          dispatch(toggleModalAC({isOpen: false, component: null}));
        })
        .catch(() => {
          dispatch(
            setGlobalNotification({
              variant: 'error',
              message: `Invitation cancel failed for user ${matchingUser.email}`,
            }),
          );
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setConfirmUserIdAC(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--danger">
            <i className="far fa-exclamation-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('confirm-invitation-canceling')} <b>{matchingUser?.name}</b>?
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--uppercase" onClick={undoCall}>
                {t('Remove-call')}
              </button>
              <button
                className="btn btn--secondary btn--uppercase"
                onClick={() => dispatch(toggleModalAC({isOpen: false, component: null}))}
              >
                {t('Quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
