import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setDeletedProjectAC} from 'store/deletedProjects/setDeletedProject/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {deleteProjectACS} from 'store/project/deleteProject/actionCreator';
import {getProjectsSagaACS} from 'store/project/getProjects/actionCreator';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import CloseModalButton from '../shared/CloseModalButton';

export const ConfirmDeleteProject = () => {
  const dispatch = useSetGlobalState();
  const {deletedProject} = useGlobalState((state) => state.deletedProjects);
  const {projectFilters: filters, projects} = useGlobalState((state) => state.project);
  const {t} = useTranslation();
  const matcingProject = projects.find((x) => x.id === deletedProject);

  useEffect(() => {
    return () => {
      dispatch(setDeletedProjectAC(null));
    };
    // eslint-disable-next-line
  }, []);

  const closeModal = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleDelete = async () => {
    if (deletedProject) {
      dispatch(deleteProjectACS({id: deletedProject, filters}));
    }
    dispatch(getProjectsSagaACS(filters));
    closeModal();
  };
  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__wrap">
        <div className="confirm-dialog--danger">
          <i className="far fa-exclamation-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>
            {t('delete confirmation')} <b>{matcingProject && renderProjectTitle(matcingProject.title, 25)}</b>?
          </h6>
          <div className="btn__wrap">
            <button className="btn btn--primary btn--large" onClick={handleDelete}>
              {t('delete-project')}
            </button>
            <button className="btn btn--secondary btn--large" onClick={closeModal}>
              {t('quit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
