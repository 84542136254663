import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {useHistory} from 'react-router';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {isAdmin} from 'utils/helpers/isAdmin';

import {TagTable} from './TagsTable/TagTable';

export const TagPage = () => {
  const dispatch = useSetGlobalState();

  const {tagDetails, tagFilters, pagination} = useGlobalState((state) => state.tag);
  const {user} = useGlobalState((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    dispatch(getTagDetailsACS(tagFilters));
    // eslint-disable-next-line
  }, [tagFilters, user]);

  useEffect(() => {
    if (user && !isAdmin(user)) history.push('*');
    // eslint-disable-next-line
  }, [user]);

  return (
    <AppLayout pageName="tag">
      <TagTable filters={tagFilters} pagination={pagination} listOfTags={tagDetails} />
    </AppLayout>
  );
};
