import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

export const addProjectFilters = (filters: IGetProjectsDTO, sortType: string) => {
  const isOrderASC = filters.order === 'ASC' ? 'DESC' : 'ASC';
  let filterPayload: IGetProjectsDTO = {};

  switch (sortType) {
    case 'title':
      filterPayload = {...filters, sort: 'title', order: filters.sort === 'title' ? isOrderASC : 'ASC'};
      break;
    case 'date':
      filterPayload = {...filters, sort: 'date', order: filters.sort === 'date' ? isOrderASC : 'ASC'};
      break;
    case 'updatedOn':
      filterPayload = {...filters, sort: 'updatedOn', order: filters.sort === 'updatedOn' ? isOrderASC : 'ASC'};
      break;
    case 'favorite':
      filterPayload = {...filters, sort: 'favorite', order: filters.sort === 'favorite' ? isOrderASC : 'DESC'};
      break;
  }

  return filterPayload;
};
