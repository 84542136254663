import {IDisableEnableUsers} from 'typescript/models/UpdatedUser';

import {UserActions} from '../../actions';

export const disableUsersACS = (payload: IDisableEnableUsers) => {
  return {
    type: UserActions.DISABLE_USER,
    payload,
  };
};
