import ArrowUpDown from './ArrowUpDown/ArrowUpDown';
import {CheckAll} from './CheckAll/CheckAll';
import ZeroState from './ZeroState/ZeroState';

type IconsType = 'checkAll' | 'zeroState' | 'arrow-up-down';

interface IProps {
  icon: IconsType;
}

const Icons = ({icon, ...rest}: IProps) => {
  const RenderIcons = () => {
    let iconComponent;
    switch (icon) {
      case 'checkAll':
        iconComponent = <CheckAll {...rest} />;
        break;

      case 'zeroState':
        iconComponent = <ZeroState {...rest} />;
        break;

      case 'arrow-up-down':
        iconComponent = <ArrowUpDown {...rest} />;
        break;

      default:
        iconComponent = <></>;
        break;
    }

    return iconComponent;
  };

  return <>{RenderIcons()}</>;
};

export default Icons;
