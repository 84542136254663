import {useState} from 'react';

import EmojiPicker from 'components/EmojiPicker/EmojiPicker';
import {EmojiData} from 'emoji-mart';
import useClickAway from 'hooks/useClickAway';

interface IProps {
  setComment: React.Dispatch<React.SetStateAction<string>>;
}

const AddEmoji = ({setComment}: IProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const {containerRef} = useClickAway<HTMLDivElement>(() => {
    setShowEmojiPicker(() => false);
  });

  const handleAddReaction = (emoji: EmojiData) => {
    setComment((currComment) => currComment.concat(` ${emoji.colons!} `));
    setShowEmojiPicker(false);
  };

  const showEmojies = () => {
    setShowEmojiPicker((currState) => !currState);
  };

  return (
    <div className="position-relative">
      <span className="project__comment__controls__control" ref={containerRef} role="button" onClick={showEmojies}>
        <img src="/images/smiley.png" alt="" />
      </span>
      {showEmojiPicker && (
        <EmojiPicker
          style={{position: 'absolute', bottom: '20px', right: '17px', zIndex: 1}}
          onSelect={(emoji: EmojiData) => handleAddReaction(emoji)}
        />
      )}
    </div>
  );
};

export default AddEmoji;
