import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {addRemoveMemberACS} from 'store/project/addRemoveMembers/actionCreator';
import {addRemoveOrganizersACS} from 'store/project/addRemoveOrganizers/actionCreators';
import {setProjectsAC} from 'store/project/setProjects/setProjects';
import {setSelectedProjectAC} from 'store/project/setSelectedProject/actionCreator';
import {IAddRemoveMemberPayload} from 'typescript/models/AddRemoveMember';
import {IAddRemoveOrganizersPayload} from 'typescript/models/AddRemoveOrganizers';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmLeaveProject() {
  const {selectedProject, projects} = useGlobalState((state) => state.project);
  const {user} = useGlobalState((state) => state.auth);

  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const isUserOrganizer = selectedProject?.organizers.some((organizer) => organizer.id === user?.id);
  const isUserMember = selectedProject?.members.some((organizer) => organizer.id === user?.id);

  const leaveAsOrganizer = () => {
    if (selectedProject && user) {
      const projectClone = cloneDeep(selectedProject);
      const payload: IAddRemoveOrganizersPayload = {dto: {id: selectedProject.id, userId: user.id}};

      projectClone.organizers = projectClone.organizers.filter((organizer) => organizer.id !== user.id);

      payload.singleProject = projectClone;
      payload.addOrRemove = false;

      dispatch(addRemoveOrganizersACS(payload));
    }
  };

  const leaveAsMember = () => {
    if (selectedProject && user) {
      const projectClone = cloneDeep(selectedProject);
      const payload: IAddRemoveMemberPayload = {dto: {id: selectedProject.id, userId: user.id}};

      projectClone.members = projectClone.members.filter((member) => member.id !== user.id);

      payload.singleProject = projectClone;
      payload.addRemoveMember = false;

      dispatch(addRemoveMemberACS(payload));
    }
  };

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
    dispatch(setSelectedProjectAC(null));
  };

  const handleLeaveProject = () => {
    if (isUserOrganizer) leaveAsOrganizer();
    if (isUserMember) leaveAsMember();

    const updatedProjectsList = projects.filter((project) => project.id !== selectedProject?.id);

    dispatch(setGlobalNotification({variant: 'success', message: 'Project left successfully'}));
    dispatch(setProjectsAC(updatedProjectsList));
    handleClose();
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--warning">
            <i className="far fa-question-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('leave-confirm')}
              <span>{selectedProject && renderProjectTitle(selectedProject.title, 25)}</span>?
            </h6>
            <em className="confirm-dialog__note">*{t('leave-confirm-desc')}.</em>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={handleLeaveProject}>
                {t('continue')}
              </button>
              <button className="btn btn--secondary btn--large" onClick={handleClose}>
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
