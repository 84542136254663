import React, {useEffect, useState} from 'react';

import {Field, Form, Formik} from 'formik';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setAddUserDataAC} from 'store/user/setAddUserData/actionCreator';
import {setConfirmUserIdAC} from 'store/user/setConfirmUserId/actionCreator';
import {setUserCredentialsAC} from 'store/user/setUserCredentials/actionCreator';
import {setUserActivateDeactivateAC, setUserInviteIdAC} from 'store/user/setUserId/actionCreator';
import {updateUserACS} from 'store/user/updateUser/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {IUpdateUserPayload} from 'typescript/models/User';

import addUserValidationSchema from '../AddUser/addUserValidationSchema';

import './SingleUser.scss';

export interface IInputValues {
  email: string;
  name: string;
  unitName: string;
  positionName: string;
}

export const SingleUser = () => {
  const dispatch = useSetGlobalState();
  const {newUser, users} = useGlobalState((state) => state.users);
  const {user} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();
  const [checkedRoles, setCheckedRoles] = useState<string[]>(newUser.roles);

  const matchingUserFromList = users?.find((singleUser) => singleUser.id === newUser.id);
  const isAdminAuthUser = user?.id === newUser.id;
  const isUserInactive = !matchingUserFromList?.active;
  const disableIfAdminOrInactive = isAdminAuthUser || isUserInactive;

  const isOnWait = matchingUserFromList?.status === 'ON_WAIT';
  const isCallExpired = matchingUserFromList?.status === 'CALL_EXPIRED';
  const doesUserHaveStatusOnWaitOrIsInactive = isOnWait || isUserInactive;

  const initValues = {
    name: newUser.name,
    email: newUser.email,
    positionName: newUser.positionName,
    unitName: newUser.unitName,
  };

  const setMatchingUser = () => {
    if (matchingUserFromList) dispatch(setConfirmUserIdAC(matchingUserFromList.id));
  };

  const handleSendInvitation = () => {
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_REINVITE}));
    if (newUser.id) dispatch(setUserInviteIdAC(newUser.id));
  };

  const handleCancelInvitation = () => {
    setMatchingUser();
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_UNDO_CALL}));
  };

  const activateUser = () => {
    setMatchingUser();
    dispatch(setUserActivateDeactivateAC(true));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_USER_ACTIVATION_DEACTIVATION}));
  };

  const deactivateUser = () => {
    setMatchingUser();
    dispatch(setUserActivateDeactivateAC(false));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_USER_ACTIVATION_DEACTIVATION}));
  };

  useEffect(() => {
    return () => {
      dispatch(setAddUserDataAC({id: null, roles: ['USER'], email: '', name: '', unitName: '', positionName: ''}));
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (inputValues: IInputValues) => {
    const dto = {...inputValues, roles: checkedRoles};
    const payload: IUpdateUserPayload = {dto, id: newUser.id!};

    const isAdminChangingOwnEmail = matchingUserFromList?.id === user?.id && matchingUserFromList?.email !== dto.email;

    if (isAdminChangingOwnEmail) {
      dispatch(setUserCredentialsAC(payload));
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_EMAIL_CHANGE}));
    } else {
      dispatch(updateUserACS({data: payload, refreshData: newUser?.id === user?.id ? true : false}));
      dispatch(toggleModalAC({isOpen: false, component: null}));
    }
  };

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isAdminAuthUser || isUserInactive) return;
    setCheckedRoles(e.target.value.split(','));
  };

  const handleActionIcon = () => {
    const userStatus = matchingUserFromList?.status;
    let icon;
    let text;
    let iconHelper;
    switch (userStatus) {
      case 'ACTIVE':
        icon = 'far fa-user';
        text = 'Deactivate';
        iconHelper = '-';
        break;
      case 'INACTIVE':
        icon = 'far fa-user';
        text = 'Activate';
        iconHelper = '+';
        break;
      case 'ON_WAIT':
        icon = 'far fa-user-slash';
        text = 'Remove-call';
        break;
      case 'CALL_EXPIRED':
        icon = 'fal fa-envelope';
        text = 'Send-call';
        break;
      default:
        icon = 'fal fa-envelope';
        text = 'Send-call';
        break;
    }
    return {icon, text, iconHelper};
  };

  const handleActionsByStatus = () => {
    if (matchingUserFromList) {
      const {status} = matchingUserFromList;
      switch (status) {
        case 'ACTIVE':
          deactivateUser();
          break;
        case 'INACTIVE':
          activateUser();
          break;
        case 'ON_WAIT':
          handleCancelInvitation();
          break;
        case 'CALL_EXPIRED':
          handleSendInvitation();
          break;

        default:
          handleSendInvitation();
          break;
      }
    }
  };

  return (
    <div className="single-user">
      {matchingUserFromList && (
        <div className="single-user__form-wrapper">
          <button
            className="btn-close"
            onClick={() => {
              dispatch(toggleModalAC({isOpen: false, component: null}));
            }}
          >
            <i className="fal fa-times" />
          </button>
          <div className="single-user__inner">
            <h2>{t('Member-profile')}</h2>
            <div className="single-user__wrap">
              <p>
                {isCallExpired && <i className="far fa-exclamation-triangle" aria-hidden="true" />}
                {t(matchingUserFromList.status)}
              </p>
              <div className="single-user__action d-sm-none">
                <button onClick={handleActionsByStatus} disabled={isAdminAuthUser}>
                  <i className={`${handleActionIcon().icon}`} />
                  {handleActionIcon().iconHelper && <span>{handleActionIcon().iconHelper}</span>}
                  {handleActionIcon().text && t(handleActionIcon().text)}
                </button>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initValues}
            onSubmit={handleChange}
            validationSchema={addUserValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props) => {
              const {errors, values} = props;

              return (
                <Form>
                  <div className="form">
                    <div
                      className={`form__group ${
                        doesUserHaveStatusOnWaitOrIsInactive ? 'single-user__check--disabled' : ''
                      }`}
                    >
                      <Field
                        disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        name="name"
                        type="text"
                        placeholder={t('add-name-placeholder')}
                      />
                      {errors.name && <span style={{color: 'red'}}>{errors.name}</span>}
                    </div>
                    <div
                      className={`form__group ${
                        doesUserHaveStatusOnWaitOrIsInactive ? 'single-user__check--disabled' : ''
                      }`}
                    >
                      <Field
                        disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        name="email"
                        type="text"
                        placeholder={t('add-email-placeholder')}
                      />
                      {errors.email && <span style={{color: 'red'}}>{errors.email}</span>}
                    </div>
                    <div
                      className={`form__group ${
                        doesUserHaveStatusOnWaitOrIsInactive ? 'single-user__check--disabled' : ''
                      }`}
                    >
                      <Field
                        disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        name="positionName"
                        type="text"
                        placeholder={t('add-position-placeholder')}
                      />
                      {errors.positionName && <span style={{color: 'red'}}>{errors.positionName}</span>}
                    </div>
                    <div
                      className={`form__group ${
                        doesUserHaveStatusOnWaitOrIsInactive ? 'single-user__check--disabled' : ''
                      }`}
                    >
                      <Field
                        disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        name="unitName"
                        type="text"
                        placeholder={t('add-organizational-unit-placeholder')}
                      />
                      {errors.unitName && <span style={{color: 'red'}}>{errors.unitName}</span>}
                    </div>
                    <div className="single-user__checks">
                      <div
                        className={`form__group single-user__check ${
                          disableIfAdminOrInactive ? 'single-user__check--disabled' : ''
                        }`}
                      >
                        <Field
                          name="adminRole"
                          type="radio"
                          id="admin"
                          value="ADMIN,USER"
                          checked={checkedRoles.includes('ADMIN')}
                          onChange={handleCheck}
                          disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        />
                        <label htmlFor="admin" className="radio-label">
                          {t('Administrator')}
                        </label>
                      </div>
                      <div
                        className={`form__group single-user__check ${
                          disableIfAdminOrInactive ? 'single-user__check--disabled' : ''
                        }`}
                      >
                        <Field
                          name="userRole"
                          type="radio"
                          value="USER"
                          id="user"
                          checked={!checkedRoles.includes('ADMIN')}
                          onChange={handleCheck}
                          disabled={doesUserHaveStatusOnWaitOrIsInactive}
                        />
                        <label htmlFor="user" className="radio-label">
                          {t('Member')}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="btn__wrap mt-4">
                    <button
                      type="submit"
                      disabled={
                        !values.email ||
                        !values.name ||
                        !values.positionName ||
                        !values.unitName ||
                        doesUserHaveStatusOnWaitOrIsInactive
                      }
                      className="btn btn--primary btn--large"
                    >
                      {t('save')}
                    </button>
                    <button className="btn btn--secondary btn--large" onClick={handleClose}>
                      {t('quit')}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
      {matchingUserFromList && (
        <div className="single-user__actions">
          <ul className="sidebar__dropdown">
            <li className="single-user__action">
              <button onClick={handleActionsByStatus} disabled={isAdminAuthUser}>
                <i className={`${handleActionIcon().icon}`} />
                {handleActionIcon().iconHelper && <span>{handleActionIcon().iconHelper}</span>}
                {t(handleActionIcon().text)}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
