/* eslint-disable @typescript-eslint/indent */
import React from 'react';

import './Tag.scss';

interface IProps {
  variant: string;
  children: React.ReactNode;
}

export const Tag = ({variant, children}: IProps) => {
  return (
    <span className="tag" style={{background: variant}}>
      {children}
    </span>
  );
};
