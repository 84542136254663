import {useEffect, useState} from 'react';

import {Tag as TagComponent} from 'components/Tag/Tag';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setCheckedDeleteTags} from 'store/deletedProjects/setCheckedDeleteTags/actionCreators';
import {setDeletedProjectsFiltersAC} from 'store/deletedProjects/setDeletedProjectsFilters/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';

export const DeleteTagFilters = () => {
  const dispatch = useSetGlobalState();
  const {tagDetails} = useGlobalState((state) => state.tag);

  const {projectFilters: filters, checkedTags} = useGlobalState((state) => state.deletedProjects);
  const [checked, setChecked] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getTagDetailsACS({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setChecked(checkedTags);
    // eslint-disable-next-line
  }, [checkedTags]);

  const handleTagFilter = (e: React.MouseEvent, tagID: number) => {
    e.stopPropagation();

    let checkedTagPayload: number[] = [];
    if (checkedTags.includes(tagID)) checkedTagPayload = checkedTags.filter((tag) => tag !== tagID);
    else checkedTagPayload = [...checkedTags, tagID];
    dispatch(setCheckedDeleteTags(checkedTagPayload));

    const tagIds = checkedTagPayload.map(String).join(',');
    const payload = {...filters, tagIds};
    if (tagIds.length) dispatch(setDeletedProjectsFiltersAC(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setDeletedProjectsFiltersAC(newPayload));
    }
  };

  return (
    <div id="popup_organizer" className="popup tag-list">
      <section className="popup__body popup__body--borderless  tag-list__tags">
        <ul className="popup__select">
          {tagDetails.map((tag) => {
            return (
              <li key={`uid-${tag.id}`}>
                <div className="d-flex" tabIndex={0} role="button" onClick={(e) => handleTagFilter(e, tag.id)}>
                  <TagComponent variant={tag.color}>{tag.name}</TagComponent>
                  {checked.includes(tag.id) && (
                    <span className="popup__select__check">
                      <i className="far fa-check" />
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};
