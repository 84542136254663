import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import './Notification.scss';

interface IProps {
  message: string;
  variant: 'error' | 'success';
  icon?: string;
}

export default function Notification({message, variant, icon}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const userFromStorage = localStorage.getItem('user');

  return (
    <div className={`notification notification--${variant}`} style={{top: !userFromStorage ? '0' : '58px'}}>
      {icon && (
        <span>
          <i className={`far ${icon}`} />
        </span>
      )}
      {t(`${message}`)}

      <button onClick={() => dispatch(setGlobalNotification(null))}>
        <i className="far fa-times" />
      </button>
    </div>
  );
}
