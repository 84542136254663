import {Routes} from 'constants/routes';

import {mediaBreakpointDown} from 'hooks/useMediaBreakpoint/media';

export const properPageOffset = (page: string) => {
  let offset: [number, number];
  const isMediaLg = mediaBreakpointDown('lg').matches;

  switch (page) {
    case Routes.DASHBOARD:
      offset = [0, 13];
      if (isMediaLg) offset = [0, 5];
      break;
    case Routes.ARCHIVE:
      offset = [0, 5];
      break;
    case Routes.USERS:
      offset = [0, 5];
      break;
    case Routes.TAG:
      offset = [0, 2];
      if (isMediaLg) offset = [0, 5];
      break;
    case Routes.DELETED_PROJECTS:
      offset = [0, 13];
      if (isMediaLg) offset = [0, 5];

      break;
    default:
      offset = [0, 6];
      break;
  }

  return offset;
};
