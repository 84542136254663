import React, {useState} from 'react';

import {Field, Form, Formik, FormikHelpers} from 'formik';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import './AddUser.scss';
import {setAddUserDataAC} from 'store/user/setAddUserData/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import addUserValidationSchema from './addUserValidationSchema';

export interface IInputValues {
  email: string;
  name: string;
  unitName: string;
  positionName: string;
}

export default function AddUser() {
  const dispatch = useSetGlobalState();
  const {newUser} = useGlobalState((state) => state.users);

  const {t} = useTranslation();

  const initValues = {
    name: newUser.name,
    email: newUser.email,
    positionName: newUser.positionName,
    unitName: newUser.unitName,
  };
  const [checkedUserType, setCheckedUserType] = useState<string[]>(['USER']);

  const handleCreateUser = (inputValues: IInputValues, helper: FormikHelpers<IInputValues>) => {
    const payload = {...inputValues, roles: checkedUserType, helper};
    dispatch(setAddUserDataAC(payload));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_REGISTER_INVITATION}));
  };

  const handleCloseAddUserModal = () => {
    const payload = {roles: ['USER'], email: '', name: '', unitName: '', positionName: ''};
    dispatch(setAddUserDataAC(payload));
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => setCheckedUserType(e.target.value.split(','));

  return (
    <div className="add-user">
      <div className="add-user__form-wrapper">
        <button
          className="btn-close"
          onClick={() => {
            dispatch(toggleModalAC({isOpen: false, component: null}));
          }}
        >
          <i className="fal fa-times" />
        </button>
        <div className="add-user__inner">
          <h2>{t('add-new-member')}</h2>
        </div>
        <Formik
          initialValues={initValues}
          onSubmit={handleCreateUser}
          validationSchema={addUserValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => {
            const {errors, values} = props;

            return (
              <Form>
                <div className="form">
                  <div className="form__group">
                    <Field
                      className={`${errors.name ? 'form__error' : ''}`}
                      name="name"
                      type="text"
                      placeholder={t('add-name-placeholder')}
                    />
                    {errors.name && <span className="form__validation">{errors.name}</span>}
                  </div>
                  <div className="form__group">
                    <Field
                      className={`${errors.email ? 'form__error' : ''}`}
                      name="email"
                      type="text"
                      placeholder={t('add-email-placeholder')}
                    />
                    {errors.email && <span className="form__validation">{t('email-validation')}</span>}
                  </div>
                  <div className="form__group">
                    <Field
                      className={`${errors.positionName ? 'form__error' : ''}`}
                      name="positionName"
                      type="text"
                      placeholder={t('add-position-placeholder')}
                    />
                    {errors.positionName && <span className="form__validation">{errors.positionName}</span>}
                  </div>
                  <div className="form__group">
                    <Field
                      className={`${errors.unitName ? 'form__error' : ''}`}
                      name="unitName"
                      type="text"
                      placeholder={t('add-organizational-unit-placeholder')}
                    />
                    {errors.unitName && <span className="form__validation">{errors.unitName}</span>}
                  </div>
                  <div className="add-user__checks">
                    <div className="form__group add-user__check">
                      <Field
                        name="unitName"
                        type="radio"
                        value="ADMIN,USER"
                        id="admin"
                        checked={checkedUserType.includes('ADMIN')}
                        onChange={handleCheck}
                      />
                      <label htmlFor="admin" className="radio-label">
                        {t('Administrator')}
                      </label>
                    </div>
                    <div className="form__group add-user__check">
                      <Field
                        name="unitName"
                        type="radio"
                        value="USER"
                        id="user"
                        checked={!checkedUserType.includes('ADMIN')}
                        onChange={handleCheck}
                      />
                      <label htmlFor="user" className="radio-label">
                        {t('Member')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="btn__wrap mt-4">
                  <button
                    type="submit"
                    disabled={!values.email || !values.name || !values.positionName || !values.unitName}
                    className="btn btn--primary btn--large"
                  >
                    {t('save')}
                  </button>
                  <button className="btn btn--secondary btn--large" onClick={handleCloseAddUserModal} disabled>
                    {t('quit')}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
