import {Tag as TagComponent} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useTranslation} from 'react-i18next';
import {Tag} from 'typescript/models/Tag';
import './TagList.scss';

interface IProps {
  tags: Tag[];
}

export default function TagList({tags}: IProps) {
  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
  });

  const {t} = useTranslation();

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  return (
    <div ref={containerRef}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <span role="button" onClick={handleDropdownOpen}>
          <TruncateBadge num={tags.length! - 2} />
        </span>
      </div>
      {isOpen && (
        <div
          id="popup_organizer"
          className="popup tag-list"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <header className="popup__header">
            <h4>{t('tags')}</h4>
            <button className="btn-close" onClick={handleDropdownOpen}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body popup__body--borderless  tag-list__tags">
            <ul className="popup__select">
              {tags.map((tag) => {
                return (
                  <li key={`uid-${tag.id}`}>
                    <TagComponent variant={tag.color}>{tag.name}</TagComponent>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      )}
    </div>
  );
}
