import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {t} from 'i18next';
import {cloneDeep} from 'lodash';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {addRemoveMemberACS} from 'store/project/addRemoveMembers/actionCreator';
import {addRemoveOrganizersACS} from 'store/project/addRemoveOrganizers/actionCreators';
import {setSelectedOrganizerAC} from 'store/project/setSelectedOrganizer/actionCreator';
import {IAddRemoveMemberPayload} from 'typescript/models/AddRemoveMember';
import {IAddRemoveOrganizersPayload} from 'typescript/models/AddRemoveOrganizers';
import './ConfirmOrganizer.scss';

export default function ConfirmOrganizer() {
  const {selectedOrganizer, selectedProject, projectFilters} = useGlobalState((state) => state.project);
  const {user} = useGlobalState((state) => state.auth);

  const dispatch = useSetGlobalState();

  const handleQuit = () => {
    dispatch(setSelectedOrganizerAC(null));
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleLeave = () => {
    if (selectedProject && selectedOrganizer && user) {
      const addOrganizerPayload: IAddRemoveOrganizersPayload = {
        dto: {id: selectedProject.id, userId: selectedOrganizer.id},
        addOrRemove: true,
        refreshProjects: false,
      };

      const removeOrganizerPayload: IAddRemoveOrganizersPayload = {
        dto: {id: selectedProject.id, userId: user.id},
        addOrRemove: false,
        refreshProjects: true,
        projectFilters,
      };

      dispatch(addRemoveOrganizersACS(addOrganizerPayload));
      dispatch(addRemoveOrganizersACS(removeOrganizerPayload));

      const projectClone = cloneDeep(selectedProject);
      const payload: IAddRemoveMemberPayload = {dto: {id: selectedProject.id, userId: user.id}};
      projectClone.members = projectClone.members.filter((member) => member.id !== user.id);
      payload.singleProject = projectClone;
      payload.addRemoveMember = false;
      dispatch(addRemoveMemberACS(payload));

      dispatch(
        setGlobalNotification({
          variant: 'success',
          message: 'Project left successfully',
          icon: 'fa-credit-card-blank',
        }),
      );
      dispatch(toggleModalAC({isOpen: false, component: null}));
    }
  };

  return (
    <div className="confirm-organizer">
      <button className="confirm-organizer__close" onClick={handleQuit}>
        <i className="fal fa-times" />
      </button>
      <div className="confirm-organizer__header">
        <i className="far fa-exclamation-circle" />
        <h3>
          <span>{selectedOrganizer?.name} </span>
          {t('confirm-new-organizer')}
        </h3>
      </div>
      <div className="confirm-organizer__actions">
        <button className="btn btn--primary" onClick={handleLeave}>
          {t('leave-project')}
        </button>
        <button className="btn btn--secondary" onClick={handleQuit}>
          {t('quit')}
        </button>
      </div>
    </div>
  );
}
