import {useState} from 'react';

import Datepicker, {DatePickerType} from 'components/Datepicker/Datepicker';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {setArchiveFiltersAC} from 'store/archive/setArchiveFilters/actionCreator';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

import {addArchiveFilters} from '../Helpers/addArchiveFilters';

export const DateComponent = () => {
  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset: [18, 16],
  });

  const {t} = useTranslation();

  const dispatch = useSetGlobalState();
  const {filters} = useGlobalState((state) => state.archive);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [showDateFilter, setShowDateFilter] = useState(false);

  const handleStartDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setStartDate(date);
      const dateFrom = moment(date?.toString()).format('YYYY-MM-DD');
      payload = addArchiveFilters(filters, 'dateFrom', dateFrom);

      dispatch(setArchiveFiltersAC({...payload}));
    }
  };

  const handleEndDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setEndDate(date);
      const dateTo = moment(date?.toString()).format('YYYY-MM-DD');
      payload = addArchiveFilters(filters, 'dateTo', dateTo);

      dispatch(setArchiveFiltersAC({...payload}));
    }
  };

  const handleReset = () => {
    const payload: IGetProjectsDTO = {...filters};
    setStartDate(null);
    setEndDate(null);

    payload.dateFrom = '';
    payload.dateTo = '';

    dispatch(setArchiveFiltersAC({...payload}));
  };

  const showDateModalOnHover = () => {
    if (isOpen) setShowDateFilter(false);
    else setShowDateFilter(true);
  };
  const hideDateModalOnMouseLeave = () => {
    setShowDateFilter(false);
  };
  const handleShowDateModal = () => {
    setShowDateFilter(false);
    toggleOpen();
  };

  return (
    <div ref={containerRef}>
      <div
        className="position-relative"
        role="button"
        tabIndex={0}
        onClick={handleShowDateModal}
        onMouseEnter={showDateModalOnHover}
        onMouseLeave={hideDateModalOnMouseLeave}
        ref={setReferenceElement}
      >
        <i className="fa fa-calendar" aria-hidden="true" style={{fontWeight: 400, fontSize: '14px'}} />
        {showDateFilter && <span className="archive-table__project-date-filter">{t('search-by-period')}</span>}
      </div>
      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <div className="d-flex align-items-center justify-content-end">
            <Datepicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onReset={handleReset}
            />
          </div>
        </div>
      )}
    </div>
  );
};
