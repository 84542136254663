import {Routes} from 'constants/routes';

import {useEffect} from 'react';

import NavbarFontSizeActions from 'components/Navbar/NavbarFontSizeActions/NavbarFontSizeActions';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {logoutAC} from 'store/auth/logout/actionCreator';
import './Navbar.scss';
import {getInstitutionAC} from 'store/institution/get/actionCreator';
import is404Page from 'utils/helpers/is404Page';

import NavbarAddProject from './NavbarAddProject/NavbarAddProject';
import NavbarEditInstitution from './NavbarEditInstitution/NavbarEditInstitution';
import NavbarLanguagePicker from './NavbarLanguagePicker/NavbarLanguagePicker';
import {NavbarNotifications} from './NavbarNotifications/NavbarNotifications';
import NavbarProjectSearch from './NavbarProjectSearch/NavbarProjectSearch';
import NavbarViewSwitcher from './NavbarViewSwitcher/NavbarViewSwitcher';

const Navbar = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isNotFoundPage = !is404Page(location.pathname);

  const isDownXL = useMediaBreakpoint('down', 'xl');

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom',
    offset: [0, 3],
  });

  const {user} = useGlobalState((state) => state.auth);
  const {globalNotification} = useGlobalState((state) => state.app);

  useEffect(() => {
    dispatch(getInstitutionAC());
  }, [dispatch]);

  const handleOpen = () => {
    if (globalNotification?.message) dispatch(setGlobalNotification(null));
    toggleOpen();
  };

  const shortenName =
    user &&
    user?.name
      .trim()
      .split(' ')
      .map((x) => x[0].toUpperCase())
      .join('');

  const handleLogout = () => {
    dispatch(logoutAC({}));
    history.push(Routes.LOGIN);
  };

  return (
    <>
      {isDownXL ? (
        <header className="header">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="header__wrap">
                  <div className="header__inner header__head">
                    <NavbarEditInstitution isNotFoundPage={isNotFoundPage} />

                    <NavbarAddProject isNotFoundPage={isNotFoundPage} />
                  </div>
                  <div className="header__inner">
                    <div className="header__search">
                      <NavbarProjectSearch />
                    </div>
                    <div>
                      <NavbarViewSwitcher />
                    </div>
                    <div style={{margin: '0px 5px'}}>
                      <NavbarNotifications />
                    </div>
                    <div className="header__user-wrap">
                      <div className="dropdown" ref={containerRef}>
                        <button ref={setReferenceElement} className="header__user" onClick={handleOpen}>
                          <h2>{shortenName}</h2>
                        </button>
                        {isOpen && (
                          <div
                            className={`dropdown__content ${isOpen ? 'dropdown__content--active' : ''}`}
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                          >
                            <ul className="header__dropdown">
                              <li>
                                <div className="header__inner">
                                  <NavbarLanguagePicker />
                                </div>
                              </li>
                              <li>
                                <div className="header__inner">
                                  <NavbarFontSizeActions />
                                </div>
                              </li>
                              <li>
                                <button className="logout" onClick={handleLogout}>
                                  Odjavite se
                                  <i className="far fa-sign-out" />
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="header">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="header__wrap">
                  <div className="header__inner">
                    <div className="header__inner-wrapper">
                      <NavbarEditInstitution isNotFoundPage={isNotFoundPage} />

                      <NavbarAddProject isNotFoundPage={isNotFoundPage} />
                    </div>
                  </div>
                  <div className="header__inner">
                    <div className="header__search">
                      <NavbarProjectSearch />
                    </div>
                  </div>
                  <div className="header__inner">
                    <NavbarViewSwitcher />
                  </div>
                  <div className="header__inner">
                    <NavbarFontSizeActions />
                  </div>
                  <div className="header__inner">
                    <NavbarLanguagePicker />
                  </div>
                  <div className="header__inner">
                    <NavbarNotifications />
                    <div className="header__user-wrap" ref={containerRef}>
                      <button className="header__user" ref={setReferenceElement} onClick={() => toggleOpen()}>
                        <h2>{user?.name}</h2>
                        <h3 className="first-letter-capital">{user?.position}</h3>
                      </button>
                      {isOpen && (
                        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                          <ul className="header__dropdown">
                            <li>
                              <button className="logout" onClick={handleLogout}>
                                <span className="first-letter-capital">{t('logout')}</span>
                                <i className="far fa-sign-out" />
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Navbar;
