import {CreateProjectPayload} from 'typescript/models/CreateProjectPayload';

import {ProjectActions} from '../actions';

export function createNewProjectACS(payload: CreateProjectPayload) {
  return {
    type: ProjectActions.CREATE_NEW_PROJECT,
    payload,
  };
}
