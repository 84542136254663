import React, {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import Datepicker, {DatePickerType} from 'components/Datepicker/Datepicker';
import MobileSort from 'components/MobileSort/MobileSort';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {setArchiveFiltersAC} from 'store/archive/setArchiveFilters/actionCreator';
import {setCheckedArchiveProjectsAC} from 'store/archive/setCheckedArchiveProjects/actionCreator';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {SingleProject} from 'typescript/models/SingleProject';

import {ArchiveTagFilter} from '../DesktopScreen/ArchiveTagFilter';
import {SideBarDropdown} from '../DesktopScreen/SideBarDropdown';
import {addArchiveFilters} from '../Helpers/addArchiveFilters';

interface IProps {
  archivedProjects: SingleProject[];
  checkedProjects: number[];
}

export const Header = ({archivedProjects, checkedProjects}: IProps) => {
  const dispatch = useSetGlobalState();
  const {filters} = useGlobalState((state) => state.archive);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const {t} = useTranslation();

  const handleCheckAll = () => {
    const listOfArchivedProjects = archivedProjects.map((project) => project.id);
    let checkedPayload: number[] = [];
    if (!checkedProjects.length) checkedPayload = [...listOfArchivedProjects];
    dispatch(setCheckedArchiveProjectsAC(checkedPayload));
  };

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset: [30, 7],
  });

  const handleStartDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setStartDate(date);
      const dateFrom = moment(date?.toString()).format('YYYY-MM-DD');
      payload = addArchiveFilters(filters, 'dateFrom', dateFrom);

      dispatch(setArchiveFiltersAC({...payload}));
    }
  };

  const handleEndDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setEndDate(date);
      const dateTo = moment(date?.toString()).format('YYYY-MM-DD');
      payload = addArchiveFilters(filters, 'dateTo', dateTo);

      dispatch(setArchiveFiltersAC({...payload}));
    }
  };

  const handleReset = () => {
    const payload: IGetProjectsDTO = {...filters};
    setStartDate(null);
    setEndDate(null);

    payload.dateFrom = '';
    payload.dateTo = '';

    dispatch(setArchiveFiltersAC({...payload}));
  };

  const {
    styles: tagStyles,
    attributes: tagAttributes,
    isOpen: isTagOpen,
    toggleOpen: toggleTagOpen,
    setReferenceElement: setTagRefEl,
    setPopperElement: setTagPopperEl,
    containerRef: tagContainerRef,
  } = useDropdown({
    position: 'bottom',
    offset: [-5, 13],
  });

  const {
    styles: sortStyles,
    attributes: sortAttributes,
    isOpen: isSortOpen,
    toggleOpen: toggleSortOpen,
    setReferenceElement: setSortRefEl,
    setPopperElement: setSortPopperEl,
    containerRef: sortContainerRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [-50, 12],
  });

  const handleSort = (value: string) => {
    const payload = addArchiveFilters(filters, value);
    dispatch(setArchiveFiltersAC(payload));
  };

  const mobileFilters = [
    {name: `${t('projects')}`, value: 'title'},
    {name: `${t('date')} ${t('event')}`, value: 'date'},
    {name: `${t('time')}`, value: 'updatedOn'},
  ];

  return (
    <div className="grid__header">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="grid__nav">
              <div className="grid__inner">
                <div className="check-main">
                  <div className="checkbox">
                    <Checkbox
                      id="checkMainMobile"
                      handleClick={handleCheckAll}
                      checked={Boolean(checkedProjects.length)}
                    />
                  </div>
                </div>
                <SideBarDropdown />
              </div>
              <div className="grid__inner">
                <div className="header__inner">
                  <div ref={sortContainerRef}>
                    <div className="header__action header__action--filter">
                      <div
                        ref={setSortRefEl}
                        role="button"
                        tabIndex={0}
                        onClick={() => toggleSortOpen()}
                        className="position-relative"
                      >
                        <i className="far fa-sort-alt" />
                      </div>
                      {isSortOpen && (
                        <div
                          id="popup_tag-filter"
                          ref={setSortPopperEl}
                          style={sortStyles.popper}
                          {...sortAttributes.popper}
                          className="tag-dropdown"
                        >
                          <MobileSort filters={mobileFilters} handleSort={handleSort} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div ref={tagContainerRef}>
                    <div className="header__action header__action--filter">
                      <div
                        ref={setTagRefEl}
                        role="button"
                        tabIndex={0}
                        onClick={() => toggleTagOpen()}
                        className="tag-dropdown"
                      >
                        <i className="far fa-filter" />
                      </div>
                      {isTagOpen && (
                        <div
                          id="popup_tag-filter"
                          ref={setTagPopperEl}
                          style={tagStyles.popper}
                          {...tagAttributes.popper}
                        >
                          <ArchiveTagFilter />
                        </div>
                      )}
                    </div>
                  </div>
                  <div ref={containerRef}>
                    <button ref={setReferenceElement} className="header__action" onClick={() => toggleOpen()}>
                      <i className="far fa-calendar-alt" />
                    </button>
                    {isOpen && (
                      <div
                        className="datepicker__position"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <Datepicker
                          startDate={startDate}
                          endDate={endDate}
                          onStartDateChange={handleStartDateChange}
                          onEndDateChange={handleEndDateChange}
                          onReset={handleReset}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <RoutesDropdown>
                  <i className="far fa-archive" />
                </RoutesDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
