import {Routes} from 'constants/routes';

import './Sidebar.scss';

import {useLocation} from 'react-router';

import {DefaultDropdown} from './DefaultDropdown';
import SidebarActions from './SidebarActions';

interface IProps {
  children?: React.ReactNode;
}

export default function ContextMenu({children = <DefaultDropdown />}: IProps) {
  const location = useLocation();
  const {pathname} = location;
  const shouldShowActions = pathname !== Routes.DASHBOARD;

  return <aside>{shouldShowActions && <SidebarActions>{children}</SidebarActions>}</aside>;
}
