import {useEffect} from 'react';

import ContextMenu from 'components/Sidebar/ContextMenu';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSelectedUsersAC} from 'store/user/setSelectedUsers/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import isMobileView from 'utils/helpers/isMobileView';

export const UserDropdown = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {checkedMembers, users} = useGlobalState((state) => state.users);

  let offset: [number, number] = [40, 12];
  if (isMobileView()) offset = [60, 12];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom',
    offset,
  });

  const isCheckedLengthZero = checkedMembers.length === 0;
  const shouldDisableClick = isOpen && isCheckedLengthZero;

  const handleSendInvites = (e: React.MouseEvent) => {
    e.preventDefault();

    if (users) {
      const selectedUsers = checkedMembers.flatMap((checkedMember) => {
        const filteredUsers = users.filter((x) => checkedMember === x.id);
        return filteredUsers;
      });

      if (selectedUsers.length) {
        dispatch(setSelectedUsersAC(selectedUsers));
        dispatch(toggleModalAC({isOpen: true, component: ModalComponents.SEND_BULK_USERS_INVITATION}));
      }
    }
  };
  const handleBulkTogglerOpen = () => {
    if (checkedMembers.length > 0) toggleOpen();
  };

  useEffect(() => {
    if (shouldDisableClick) toggleOpen();
    // eslint-disable-next-line
  }, [checkedMembers]);

  return (
    <div className="header__action-wrap" ref={containerRef}>
      <button
        className={`header__action header__action--more ${isCheckedLengthZero ? 'pe-none' : ''}`}
        onClick={handleBulkTogglerOpen}
        ref={setReferenceElement}
      >
        <i className="far fa-ellipsis-v" />
      </button>

      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="user-bulk-dropdown">
          <ContextMenu>
            <ul className="sidebar__dropdown">
              <li className="user-sidebar__wrapper">
                <a href="/" onClick={handleSendInvites}>
                  <i className="fal fa-envelope" />
                  {t('send invite')}
                </a>
              </li>
            </ul>
          </ContextMenu>
        </div>
      )}
    </div>
  );
};
