import {Routes} from 'constants/routes';

import {useLocation} from 'react-router';

interface IProps {
  children: React.ReactNode;
}

export default function SidebarActions({children}: IProps) {
  const location = useLocation();

  return (
    <>
      <div className="sidebar__action">{children}</div>
      {location.pathname === Routes.ARCHIVE && <div className="sidebar__add" />}
    </>
  );
}
