import Checkbox from 'components/Checkbox/Checkbox';
import SingleTag from 'components/Modal/Project/SingleTag/SingleTag.';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';
import {setTagFiltersAC} from 'store/tag/setTagFilters/actionCreator';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';

import MobileSort from '../../../../components/MobileSort/MobileSort';
import {TagDropdown} from '../TagDropdown';

interface IProps {
  filters: GetTagDetailsDTO;
}

export default function MobileHeader({filters}: IProps) {
  const {checkedTags, tagDetails, tagFilters} = useGlobalState((state) => state.tag);

  const tagIdsList = tagDetails.map((tag) => tag.id);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
  });

  const {
    styles: styling,
    attributes: att,
    isOpen: isOpened,
    toggleOpen: setOpen,
    setReferenceElement: setMobileSortRef,
    setPopperElement: setMobilePopperRef,
    containerRef: mobileSortRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [19, 5],
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) toggleOpen();
    if (e.key === 'Enter' && !isOpen) toggleOpen();
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleOnKeyDown,
  };

  const handleTagCreate = () => {
    toggleOpen();
    dispatch(getTagDetailsACS(filters));
  };

  const handleCheckAll = () => {
    let checkedPayload: number[] = [];
    if (!checkedTags.length) checkedPayload = [...tagIdsList];
    dispatch(setCheckedTagsAC(checkedPayload));
  };

  const mobileFilters = [
    {name: `${t('tags')}`, value: 'name'},
    {name: `${t('projects')}`, value: 'numberOfProject'},
  ];

  const handleSort = (value: string) => {
    const filtersCopy = {...tagFilters};

    if (value === filtersCopy.sort) {
      filtersCopy.order = filtersCopy.order === 'DESC' ? 'ASC' : 'DESC';
    }

    filtersCopy.sort = value;

    dispatch(setTagFiltersAC(filtersCopy));
  };

  const handleSortClick = () => setOpen();

  return (
    <div className="grid">
      <div className="grid__header">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="grid__nav">
                <div className="grid__inner">
                  <div className="checkbox">
                    <div className="check-main">
                      <Checkbox
                        id="checkMainMobile"
                        onChange={handleCheckAll}
                        checked={Boolean(checkedTags.length)}
                        className="checkbox"
                      />
                    </div>
                  </div>
                  <TagDropdown />
                </div>
                <div className="grid__inner">
                  <div className="header__inner" ref={mobileSortRef}>
                    <div className="header__action" ref={setMobileSortRef} role="button" onClick={handleSortClick}>
                      <i className="far fa-sort-alt" />
                    </div>
                    {isOpened && (
                      <div ref={setMobilePopperRef} style={styling.popper} {...att.popper}>
                        <MobileSort filters={mobileFilters} handleSort={handleSort} />
                      </div>
                    )}
                  </div>
                  <div className="tag-table__add-icon-header" ref={containerRef}>
                    <button className="tag-table__icon" onClick={() => toggleOpen()} ref={setReferenceElement}>
                      <i className="far fa-tag" />
                    </button>
                    {isOpen && (
                      <div
                        ref={setPopperElement}
                        className="project__tags-overview"
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <SingleTag handleCreate={handleTagCreate} {...dropdownProps} tag={null} isInTagRoute />
                      </div>
                    )}
                  </div>
                  <div className="tag-table__menu-header">
                    <RoutesDropdown>
                      <div className="routes-dropdown__icon">
                        <i className="far fa-tags" aria-hidden="true" />
                      </div>
                    </RoutesDropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
