import {Routes} from 'constants/routes';

import Button from 'components/Button/Button';
import {Field, Form, Formik} from 'formik';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {createPassword} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';

import './CreatePassword.scss';

import passwordCreateValidationSchema from './createPasswordValidation';

export const CreatePasswordForm = () => {
  const dispatch = useSetGlobalState();
  const history = useHistory();
  const location = useLocation();
  const initValues = {password: '', passwordConfirm: ''};
  const {t} = useTranslation();

  const params = new URLSearchParams(location.search);

  const email = params.get('email')!;
  const key = params.get('key')!;

  const handleCreatePassword = async (inputValues: {password: string; passwordConfirm: string}) => {
    const {passwordConfirm, password} = inputValues;

    await createPassword({
      email,
      key,
      password,
      confirmedPassword: passwordConfirm,
    })
      .then((response) => {
        const isResponseSuccessfull = response.status >= 200 && response.status < 300;
        if (isResponseSuccessfull) {
          dispatch(setGlobalNotification({variant: 'success', message: 'change password success'}));
          history.push(Routes.LOGIN);
        }
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'change password fail'}));
      });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleCreatePassword}
      validationSchema={passwordCreateValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => {
        const {errors} = props;
        return (
          <Form className="form">
            <div className="create-password-form">
              <label className="d-block" htmlFor="email">
                {t('Password')} <span className="required">*</span>
              </label>
              <Field
                name="password"
                type="password"
                className={`${errors.password ? 'form__error' : ''}`}
                id="password"
                placeholder={t('Password placeholder')}
              />
              {errors.password && <span className="form__validation">{t(errors.password)}</span>}
              <label className="d-block" htmlFor="email">
                {t('confirm_password')} <span className="required">*</span>
              </label>
              <Field
                name="passwordConfirm"
                type="password"
                className={`${errors.passwordConfirm ? 'form__error' : ''}`}
                id="passwordConfirm"
                placeholder={t('Password placeholder')}
              />
              {errors.passwordConfirm && <span className="form__validation">{t(errors.passwordConfirm)}</span>}
              <div className="button__wrap button__wrap--single">
                <Button type="submit" variant="primary">
                  {t('send')}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
