import {saveAs} from 'file-saver';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {downloadProjectDocument} from 'services/documentsService';
import {deleteDocument} from 'store/documents/delete/actionCreator';
import {FileTypes} from 'typescript/enums/FileTypes';
import {Document} from 'typescript/models/Document';
import {SingleProject} from 'typescript/models/SingleProject';
import {isAdmin} from 'utils/getUserRole';
import {isDeleted} from 'utils/helpers/isDeleted';
import {isUserOrganizer} from 'utils/helpers/isUserOrganizator';
import validator from 'validator';

interface IProps {
  isArchived: boolean | undefined;
  singleProject: SingleProject;
}
export const DocumentList = ({isArchived, singleProject}: IProps) => {
  const dispatch = useSetGlobalState();

  const {user} = useGlobalState((state) => state.auth);

  const handleDownloadDocument = async (singleDocument: Document) => {
    const {name, id} = singleDocument;
    const response = await downloadProjectDocument(singleProject.id, id);
    const url = URL.createObjectURL(response.data);
    saveAs(url, name);
  };

  const handleDeleteDocument = (id: number) => {
    const payload = {projectId: singleProject.id, documentId: id};

    dispatch(deleteDocument(payload));
  };

  const shouldDisableItem = () => {
    if (isArchived || isDeleted(singleProject)) return true;

    return false;
  };

  const shouldBeAbleToDeleteDocument = (document: Document) => {
    let payload = false;
    if (isArchived || isDeleted(singleProject)) payload = false;
    if ((user && isAdmin(user)) || isUserOrganizer(singleProject, user)) payload = true;
    const isUploadedDocumentUserAuthUser = document.user.id === user?.id;
    if (isUploadedDocumentUserAuthUser) payload = true;

    return payload;
  };

  const renderLiContent = (document: Document) => {
    if (document.type === FileTypes.URL) {
      const isValidUrl = validator.isURL(document.url!);
      const urlHasProtocol = validator.isURL(document.url!, {require_protocol: true});

      if (isValidUrl) {
        return (
          <a
            className={`project__document ${shouldDisableItem() ? 'project__item-disabled' : ''}`}
            target="_blank"
            rel="noreferrer"
            href={urlHasProtocol ? document.url : `//${document.url}`}
          >
            {document.name ? document.name : document.url}
          </a>
        );
      }
    }

    if (document.type === FileTypes.FILE) {
      return (
        <span
          className={`project__document ${shouldDisableItem() ? 'project__item-disabled' : ''}`}
          role="button"
          onClick={() => handleDownloadDocument(document)}
        >
          {document.name}
        </span>
      );
    }

    return (
      <span
        className={`project__document project__document--text ${shouldDisableItem() ? 'project__item-disabled' : ''}`}
      >
        {document.name ? document.name : document.url}
      </span>
    );
  };

  return (
    <ul>
      {singleProject.documents.map((document) => {
        return (
          <li key={`doc-${document.id}`}>
            {renderLiContent(document)}
            {shouldBeAbleToDeleteDocument(document) && (
              <button
                className={`project__document__close ${shouldDisableItem() ? 'project__item-disabled' : ''}`}
                onClick={() => handleDeleteDocument(document.id)}
              >
                <i className="fal fa-times" />
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};
