import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {unarchiveProjectAC} from 'store/archive/unarchiveProject/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSelectedProjectAC} from 'store/project/setSelectedProject/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmUnarchiveProject() {
  const {selectedProject} = useGlobalState((state) => state.project);
  const {filters} = useGlobalState((state) => state.archive);
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const handleUnarchiveProject = () => {
    dispatch(unarchiveProjectAC(selectedProject!.id, filters));
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleCancel = () => {
    dispatch(setSelectedProjectAC(null));
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--danger">
            <i className="far fa-exclamation-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('Are you sure you want to unarchive project')} <b>{selectedProject?.title}</b>?
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--uppercase" onClick={handleUnarchiveProject}>
                {t('Return')}
              </button>
              <button className="btn btn--secondary btn--uppercase" onClick={handleCancel}>
                {t('Quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
