import AppLayout from 'layouts/AppLayout/AppLayout';

import './Archive.scss';

import ArchiveTable from './ArchiveTable/ArchiveTable';

export const Archive = () => {
  return (
    <AppLayout pageName="archive">
      <ArchiveTable />
    </AppLayout>
  );
};
