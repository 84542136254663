import React from 'react';

import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import {Member} from 'typescript/models/Member';

interface IProps {
  organizersList: Member[] | [];
}

export const OrganizersList = ({organizersList}: IProps) => {
  return (
    <div>
      {organizersList.length > 0 ? (
        organizersList.slice(0, 1).map((organizer) => {
          return (
            <div key={organizer.id} className="d-flex align-items-baseline">
              <p>{organizer.name}</p>
              {organizersList.length > 1 ? <TruncateBadge num={organizersList.length - 1} /> : null}
            </div>
          );
        })
      ) : (
        <p>/</p>
      )}
    </div>
  );
};
