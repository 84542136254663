import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';

interface IProps {
  filters: GetTagDetailsDTO;
  sortType: string;
}

export const addTagFilters = ({filters, sortType}: IProps) => {
  const isOrderASC = filters.order === 'ASC' ? 'DESC' : 'ASC';
  let filterPayload: GetTagDetailsDTO = {};

  switch (sortType) {
    case 'name':
      filterPayload = {...filters, sort: 'name', order: filters.sort === 'name' ? isOrderASC : 'ASC'};
      break;
    case 'createdOn':
      filterPayload = {...filters, sort: 'createdOn', order: filters.sort === 'createdOn' ? isOrderASC : 'ASC'};
      break;
    case 'numberOfProject':
      filterPayload = {
        ...filters,
        sort: 'numberOfProject',
        order: filters.sort === 'numberOfProject' ? isOrderASC : 'ASC',
      };
      break;
  }

  return filterPayload;
};
