import AddProject from 'components/AddProject/AddProject';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';

interface IProps {
  isNotFoundPage: boolean;
}

export default function NavbarAddProject({isNotFoundPage}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {globalNotification} = useGlobalState((state) => state.app);

  const {
    styles: projectStyles,
    attributes: projectAtributes,
    isOpen: isAddProjectOpen,
    toggleOpen: toggleAddProjectOpen,
    setReferenceElement: setProjectRefElement,
    setPopperElement: setProjectPoperElement,
    containerRef: projectContainerRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [0, 5],
  });

  const handleAddProject = () => {
    if (isNotFoundPage) return;
    if (globalNotification?.message) dispatch(setGlobalNotification(null));
    toggleAddProjectOpen();
  };

  return (
    <div className="header__new-project" ref={projectContainerRef}>
      <button className="button button--primary button--small" ref={setProjectRefElement} onClick={handleAddProject}>
        <span className="align-self-center">+</span>
        <p>{t('new project')}</p>
      </button>

      {isAddProjectOpen && (
        <div
          className="header__add-project"
          ref={setProjectPoperElement}
          style={projectStyles.popper}
          {...projectAtributes.popper}
        >
          <div>
            <AddProject onClose={() => toggleAddProjectOpen()} />
          </div>
        </div>
      )}
    </div>
  );
}
