import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';

interface IProps {
  onClose?: () => void;
}

export default function CloseModalButton({onClose}: IProps) {
  const dispatch = useSetGlobalState();
  return (
    <button
      type="button"
      className="btn-close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={() => {
        if (onClose) onClose();
        dispatch(toggleModalAC({isOpen: false, component: null}));
      }}
    >
      <i className="fal fa-times" />
    </button>
  );
}
