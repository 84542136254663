import React, {useEffect} from 'react';

import {TimeStamp} from 'typescript/models/Remainder';

import {AddNumberOfDays} from './AddNumberOfDays';
import {SelectTimePeriod} from './SelectTimePeriod';
import {TimePickerComponent} from './TimePicker';

interface IProps {
  timeStamp: Date | null;
  setNumOfDays: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPeriodOfTime: React.Dispatch<React.SetStateAction<TimeStamp>>;
  setTimeStamp: React.Dispatch<React.SetStateAction<Date | null>>;
}

export const AddRemainder = ({setNumOfDays, setPeriodOfTime, setTimeStamp, timeStamp}: IProps) => {
  useEffect(() => {
    return () => {
      setNumOfDays(undefined);
      setPeriodOfTime('dana');
      setTimeStamp(null);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="add-remainder-wrapper">
      <AddNumberOfDays handleSelectNumberOfDays={(value: number) => setNumOfDays(value)} />
      <SelectTimePeriod onClick={(value: TimeStamp) => setPeriodOfTime(value)} />
      <p>ranije u</p>
      <TimePickerComponent setTimeStamp={setTimeStamp} timeStamp={timeStamp} />
      <button>
        <i className="fal fa-times" />
      </button>
    </div>
  );
};
