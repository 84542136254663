export const dipslayProperPlaceholder = (pathname: string) => {
  let payload = '';
  switch (pathname) {
    case '/dashboard':
      payload = 'Search projects';
      break;
    case '/users':
      payload = 'Search users';
      break;
    case '/archive':
      payload = 'Search projects';
      break;
    case '/deleted-projects':
      payload = 'Search projects';
      break;
    case '/tag':
      payload = 'Search tags';
      break;
  }

  return payload;
};
