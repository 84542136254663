import React from 'react';

import {useTranslation} from 'react-i18next';

export default function NavbarLanguagePicker() {
  const {i18n} = useTranslation();

  return (
    <>
      <button
        onClick={() => {
          i18n.changeLanguage('cyrillic');
        }}
        className={`header__action header__action--small ${
          i18n.language === 'cyrillic' ? 'header__action--active' : ''
        }`}
      >
        <span>ЋИР</span>
      </button>
      <button
        onClick={() => {
          i18n.changeLanguage('latin');
        }}
        className={`header__action header__action--small ${i18n.language === 'latin' ? 'header__action--active' : ''}`}
      >
        <span>LAT</span>
      </button>
    </>
  );
}
