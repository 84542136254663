import {Routes} from 'constants/routes';

import React from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useLocation} from 'react-router';
import {setCheckedMembers} from 'store/user/setCheckedMembersList/actionCreator';

import {AddUsersButton} from './AddUsers';

interface IProps {
  name: string;
  icon?: string;
  checkIcon?: boolean;
  dropdown?: boolean;
  className?: string;
}

export const UsersHeader = ({dropdown = false, name, icon = '', checkIcon = false, className = ''}: IProps) => {
  const dispatch = useSetGlobalState();
  const location = useLocation();

  const {users, checkedMembers} = useGlobalState((state) => state.users);
  const showAddUsersButton = location.pathname === Routes.USERS;

  const handleCheckAll = () => {
    const listOfUserIds = users?.filter((user) => user.status === 'CALL_EXPIRED').map((user) => user.id);

    if (listOfUserIds) {
      let checkedMembersPayload: (number | undefined)[] = [];
      if (!checkedMembers.length) checkedMembersPayload = [...listOfUserIds];
      dispatch(setCheckedMembers(checkedMembersPayload));
    }
  };

  return (
    <th className={className}>
      {!dropdown ? (
        <span className="dashboard-table__sort">
          {checkIcon ? (
            <div className="dashboard-table__tooltip">
              <Checkbox
                id="checkMainMobile"
                onChange={handleCheckAll}
                checked={Boolean(checkedMembers.length)}
                className="checkbox"
              />
            </div>
          ) : (
            <>
              {name}
              {icon && <i className={icon} />}
            </>
          )}
        </span>
      ) : (
        <div className="dashboard-table__users-action">
          {showAddUsersButton && <AddUsersButton />}
          <RoutesDropdown>
            <div className="routes-dropdown__icon">
              <i className="far fa-users" aria-hidden="true" />
            </div>
          </RoutesDropdown>
        </div>
      )}
    </th>
  );
};
