import React from 'react';

import moment from 'moment';

import {CalendarDay} from './Day/CalendarDay';

interface IProps {
  week: moment.Moment[];
  currentlySelectedDay: moment.Moment;
  isFirstWeek: boolean;
}

export const CalendarWeek = ({week, currentlySelectedDay, isFirstWeek}: IProps) => {
  return (
    <div className="calendar__row">
      <div className="row h-100 g-0 g-lg-3">
        {week.map((dayInWeek) => {
          return (
            <div className="col" key={dayInWeek.format('D')}>
              <CalendarDay
                isFirstWeek={isFirstWeek}
                dayInWeek={dayInWeek}
                currentlySelectedDay={currentlySelectedDay}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
