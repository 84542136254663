import Icons from 'components/Icons/Icons';
import {Pagination} from 'components/Pagination/Pagination';
import {Table, TableBody, TableHead, TableHeader, TableRow} from 'components/Table';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {setUserFitlersAC} from 'store/user/setUserFilters/actionCreator';
import {UserActivity} from 'typescript/dto/GetUsersDTO';
import {Member} from 'typescript/models/Member';
import {PaginateData} from 'typescript/models/Paginations';
import {showPagination} from 'utils/helpers/showPagination';

import {sortByNameHelper} from './Helpers/sortByName';
import {sortByStatusHelper} from './Helpers/sortByStatus';
import {UserDropdown} from './UserDropdown';
import {UsersHeader} from './UsersHeader';
import {UsersRows} from './UsersRows';

interface IProps {
  users: Member[] | null;
  pagination: PaginateData;
  handlePageChange: (e: React.MouseEvent, value: 'increment' | 'decrement') => void;
}

export const DesktopTable = ({users, pagination, handlePageChange}: IProps) => {
  const dispatch = useSetGlobalState();
  const {userFilters} = useGlobalState((state) => state.users);
  const clonedFilters = cloneDeep(userFilters);
  const {t} = useTranslation();

  const hasUsersList = users && users.length > 0;

  const sortByStatus = () => {
    const payload = sortByStatusHelper(clonedFilters);
    dispatch(setUserFitlersAC(payload));
  };

  const sortByName = () => {
    const payload = sortByNameHelper(clonedFilters);
    dispatch(setUserFitlersAC(payload));
  };

  const handleSortStatusIcons = (iconStatus: UserActivity) => {
    let icon;
    if (iconStatus === 'ALL') icon = 'fa-sort';
    else if (iconStatus === 'ACTIVE') icon = 'fa-sort-up';
    else icon = 'fa-sort-down';
    return icon;
  };

  return (
    <>
      <Table className="dashboard-table">
        <TableHead>
          <TableRow>
            <UsersHeader name="" icon="fad fa-sort" checkIcon className="dashboard-table__sort-header" />
            <TableHeader className="dashboard-table__dropdown-header">
              <UserDropdown />
            </TableHeader>
            <TableHeader className="dashboard-table__username-header">
              <span className="dashboard-table__sort">
                <div tabIndex={0} role="button" onClick={sortByName}>
                  {t('first_last-name')}
                  <i className={`fad ${userFilters.order === 'ASC' ? 'fa-sort-up' : 'fa-sort-down'}`} />
                </div>
              </span>
            </TableHeader>
            <UsersHeader className="dashboard-table__email" name={t('e-mail')} />
            <UsersHeader name={t('position')} className="dashboard-table__user-position-header" />
            <UsersHeader name={t('organizational-unit')} className="dashboard-table__user-unit-header" />
            <TableHeader className="dashboard-table__user-status-header">
              <span className="dashboard-table__sort">
                <div tabIndex={0} role="button" onClick={sortByStatus}>
                  {t('status')}
                  <i className={`fad ${handleSortStatusIcons(userFilters.active!)}`} />
                </div>
              </span>
            </TableHeader>
            <UsersHeader name="" dropdown />
          </TableRow>
        </TableHead>
        <TableBody>
          {hasUsersList && <UsersRows users={users} />}
          {!hasUsersList && (
            <TableZeroState>
              <Icons icon="zeroState" />
              <h3 className="first-letter-capital">{t('currently there are no users')}</h3>
            </TableZeroState>
          )}
        </TableBody>
      </Table>
      {users && showPagination({list: users, filters: userFilters}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </>
  );
};
