import React from 'react';

import {Tag} from 'components/Tag/Tag';
import {SingleProject} from 'typescript/models/SingleProject';

interface IProps {
  singleProject: SingleProject;
}

export const ListOfTags = ({singleProject}: IProps) => {
  return (
    <div className="position-relative" style={{marginBottom: '10px'}}>
      {singleProject.tags.map((tag) => {
        return (
          <Tag key={`project-modal-tag-${tag.id}`} variant={tag.color}>
            {tag.name}
          </Tag>
        );
      })}
    </div>
  );
};
