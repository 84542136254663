import {Routes} from 'constants/routes';

import Button from 'components/Button/Button';
import {Field, Form, Formik} from 'formik';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {forgotPassword} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';

import passwordChangeValidationSchema from './passwordChangeValidationSchema';

export const ChangePasswordForm = () => {
  const dispatch = useSetGlobalState();
  const history = useHistory();
  const initValues = {email: ''};
  const {t} = useTranslation();

  const handleLogin = async (inputValues: {email: string}) => {
    const {email} = inputValues;
    await forgotPassword(email)
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'Password reset request sent successfully'}));
        history.push(Routes.LOGIN);
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'Password reset request sent unsuccessfully'}));
      });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleLogin}
      validationSchema={passwordChangeValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => {
        const {errors} = props;

        return (
          <Form className="form">
            <div className="change-password-form">
              <label className="d-block" htmlFor="email">
                {t('E-mail')} <span className="required">*</span>
              </label>
              <Field
                name="email"
                type="text"
                className={`${errors.email ? 'form__error' : ''}`}
                id="email"
                placeholder="primer@gmail.com"
              />
              {errors.email && <span className="form__validation">{errors.email}</span>}
              <div className="button__wrap">
                <Button type="submit" variant="primary">
                  {t('send')}
                </Button>
                <Button variant="secondary" onClick={() => history.goBack()}>
                  {t('quit')}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
