import {Routes} from 'constants/routes';

import {useEffect} from 'react';

import './Register.scss';

import Button from 'components/Button/Button';
import {Formik, Form, Field} from 'formik';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import LogoSmall from 'images/logo-small.png';
import Logo from 'images/logo.png';
import {AuthLayout} from 'layouts/AuthLayout/AuthLayout';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {createPassword} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {logoutAC} from 'store/auth/logout/actionCreator';

import registerValidationSchema from './registerValidationSchema';

const Register = () => {
  const initValues = {password: '', confirmPassword: ''};
  const location = useLocation();
  const history = useHistory();
  const dispatch = useSetGlobalState();

  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  const isUserLogged = user && token;

  const {t} = useTranslation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const key = params.get('key');

    if (!email || !key) history.push(Routes.LOGIN);
    if (isUserLogged) {
      const shouldRedirect = true;
      dispatch(logoutAC({shouldRedirect}));
    }
    // eslint-disable-next-line
  }, []);

  const handleRegister = async (inputValues: {password: string; confirmPassword: string}) => {
    const {confirmPassword, password} = inputValues;

    const params = new URLSearchParams(location.search);

    const email = new URLSearchParams(params.get('email')!).toString().replaceAll('%40', '@').slice(0, -1);
    const key = params.get('key')!;

    await createPassword({
      email,
      key,
      password,
      confirmedPassword: confirmPassword,
    })
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'register-success'}));
        history.push(Routes.LOGIN);
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'register-fail'}));
      });
  };

  return (
    <AuthLayout pageName="register">
      <img className="d-none d-md-block" width="157" src={Logo} alt="" />
      <img className="d-md-none" width="94" src={LogoSmall} alt="" />
      <h1>{t('open-account')}</h1>
      <Formik
        initialValues={initValues}
        onSubmit={handleRegister}
        validationSchema={registerValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          const {errors} = props;
          return (
            <Form className="form form--login">
              <div className="form__group">
                <label className="d-block" htmlFor="password">
                  {t('Password')} <span className="required">*</span>
                </label>
                <Field
                  name="password"
                  type="password"
                  className={`${errors.password ? 'form__error' : ''}`}
                  id="password"
                />
                {errors.password && <span className="form__validation">{errors.password}</span>}
              </div>
              <div className="form__group">
                <label className="d-block" htmlFor="confirm-password">
                  {t('confirm_password')}
                  <span className="required">*</span>
                </label>
                <Field
                  name="confirmPassword"
                  type="password"
                  className={`${errors.confirmPassword ? 'form__error' : ''}`}
                  id="confirm-password"
                />
                {errors.confirmPassword && <span className="form__validation">{errors.confirmPassword}</span>}
              </div>
              <div className="form__action justify-content-center">
                <Button type="submit" variant="primary">
                  {t('Open-account')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <p className="auth-layout__link">
        {t('already-have-acc')} <Link to={Routes.LOGIN}>{t('sign-in')}.</Link>
      </p>
    </AuthLayout>
  );
};

export default Register;
