import MemberList from 'components/MemberLst/MemberList';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {addRemoveMemberACS} from 'store/project/addRemoveMembers/actionCreator';
import {IAddRemoveMemberPayload} from 'typescript/models/AddRemoveMember';
import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';
import isMobileView from 'utils/helpers/isMobileView';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import {MembersDropdown} from './MembersDropdown';

interface IProps {
  singleProject: SingleProject;
}

export default function Members({singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {users} = useGlobalState((state) => state.users);
  const {user: authUser} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [5, 5],
  });

  const membersLength = singleProject.members.length;
  const shouldDisplayButton = showSingleProjectButton(singleProject, authUser);
  const firstMembersName = membersLength > 0 && singleProject.members[0].name;

  const handleCheck = (user: Member) => {
    const cloneOfSingleProject = cloneDeep(singleProject);
    const payload: IAddRemoveMemberPayload = {dto: {id: singleProject.id, userId: user.id}};

    const isMemberInList = singleProject.members.find((member) => member.id === user.id);
    if (isMemberInList) {
      payload.addRemoveMember = false;
      const filteredList = cloneOfSingleProject.members.filter((member) => member.id !== user.id);
      cloneOfSingleProject.members = filteredList;
      payload.singleProject = cloneOfSingleProject;
    } else {
      payload.addRemoveMember = true;
      cloneOfSingleProject.members.push(user);
      payload.singleProject = cloneOfSingleProject;
    }

    dispatch(addRemoveMemberACS(payload));
  };

  const handleToggle = () => {
    toggleOpen();
  };

  return (
    <div>
      <div className="project__title">
        <h3>{t('members')}:</h3>
        <div className="project__text">
          <div className="project__text-container">
            {membersLength! >= 2 && (
              <>
                {!isMobileView() ? (
                  singleProject.members.slice(0, 2).map((member) => <>{member.name}, </>)
                ) : (
                  <>{firstMembersName}</>
                )}
                <MemberList title="organizers" subtitle="group organizers" members={singleProject.members || []} />
              </>
            )}
            {membersLength! === 1 && firstMembersName}
            <div className="dropdown" ref={containerRef}>
              {shouldDisplayButton && (
                <button
                  className={`project__title-more${isOpen ? ' project__title-more--open' : ''} organizer-more`}
                  onClick={handleToggle}
                  ref={setReferenceElement}
                >
                  <i className={`far ${membersLength > 0 ? 'fa-pencil' : 'fa-plus'}`} />
                </button>
              )}
              {isOpen && (
                <MembersDropdown
                  singleProject={singleProject}
                  users={users}
                  handleCheck={handleCheck}
                  styles={styles}
                  setPopperElement={setPopperElement}
                  attributes={attributes}
                  handleToggle={handleToggle}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
