import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';

import {UserActions} from '../actions';

export const setUserFitlersAC = (payload: GetUsersDTO) => {
  return {
    type: UserActions.SET_USER_FILTERS,
    payload,
  };
};
