import {useEffect, useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {getComments} from 'store/comments/get/actionCreator';
import {SingleProject} from 'typescript/models/SingleProject';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';

import AddComment from './AddComment/AddComment';
import SingleComment from './SingleComment/SingleComment';

interface IProps {
  singleProject: SingleProject;
}

export default function Comments({singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {user} = useGlobalState((state) => state.auth);
  const {comments} = useGlobalState((state) => state.comments);
  const [comment, setComment] = useState<string>('');
  const [isReplied, setIsReplied] = useState(false);

  useEffect(() => {
    dispatch(getComments(singleProject.id));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="project__title project__title--main project__comments">
        <span className="d-none d-md-block">
          <i className="far fa-comment-lines" />
        </span>
        <h3>{t('comments')}</h3>
      </div>
      <div className="project__inner">
        {isNotArchivedOrDeleted(singleProject) && (
          <>
            <label htmlFor="comment">{user?.name}</label>
            <AddComment setIsReplied={setIsReplied} comment={comment} setComment={setComment} />
          </>
        )}
        {comments.map((com) => {
          return (
            <SingleComment
              isReplied={isReplied}
              setIsReplied={setIsReplied}
              setComment={setComment}
              comment={com}
              key={com.id}
            />
          );
        })}
      </div>
    </>
  );
}
