import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {deleteUserACS, setDeleteUserAC} from 'store/user/deleteUser/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export const ConfirmDeleteUser = () => {
  const dispatch = useSetGlobalState();
  const {deleteUserId, users} = useGlobalState((state) => state.users);

  const {t} = useTranslation();

  const deleteUser = () => {
    if (deleteUserId && users?.length) dispatch(deleteUserACS({id: deleteUserId, listOfUsers: users}));
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  useEffect(() => {
    return () => {
      dispatch(setDeleteUserAC(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--danger">
            <i className="far fa-exclamation-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>{t('confirm-user-delete')}</h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={deleteUser}>
                {t('delete')}
              </button>
              <button
                className="btn btn--secondary btn--large"
                onClick={() => dispatch(toggleModalAC({isOpen: false, component: null}))}
              >
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
