import React from 'react';

import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

export const AddUsersButton = () => {
  const dispatch = useSetGlobalState();

  return (
    <button
      className="header__action header__action--add"
      onClick={() => {
        dispatch(toggleModalAC({isOpen: true, component: ModalComponents.ADD_USER}));
      }}
    >
      <i className="far fa-user-plus" />
    </button>
  );
};
