import React from 'react';

import './ConfirmDialog.scss';
import 'layouts/AuthLayout/AuthLayout.scss';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmDialog() {
  return (
    <div className="confirm-dialog">
      <CloseModalButton />
    </div>
  );
}
