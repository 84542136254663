import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useLocation} from 'react-router';
import {setProjectViewAC} from 'store/app/setProjectView/actionCreator';

export default function NavbarViewSwitcher() {
  const dispatch = useSetGlobalState();
  const {projectView} = useGlobalState((state) => state.app);
  const location = useLocation();
  const isNotDashboardPage = location.pathname !== '/dashboard';

  const displayProperClass = () => {
    let payload = 'header__action ';
    if (isNotDashboardPage) {
      payload += 'header__action--active';
    } else if (!isNotDashboardPage && projectView === 'table') payload += 'header__action--active';

    return payload;
  };

  return (
    <>
      <button
        className={displayProperClass()}
        onClick={() => {
          dispatch(setProjectViewAC('table'));
        }}
      >
        <i className="far fa-align-justify" />
      </button>
      <button
        className={`header__action ${projectView === 'calendar' ? 'header__action--active' : ''}`}
        onClick={() => {
          dispatch(setProjectViewAC('calendar'));
        }}
        disabled={isNotDashboardPage}
      >
        <i className="fas fa-th" />
      </button>
    </>
  );
}
