import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

import {DeletedProjectsActions} from '../actions';

export function getDeletedProjectsACS(payload: IGetProjectsDTO) {
  return {
    type: DeletedProjectsActions.GET_DELETED_PROJECTS,
    payload,
  };
}
