import {IArchiveProject} from 'typescript/interfaces/IProjectActions';

import {ProjectActions} from '../actions';

export function archiveProjectAC(id: number): IArchiveProject {
  return {
    type: ProjectActions.ARCHIVE_PROJECT,
    payload: id,
  };
}
