import {Routes} from 'constants/routes';

import NotFoundImage from 'images/not-found.png';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import './NotFound.scss';

export const NotFound = () => {
  const {t} = useTranslation();

  return (
    <AppLayout pageName="not-found">
      <div className="not-found">
        <div className="not-found__content">
          <img src={NotFoundImage} alt="not-found" />
          <p className="not-found-content__description">{t('not-found-description')}</p>
          <p className="not-found-content__link">
            {t('go-to')}
            <NavLink to={Routes.DASHBOARD}>{t('project-e')}</NavLink>.
          </p>
        </div>
      </div>
    </AppLayout>
  );
};
