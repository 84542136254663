import {useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {markAllAsRead} from 'services/notificationService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {getNotificationsACS} from 'store/notification/getNotifications/actionCreators';
import {markAsReadUnreadACS} from 'store/notification/markAsRead/actionCreators';

import {NotificationsDropdown} from './NotificationsDropdown';
import {NotificationZeroState} from './NotificationZeroState';

interface IProps {
  readMore: boolean;
  setReadMore: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationDropdown = ({readMore, setReadMore}: IProps) => {
  const dispatch = useSetGlobalState();
  const {notifications, notificationCount} = useGlobalState((state) => state.notifications);
  const {t} = useTranslation();

  const [showAll, setShowAll] = useState<boolean>(false);

  const handleViewAll = () => {
    const unReadIds = notifications.filter((x) => !x.read).map((x) => x.id);

    markAllAsRead(unReadIds)
      .then(() => {
        dispatch(getNotificationsACS({order: 'DESC', sort: 'time'}));
        setShowAll(true);
      })
      .catch((error) => {
        dispatch(setGlobalNotification({variant: 'error', message: error?.message ? error.message : 'Error'}));
      });
  };

  const handleMarkAsReadUnread = (id: number, status: boolean) => {
    const markAsReadUnreadPayload = {
      id,
      listOfNotifications: notifications,
      status,
      sort: showAll,
    };
    dispatch(markAsReadUnreadACS(markAsReadUnreadPayload));
  };

  const handleShowAll = () => {
    setReadMore(false);
    setShowAll(false);
    dispatch(getNotificationsACS({order: 'DESC', sort: 'time'}));
  };

  const handleShowUnread = () => {
    setReadMore(false);
    setShowAll(true);
    dispatch(getNotificationsACS({sort: 'read', read: false}));
  };

  const notificationList = () => {
    let payload = notifications;
    if (!readMore) {
      if (notifications.length < 3) payload = notifications.slice(0, notifications.length);
      else payload = notifications.slice(0, 3);
    }
    return payload;
  };

  const addProperClass = () => {
    let className = 'notification-popup-body__list notification-popup-body__list';

    if (notifications.length && notificationCount.ALL > 0) className += readMore ? '--more' : '--less';
    else if (!notifications.length) className += '__zero-state';
    return className;
  };

  const showSeeAll = notifications.length > 0 && !!notificationCount.UNREAD;

  return (
    <div className="notification-popup-body">
      <div className="notification-popup-body__btn">
        {notificationCount.ALL > 0 && (
          <div className="position-relative w-100">
            {showAll ? (
              <div role="button" tabIndex={0} onClick={handleShowAll} className="notification-popup-body__filter">
                <p>{t('all-notifications')}</p>
              </div>
            ) : (
              <div role="button" tabIndex={0} onClick={handleShowUnread} className="notification-popup-body__filter">
                <p>{t('unread')}</p>
              </div>
            )}

            {showSeeAll && (
              <div role="button" tabIndex={0} onClick={handleViewAll} className="notification-popup-body__mark-all">
                <p>{t('mark-all-as-read')}</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={addProperClass()}>
        {notifications.length > 0 ? (
          <NotificationsDropdown notificationList={notificationList} handleRead={handleMarkAsReadUnread} />
        ) : (
          <NotificationZeroState handleShowAll={handleShowAll} allNotifications={notificationCount.ALL} />
        )}
      </div>
    </div>
  );
};
