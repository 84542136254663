import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {undeleteProject, undeleteProjectsBulk} from 'services/projectService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {getDeletedProjectsACS} from 'store/deletedProjects/getDeletedProjects/actionCreator';
import {setCheckedDeletedProjectsAC} from 'store/deletedProjects/setCheckedDeletedProjects/actionCreator';
import {setDeletedProjectAC} from 'store/deletedProjects/setDeletedProject/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import CloseModalButton from '../shared/CloseModalButton';

export const ConfirmActivateProject = () => {
  const dispatch = useSetGlobalState();
  const {checkedProjects, deletedProject, projects, projectFilters} = useGlobalState((state) => state.deletedProjects);

  const {t} = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(setDeletedProjectAC(null));
    };
    // eslint-disable-next-line
  }, []);

  const handleActivateSingle = async () => {
    if (deletedProject) {
      await undeleteProject(deletedProject)
        .then(() => {
          dispatch(
            setGlobalNotification({
              variant: 'success',
              message: 'Project successfully activated',
              icon: 'fa-credit-card-blank',
            }),
          );
        })
        .catch(() => {
          dispatch(
            setGlobalNotification({
              variant: 'error',
              message: 'Project activation failed',
              icon: 'fa-credit-card-blank',
            }),
          );
        })
        .finally(() => {
          batch(() => {
            dispatch(getDeletedProjectsACS(projectFilters));
            dispatch(toggleModalAC({isOpen: false, component: null}));
          });
        });
    }
  };

  const handleActivateAll = async () => {
    await undeleteProjectsBulk(checkedProjects)
      .then(() => {
        dispatch(
          setGlobalNotification({
            variant: 'success',
            message: 'Project successfully activated',
            icon: 'fa-credit-card-blank',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setGlobalNotification({
            variant: 'error',
            message: 'Projects activation failed',
            icon: 'fa-credit-card-blank',
          }),
        );
      })
      .finally(() => {
        batch(() => {
          dispatch(getDeletedProjectsACS(projectFilters));
          dispatch(setCheckedDeletedProjectsAC([]));
          dispatch(toggleModalAC({isOpen: false, component: null}));
        });
      });
  };

  const handleActivateProject = () => {
    if (deletedProject) handleActivateSingle();
    if (checkedProjects.length > 0) handleActivateAll();
  };

  const handleTitle = () => {
    let payload = '';
    if (deletedProject) {
      const matchingProject = projects.find((project) => project.id === deletedProject);
      payload += `${renderProjectTitle(matchingProject!.title, 25)}`;
    } else if (checkedProjects.length === 1) {
      const matchingProject = projects.find((project) => project.id === checkedProjects[0]);
      payload += `${renderProjectTitle(matchingProject!.title, 25)}`;
    }
    return payload;
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__wrap">
        <div className="confirm-dialog--warning">
          <i className="far fa-question-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>
            {checkedProjects.length > 1 ? t('activate projects confirmation') : t('activate project confirmation')}
            {checkedProjects.length === 1 && <b>{handleTitle()}</b>}?
          </h6>
          <div className="btn__wrap">
            <button className="btn btn--primary btn--large" onClick={handleActivateProject}>
              {t('Activate')}
            </button>
            <button
              className="btn btn--secondary btn--large"
              onClick={() => dispatch(toggleModalAC({isOpen: false, component: null}))}
            >
              {t('quit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
