import {useState} from 'react';

import {NotificationContent} from 'typescript/models/Notifications';

interface IProps {
  handleRead: (id: number, status: boolean) => void;
  notification: NotificationContent;
}

export const NotificationStatus = ({handleRead, notification}: IProps) => {
  const handleReadNotif = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleRead(notification.id, notification.read);
  };

  const [showReadTooltip, setShowReadTooltip] = useState<boolean>(false);
  const [showUnreadTooltip, setShowUnreadTooltip] = useState<boolean>(false);

  const shouldShowTooltip = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.className === 'fa fa-circle') setShowReadTooltip(true);
    if (e.currentTarget.className === 'fa fa-circle-thin') setShowUnreadTooltip(true);
  };

  const handleOnMouseLeave = () => {
    setShowReadTooltip(false);
    setShowUnreadTooltip(false);
  };
  return (
    <div tabIndex={0} role="button" onClick={handleReadNotif} className="notification-item__status">
      <i
        className={`fa fa-circle${notification.read ? '-thin' : ''}`}
        aria-hidden="true"
        onMouseEnter={shouldShowTooltip}
        onMouseLeave={handleOnMouseLeave}
      />
      {showReadTooltip && <span>Označite kao pročitano</span>}
      {showUnreadTooltip && <span>Označite kao nepročitano</span>}
    </div>
  );
};
