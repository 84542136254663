import {AnyAction, Dispatch} from 'redux';
import getArchivedProjectsACS from 'store/archive/getArchivedProjects/actionCreator';
import {searchArchiveProjectsACS} from 'store/archive/searchArchiveProjects/actionCreator';
import {getDeletedProjectsACS} from 'store/deletedProjects/getDeletedProjects/actionCreator';
import {searchDeletedProjectsACS} from 'store/deletedProjects/searchDeletedProjects/actionCreator';
import {getProjectsSagaACS} from 'store/project/getProjects/actionCreator';
import {searchProjectACS} from 'store/project/searchProjects/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {searchTagDetailsACS} from 'store/tag/searchTagDetails/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {searchUsersACS} from 'store/user/searchUser/actionCreator';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

interface IProps {
  dispatch: Dispatch<AnyAction>;
  pathname: string;
  shouldSearch: boolean;
  value?: string;
  projectFilters: IGetProjectsDTO;
}

export const dispatchProperFetchAndSearchFunction = (props: IProps) => {
  const {dispatch, pathname, shouldSearch, value = '', projectFilters} = props;

  const payload: IGetProjectsDTO = {size: 15, page: 0, order: 'DESC', sort: 'updatedOn'};

  switch (pathname) {
    case '/dashboard':
      if (!shouldSearch) dispatch(getProjectsSagaACS(projectFilters));
      else if (value.length > 2) {
        dispatch(searchProjectACS({value, filters: {size: 15, page: 0, order: 'DESC', sort: 'updatedOn'}}));
      }
      break;
    case '/users':
      if (!shouldSearch) dispatch(getUsersACS({size: 15, page: 0, order: 'DESC', sort: 'updatedOn'}));
      else if (value.length > 2) dispatch(searchUsersACS(value));
      break;
    case '/archive':
      if (!shouldSearch) dispatch(getArchivedProjectsACS({...payload, active: false}));
      else if (value.length > 2) dispatch(searchArchiveProjectsACS(value));
      break;
    case '/deleted-projects':
      if (!shouldSearch) dispatch(getDeletedProjectsACS({...payload, deleted: true}));
      else if (value.length > 2) dispatch(searchDeletedProjectsACS(value));
      break;
    case '/tag':
      if (!shouldSearch) dispatch(getTagDetailsACS(payload));
      else if (value.length > 2) dispatch(searchTagDetailsACS(value));
      break;
  }
};
