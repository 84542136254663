import {IDeleteDocument} from 'typescript/interfaces/IDocumentsActions';

import {DocumentsActions} from '../actions';

export function deleteDocument(payload: IDeleteDocument['payload']): IDeleteDocument {
  return {
    type: DocumentsActions.DELETE_DOCUMENT,
    payload,
  };
}
