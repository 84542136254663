import {useEffect, useState} from 'react';

import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useTranslation} from 'react-i18next';
import {SingleProject} from 'typescript/models/SingleProject';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import {DateDropdown} from './DateDropdown';
import {displayRemainder} from './Helpers/displayRemainder';

interface IProps {
  singleProject: SingleProject;
}

export default function ProjectDate({singleProject}: IProps) {
  const {user} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();
  const [date, setDate] = useState<string>();
  const [timeFrom, setTimeFrom] = useState<string>();
  const [timeTo, setTimeTo] = useState<string>();

  const shouldDisplayButton = showSingleProjectButton(singleProject, user);

  let offset: [number, number] = [5, 5];
  if (singleProject.date) offset = [-21, 10];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset,
  });

  const datePickerProps = {
    styles,
    date,
    setDate,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    attributes,
    isOpen,
    setReferenceElement,
    setPopperElement,
    toggleOpen,
    singleProject,
  };

  useEffect(() => {
    if (!isOpen) {
      setDate(undefined);
      setTimeFrom(undefined);
      setTimeTo(undefined);
    }
  }, [isOpen]);

  return (
    <div>
      <div className="project__title">
        <h3>
          {t('date')} {t('event')}:
        </h3>
        <div>
          <span>
            {singleProject.date ? (
              <div className="project__text" ref={containerRef}>
                <span>{new Date(singleProject.date).toLocaleDateString('en-GB')}</span>
                {shouldDisplayButton && (
                  <button
                    className={`project__title-more${isOpen ? ' project__title-more--open' : ''}`}
                    onClick={() => toggleOpen()}
                    ref={setReferenceElement}
                  >
                    <i className="fal fa-pencil" aria-hidden="true" />
                  </button>
                )}
                <DateDropdown {...datePickerProps} />
                {displayRemainder(singleProject) ? (
                  <span className="project__text-remainder" style={{color: 'white'}}>
                    <i className="fal fa-clock" />
                    {displayRemainder(singleProject)}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <>
                {shouldDisplayButton && (
                  <button
                    className={`project__title-more${isOpen ? ' project__title-more--open' : ''} organizer-more`}
                    onClick={() => toggleOpen()}
                    ref={setReferenceElement}
                  >
                    <i className="fal fa-plus" aria-hidden="true" />
                  </button>
                )}
                <DateDropdown {...datePickerProps} />
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
