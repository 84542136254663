import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setPreviewProjectAC} from 'store/project/Calendar/SetPreviewProject/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';
import './ProjectDayPreview.scss';
import {SingleProjectPreview} from './SingleProject/SingleProject';

export default function ProjectDayPreview() {
  const dispatch = useSetGlobalState();

  const {
    singleDayProjectList: {listOfProjects},
  } = useGlobalState((state) => state.project);

  useEffect(() => {
    return () => {
      dispatch(setPreviewProjectAC({listOfProjects: [], date: ''}));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="project">
      <div className="project__dialog m-0" role="document">
        <div className="project__content project__content--day">
          <CloseModalButton />
          {listOfProjects.map((singleProject) => {
            return <SingleProjectPreview key={singleProject.id} singleProject={singleProject} />;
          })}
        </div>
      </div>
    </div>
  );
}
