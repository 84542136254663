import {useEffect, useState} from 'react';

import './DashboardCalendar.scss';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {uniqueId} from 'lodash';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import {CalendarHeader} from './CalendarComponents/CalendarHeader';
import {CalendarWeek} from './CalendarComponents/CalendarWeek';
import {calendarHeaderNames} from './CalendarComponents/constants';
import buildCalendar from './Helpers/buildCalendar';

export default function DashboardCalendar() {
  const [calendar, setCalendar] = useState<moment.Moment[][]>([]);
  const [currentlySelectedDay, setCurrentlySelectedDay] = useState<moment.Moment>(moment());
  const currentYear = currentlySelectedDay.format('YYYY');
  const currentMonth = currentlySelectedDay.format('MMMM');
  const {t} = useTranslation();

  const handlePrevMonth = () => {
    const previousMonthDate = currentlySelectedDay.clone().subtract(1, 'months');
    setCurrentlySelectedDay(previousMonthDate);
  };

  const handleNextMonth = () => {
    const nextMonthDate = currentlySelectedDay.clone().add(1, 'months');
    setCurrentlySelectedDay(nextMonthDate);
  };

  useEffect(() => {
    const buildedCalendar = buildCalendar(currentlySelectedDay);
    setCalendar(buildedCalendar);
    // eslint-disable-next-line
  }, [currentlySelectedDay]);

  return (
    <section className="calendar">
      <div className="calendar__nav">
        <div className="d-flex align-items-center">
          <button className="calendar__nav-item" onClick={handlePrevMonth}>
            <i className="fal fa-angle-left" />
          </button>
          <button className="calendar__nav-item" onClick={handleNextMonth}>
            <i className="fal fa-angle-right" />
          </button>
          <h2>
            {t(`${currentMonth}`)} {currentYear}
          </h2>
        </div>
        <div className="calendar-nav__dropdown">
          <RoutesDropdown>
            <div className="routes-dropdown__icon">
              <i className="far fa-credit-card-blank" aria-hidden="true" />
            </div>
          </RoutesDropdown>
        </div>
      </div>
      <div className="calendar__week">
        <div className="row g-0">
          {calendarHeaderNames.map((headerName) => {
            return <CalendarHeader key={headerName} name={headerName} />;
          })}
        </div>
      </div>
      <div className="calendar__days">
        {calendar.map((week, index) => {
          return (
            <CalendarWeek
              isFirstWeek={index === 0}
              key={uniqueId()}
              currentlySelectedDay={currentlySelectedDay}
              week={week}
            />
          );
        })}
      </div>
    </section>
  );
}
