import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagDetail} from 'typescript/models/TagDetails';

import ZeroState from '../ZeroState/ZeroState';
import MobileHeader from './MobileHeader';
import MobileTableRow from './MobileTableRow';

interface IProps {
  filters: GetTagDetailsDTO;
  tagsList: TagDetail[];
}

export default function MobileTable({filters, tagsList}: IProps) {
  const hasTagsList = tagsList.length > 0;

  return (
    <div className="tag-table tag-table--mobile">
      <MobileHeader filters={filters} />
      {hasTagsList && tagsList.map((tag) => <MobileTableRow key={tag.id} tag={tag} />)}
      {!hasTagsList && <ZeroState type="regular" />}
    </div>
  );
}
