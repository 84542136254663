import {Routes} from 'constants/routes';

import Button from 'components/Button/Button';
import {Field, Form, Formik} from 'formik';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {loginUserSagaACS} from 'store/auth/login/actionCreator';

import loginValidationSchema from './loginValidationSchema';

export const LoginForm = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const initValues = {email: '', password: ''};

  const handleLogin = (inputValues: {email: string; password: string}) => {
    const payload = {
      email: inputValues.email,
      password: inputValues.password,
    };

    dispatch(loginUserSagaACS(payload));
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleLogin}
      validationSchema={loginValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => {
        const {errors} = props;

        return (
          <Form className="form form--login">
            <div className="form__group">
              <label className="d-block" htmlFor="email">
                {t('E-mail')} <span className="required">*</span>
              </label>
              <Field
                name="email"
                type="text"
                className={`${errors.email ? 'form__error' : ''}`}
                id="email"
                placeholder="primer@gmail.com"
              />
              {errors.email && <span className="form__validation">{errors.email}</span>}
            </div>
            <div className="form__group">
              <label className="d-block" htmlFor="password">
                {t('Password')} <span className="required">*</span>
              </label>
              <Field
                name="password"
                type="password"
                className={`${errors.password ? 'form__error' : ''}`}
                id="password"
              />
              {errors.password && <span className="form__validation">{errors.password}</span>}
            </div>

            <div className="form__group form__group--password justify-content-end">
              <NavLink to={Routes.PASSWORD_CHANGE}>{t('forgoten-password?')}</NavLink>
            </div>
            <div className="button__wrap justify-content-center">
              <Button type="submit" variant="primary">
                {t('sign-in')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
