import Checkbox from 'components/Checkbox/Checkbox';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setAddUserDataAC} from 'store/user/setAddUserData/actionCreator';
import {setCheckedMembers} from 'store/user/setCheckedMembersList/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {Member} from 'typescript/models/Member';

import './Rows.scss';

interface IProps {
  user: Member;
}

export const Rows = ({user}: IProps) => {
  const dispatch = useSetGlobalState();
  const {checkedMembers} = useGlobalState((state) => state.users);
  const {t} = useTranslation();

  const isOnWait = user.status === 'ON_WAIT';

  const checkRow = (checkId: number) => {
    const checkedMemberId = checkId;

    let checkedMembersPayload;
    if (checkedMembers.includes(checkedMemberId)) {
      const fileredMembersList = checkedMembers.filter((member) => member !== checkedMemberId);
      checkedMembersPayload = fileredMembersList;
    } else {
      checkedMembersPayload = [...checkedMembers, checkedMemberId];
    }

    dispatch(setCheckedMembers(checkedMembersPayload));
  };

  const handleClick = () => {
    const userPayload = {
      id: user.id,
      roles: user.roles.length > 0 ? user.roles : ['USER'],
      email: user.email,
      name: user.name,
      unitName: user.unit,
      positionName: user.position,
    };

    dispatch(setAddUserDataAC(userPayload));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.SINGLE_USER}));
  };

  const handleStatusCheckbox = () => {
    let payload = true;
    if (user.status === 'CALL_EXPIRED') payload = false;
    return payload;
  };

  return (
    <div className="grid__row" role="button" onClick={handleClick}>
      <div className="grid__check" role="button" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          id={`${user.id}`}
          onChange={checkRow}
          checked={checkedMembers.includes(user.id)}
          className="checkbox"
          disabled={handleStatusCheckbox()}
        />
      </div>
      <div className="grid__content">
        <div className="user-row-mobile__name">
          <p className="mb-1">{user.name}</p>
          {user.roles.includes('ADMIN') && <span className="grid__small user-row-mobile__admin-label">ADMIN</span>}
        </div>
        <span className="grid__small grid__small--light">{user.email}</span>
        <span className="grid__small">{user.position}</span>
        <span className="grid__small">{user.unit}</span>
      </div>
      <div className="grid__info tooltip user-row-mobile__status">
        <div className="tooltip user-row-mobile__icon">
          {user.status === 'CALL_EXPIRED' ? (
            <i className="fal fa-exclamation-triangle" />
          ) : (
            <p style={{color: isOnWait ? '#0379bf' : '#717888'}}>{t(user.status)}</p>
          )}
        </div>
      </div>
    </div>
  );
};
