import {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import MobileSort from 'components/MobileSort/MobileSort';
import {Pagination} from 'components/Pagination/Pagination';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {Tag} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {setCheckedTagsProjectAC} from 'store/project/setCheckedTags/actionCreator';
import {setProjectFilters} from 'store/project/setProjectFilters/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import {isAdmin} from 'utils/getUserRole';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';
import {showPagination} from 'utils/helpers/showPagination';

import {DashboardTagFilter} from './DashboardTagFilter';
import {addProjectFilters} from './Helpers/addFilter';

import './DashboardMobileTable.scss';

export const DashboardTableMobile = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {user} = useGlobalState((state) => state.auth);
  const {projects, checkedTags, projectFilters, pagination} = useGlobalState((state) => state.project);
  const cloneOfProjectFilters = cloneDeep(projectFilters);

  const hasProjectsList = projects.length > 0;
  const isItSortByFavorite = cloneOfProjectFilters.sort === 'favorite' && cloneOfProjectFilters.order === 'DESC';

  const [adminFilterChecked, setAdminFilterChecked] = useState(false);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset: [26, 12],
  });

  const {
    styles: sortStyles,
    attributes: sortAttributes,
    isOpen: isSortOpen,
    toggleOpen: toggleSortOpen,
    setReferenceElement: setSortRefEl,
    setPopperElement: setSortPopperEl,
    containerRef: sortContainerRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [67, 12],
  });

  const handleProjectClick = (project: SingleProject) => {
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(project));
  };

  const handleFavoriteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, project: SingleProject) => {
    e.stopPropagation();
    const payload = {id: project.id, filters: {...projectFilters}};
    if (project.favorite) dispatch(removeFavoriteProjectAC(payload));
    else dispatch(addFavoriteProjectAC(payload));
  };

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();
    let payload;
    let page = cloneOfProjectFilters.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...cloneOfProjectFilters, page: (page += 1)};
    else payload = {...cloneOfProjectFilters, page: (page -= 1)};
    dispatch(setProjectFilters(payload));
  };

  const handleAdminFilterProjects = () => {
    setAdminFilterChecked(!adminFilterChecked);
    let payload = cloneOfProjectFilters;

    if (cloneOfProjectFilters.own) payload = {...cloneOfProjectFilters, own: false};
    else payload = {...cloneOfProjectFilters, own: true};

    dispatch(setProjectFilters(payload));
  };

  const handleSort = (value: string) => {
    const payload = addProjectFilters(cloneOfProjectFilters, value);
    dispatch(setProjectFilters(payload));
  };

  const mobileFilters = [
    {name: `${t('projects')}`, value: 'title'},
    {name: `${t('date')} ${t('event')}`, value: 'date'},
    {name: `${t('time')}`, value: 'updatedOn'},
  ];

  const handleTagFilter = (e: React.MouseEvent, tagID: number) => {
    e.stopPropagation();

    let checkedTagPayload: number[] = [];
    if (checkedTags.includes(tagID)) checkedTagPayload = checkedTags.filter((tag) => tag !== tagID);
    else checkedTagPayload = [...checkedTags, tagID];

    dispatch(setCheckedTagsProjectAC(checkedTagPayload));

    const tagIds = checkedTagPayload.map(String).join(',');
    const payload = {...projectFilters, tagIds};
    if (tagIds.length) dispatch(setProjectFilters(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setProjectFilters(newPayload));
    }
  };

  const renderTags = (tags: SingleProject['tags']) => {
    if (tags.length <= 3) {
      return tags.map((tag) => (
        <div
          key={`project-tag-mobile${tag.id}`}
          className="grid__tag"
          role="button"
          tabIndex={0}
          onClick={(e) => handleTagFilter(e, tag.id)}
        >
          <Tag variant={tag.color}>{tag.name}</Tag>
        </div>
      ));
    }

    return (
      <>
        {tags.slice(0, 2).map((tag) => (
          <Tag key={`project-tag-${tag.id}`} variant={tag.color}>
            {tag.name}
          </Tag>
        ))}
        <TruncateBadge num={tags.length - 2} />
      </>
    );
  };

  return (
    <div>
      <div className="grid">
        <div className="grid__header">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="grid__nav">
                  <div className="grid__inner">
                    <div className={`grid__star ${isItSortByFavorite ? 'grid__star--active' : ''}`}>
                      <button className="btn btn--clear" onClick={() => handleSort('favorite')}>
                        <i className="far fa-star" />
                      </button>
                    </div>
                    {user && isAdmin(user) && (
                      <div className="dashboard-table__checkbox-container">
                        <div className="dashboard-table__checkbox">
                          <Checkbox
                            id="adminCheck"
                            onChange={handleAdminFilterProjects}
                            checked={adminFilterChecked}
                            className="checkbox"
                          />
                        </div>
                        <p>{t('all-projects')}</p>
                      </div>
                    )}
                  </div>
                  <div className="grid__inner">
                    <div className="header__inner">
                      <div className="header__action header__action--filter" ref={sortContainerRef}>
                        <div role="button" tabIndex={0} onClick={() => toggleSortOpen()} ref={setSortRefEl}>
                          <i className="far fa-sort-alt" />
                        </div>
                        {isSortOpen && (
                          <div
                            id="popup_tag-filter"
                            ref={setSortPopperEl}
                            style={sortStyles.popper}
                            {...sortAttributes.popper}
                            className="tag-dropdown"
                          >
                            <MobileSort filters={mobileFilters} handleSort={handleSort} />
                          </div>
                        )}
                      </div>
                      <div className="header__action" ref={containerRef}>
                        <div role="button" tabIndex={0} onClick={() => toggleOpen()} ref={setReferenceElement}>
                          <i className="far fa-filter" />
                        </div>
                        {isOpen && (
                          <div
                            id="popup_tag-filter"
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="tag-dropdown"
                          >
                            <DashboardTagFilter />
                          </div>
                        )}
                      </div>
                    </div>
                    <RoutesDropdown>
                      <i className="far fa-credit-card-blank" />
                    </RoutesDropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {hasProjectsList &&
          projects.map((project) => (
            <div className="grid__row" role="button" key={project.id} onClick={() => handleProjectClick(project)}>
              <div className="grid__star dashboard-table__star">
                <button onClick={(e) => handleFavoriteClick(e, project)}>
                  <i className={`far fa-star ${project.favorite && 'starred'}`} />
                </button>
              </div>
              <div className="grid__content">
                <p className="dashboard-mobile-table__row-title">{renderProjectTitle(project.title, 40)}</p>
                <span className="grid__date">{project.date && moment(project.date).format('DD/MM/YYYY')}</span>
                <div>{renderTags(project.tags)}</div>
              </div>
              <div className="grid__document">
                <i className="far fa-paperclip" style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}} />
                {project.documents.length > 0 && `(${project.documents.length})`}
              </div>
            </div>
          ))}
      </div>
      {!hasProjectsList && (
        <div className="dashboard-table__zero-state">
          <div>
            <div>
              <Icons icon="zeroState" />
              <h3 className="first-letter-capital">{t('currently there are no added projects')}</h3>
            </div>
          </div>
        </div>
      )}
      {showPagination({list: projects, filters: projectFilters}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </div>
  );
};
