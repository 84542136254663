import Icons from 'components/Icons/Icons';
import {Pagination} from 'components/Pagination/Pagination';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setProjectFilters} from 'store/project/setProjectFilters/actionCreator';
import {Member} from 'typescript/models/Member';
import {showPagination} from 'utils/helpers/showPagination';

import {Header} from './Header';
import {Rows} from './Rows';

interface IProps {
  users: Member[] | null;
}

export const MobileAndTabletTable = ({users}: IProps) => {
  const {pagination, projectFilters} = useGlobalState((state) => state.project);

  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const hasUsersList = users && users.length > 0;

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();

    let payload;
    let page = projectFilters.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...projectFilters, page: (page += 1)};
    else payload = {...projectFilters, page: (page -= 1)};

    dispatch(setProjectFilters(payload));
  };

  return (
    <div className="grid">
      <Header />
      {hasUsersList &&
        users?.map((user) => {
          return <Rows key={user.id} user={user} />;
        })}
      {!hasUsersList && (
        <div className="dashboard-table__zero-state" style={{marginTop: '70px'}}>
          <Icons icon="zeroState" />
          <h3 className="first-letter-capital">{t('currently there are no users')}</h3>
        </div>
      )}
      {showPagination({list: users ? users : [], filters: {}}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </div>
  );
};
