export const API = {
  REFRESH_TOKEN: '/refresh-token',
  USER_SERVICE: {
    LOGIN: '/login',
    USER: '/api/user',
    FORGOT_PASSWORD: '/api/user/forgot-password',
    CREATE_PASSWORD: '/api/user/create-password',
    CHANGE_PASSWORD: '/api/user/change-password',
    GET_CURRENT_USER: '/api/user/me',
  },
  PROJECT_SERVICE: {
    BASE: '/api/project',
    ARCHIVED: '/api/project?active=false',
  },
  TAG_SERVICE: {
    BASE: '/api/project/tag',
    DETAILS: '/api/project/tag/details',
  },
  NOTIFICATION_SERVICE: {
    BASE: '/api/notification',
  },
  SEARCH: {
    PROJECTS: '/api/search/project/start',
    USERS: '/api/search/user',
    TAGS: '/api/search/tag',
  },
  SETUP: {
    BASE: '/api/setup',
    GET: '/api/setup/get',
    NAME: '/api/setup/name',
    LOGO: '/api/setup/logo',
  },
};
