import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {cloneDeep} from 'lodash';
import {useHistory} from 'react-router';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setUserFitlersAC} from 'store/user/setUserFilters/actionCreator';
import {isAdmin} from 'utils/getUserRole';

import {DesktopTable} from './UsersTable/DesktopScreen/DesktopTable';
import {MobileAndTabletTable} from './UsersTable/MobileAndTabletScreen/MobileAndTabletScreen';

export const Users = () => {
  const dispatch = useSetGlobalState();
  const {users, pagination, userFilters} = useGlobalState((state) => state.users);
  const {user} = useGlobalState((state) => state.auth);
  const cloneOfUserFilters = cloneDeep(userFilters);
  const history = useHistory();

  const isTabletAndUp = useMediaBreakpoint('up', 'lg');

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();

    let payload;
    let page = cloneOfUserFilters.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...cloneOfUserFilters, page: (page += 1)};
    else payload = {...cloneOfUserFilters, page: (page -= 1)};
    dispatch(setUserFitlersAC(payload));
  };

  useEffect(() => {
    dispatch(getUsersACS(userFilters));
    // eslint-disable-next-line
  }, [userFilters]);

  useEffect(() => {
    if (user && !isAdmin(user)) history.push('*');
    // eslint-disable-next-line
  }, [user]);

  return (
    <AppLayout pageName="users">
      {isTabletAndUp ? (
        <DesktopTable users={users} pagination={pagination} handlePageChange={handlePageChange} />
      ) : (
        <MobileAndTabletTable users={users} />
      )}
    </AppLayout>
  );
};
