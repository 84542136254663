import {Table, TableBody} from 'components/Table';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagDetail} from 'typescript/models/TagDetails';

import {TableHeader as TagsHeader} from '../TableHeader';
import {TagList} from '../TagList';
import ZeroState from '../ZeroState/ZeroState';

interface IProps {
  tagsList: TagDetail[];
  filters: GetTagDetailsDTO;
}

export default function DesktopTable({tagsList, filters}: IProps) {
  const hasTagsList = tagsList.length > 0;

  return (
    <Table className="tag-table tag-table--desktop">
      <TagsHeader filters={filters} listOfTags={tagsList} />
      <TableBody className="tag-table__body">
        {hasTagsList ? (
          tagsList.map((tag) => {
            return <TagList tag={tag} filters={filters} key={tag.id} />;
          })
        ) : (
          <ZeroState type="table" />
        )}
      </TableBody>
    </Table>
  );
}
