import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {markAllNotificationsAsReadACS} from 'store/notification/markAllAsReadProject/actionCreator';
import {setPreviewProjectAC} from 'store/project/Calendar/SetPreviewProject/actionCreator';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';

import ProjectPreviewMembers from '../ProjectPreviewMembers/ProjectPreviewMembers';
import {formatDate} from './Helpers/formatDate';
import {ListOfTags} from './ListOfTags';

interface IProps {
  singleProject: SingleProject;
}

export const SingleProjectPreview = ({singleProject}: IProps) => {
  const dispatch = useSetGlobalState();

  const {projectFilters, singleDayProjectList} = useGlobalState((state) => state.project);
  const {user} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();

  const {notifications} = useGlobalState((state) => state.notifications);
  const matchingNotifications = notifications.filter((notification) => notification.project.id === singleProject.id);

  const handleViewMore = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(singleProject));
  };

  const handleReadAllNotifications = (e: React.MouseEvent) => {
    const changedProjectList = cloneDeep(singleDayProjectList).listOfProjects.map((project) => {
      if (project.id === singleProject.id) project.unreadNotifications = false;
      return project;
    });
    e.stopPropagation();
    if (!matchingNotifications.length) return;
    if (user) {
      const payload = {dto: {projectId: singleProject.id, userId: user.id}, filters: projectFilters};
      dispatch(markAllNotificationsAsReadACS(payload));

      const payloadList = {date: singleDayProjectList.date, listOfProjects: changedProjectList};
      dispatch(setPreviewProjectAC(payloadList));
    }
  };

  const handleToggleFavorite = () => {
    const payload = {id: singleProject.id, filters: {...projectFilters}};

    if (singleProject.favorite) dispatch(removeFavoriteProjectAC(payload));
    else dispatch(addFavoriteProjectAC(payload));

    const changedProjectList = cloneDeep(singleDayProjectList).listOfProjects.map((project) => {
      if (project.id === singleProject.id) {
        if (singleProject.favorite) project.favorite = false;
        else project.favorite = true;
      }
      return project;
    });

    const payloadList = {date: singleDayProjectList.date, listOfProjects: changedProjectList};
    dispatch(setPreviewProjectAC(payloadList));
  };

  return (
    <div className="project__preview">
      <div className="project__preview-header">
        <div className="project__preview-title">
          <div
            tabIndex={0}
            role="button"
            onClick={handleToggleFavorite}
            className={`event-info__star ${singleProject.favorite && 'event-info__star--starred'}`}
          >
            <i className="far fa-star" />
          </div>
          <h2>{singleProject.title}</h2>
        </div>
        <div className="d-flex">
          <div className="event-info">
            <div className="event-info__comment">
              <i className="fal fa-paperclip" />
              <span>{singleProject.documents.length}</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={handleReadAllNotifications}
              className={`event-info__notification ${
                singleProject.unreadNotifications ? 'event-info__notification--active' : ''
              }`}
            >
              <i className="fal fa-bell" />
            </div>
          </div>
          <div className="d-none d-sm-block ms-3">
            <button className="btn btn--primary btn--small" onClick={handleViewMore}>
              {t('See more')}
            </button>
          </div>
        </div>
      </div>
      <div className="project__preview-content">
        <div className="row">
          <div className="col-sm-8">
            <div className="project__preview-text">
              <h5>{t('organizer')}</h5>
              <ProjectPreviewMembers members={singleProject.organizers} />
              <h5>{t('member')}</h5>
              {singleProject.members.length > 0 ? (
                <ProjectPreviewMembers members={singleProject.members} />
              ) : (
                <p className="first-letter-capital">{t('this project has no members')}.</p>
              )}
            </div>
          </div>
          <div className="col-sm-4">
            <h5>{t('EVENT-DATE')}</h5>
            <p>{formatDate(singleProject.date)}</p>
            <h5>{t('tags')}</h5>
            <div className="position-relative">
              <ListOfTags key={singleProject.id} singleProject={singleProject} />
            </div>
            <div className="d-sm-none mt-3">
              <button className="btn btn--primary btn--small" onClick={handleViewMore}>
                {t('See more')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
