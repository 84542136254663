import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {useHistory} from 'react-router';
import {getDeletedProjectsACS} from 'store/deletedProjects/getDeletedProjects/actionCreator';
import {isAdmin} from 'utils/getUserRole';

import {DesktopTable} from './Desktop/DesktopTable';
import {MobileAndTabletTable} from './MobileAndTabletView/MobileAndTabletTable';

export default function DeletedProjects() {
  const dispatch = useSetGlobalState();
  const isTabletAndUp = useMediaBreakpoint('up', 'lg');
  const {projectFilters, projects} = useGlobalState((state) => state.deletedProjects);

  const {user} = useGlobalState((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (user && !isAdmin(user)) history.push('*');
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    dispatch(getDeletedProjectsACS(projectFilters));

    // eslint-disable-next-line
  }, [projectFilters]);

  return (
    <AppLayout pageName="deleted-projects">
      <div>{isTabletAndUp ? <DesktopTable projects={projects} /> : <MobileAndTabletTable />}</div>
    </AppLayout>
  );
}
