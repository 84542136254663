import {Member} from 'typescript/models/Member';

interface IProps {
  user: Member;
  organizerId: number | null;
  handleClick: Function;
}

export default function AddOrganizerListItem({user, organizerId, handleClick}: IProps) {
  const {id, name, email} = user;

  return (
    <li
      key={id}
      onClick={() => handleClick(user)}
      className={`add-organizer__list-item ${id === organizerId && 'add-organizer__list-item--selected'}`}
    >
      <div>
        <h4>{name}</h4>
        <span>{email}</span>
      </div>
      <i className="fal fa-check" />
    </li>
  );
}
