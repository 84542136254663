import {useCallback, useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {updateInstitutionLogoAC, updateInstitutionNameAC} from 'store/institution/update/actionCreator';
import './InstitutionEditor.scss';
import {ChangeInstitutionLogoDTO} from 'typescript/dto/InstitutionDTO';

interface IProps {
  onClose: () => void;
}

export default function InstitutionEditor({onClose}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {institution, logoUrl} = useGlobalState((state) => state.institution);

  const [file, setFIle] = useState<File | null>(null);
  const [previousFile, setPreviousFile] = useState<File | null>(null);
  const [isSaved, setIsSaved] = useState(false);
  const [name, setName] = useState(institution?.name || '');

  const handleClose = () => {
    if (!isSaved && previousFile) {
      dispatch(
        updateInstitutionLogoAC({
          name: previousFile.name,
          source: 'COMPUTER',
          type: 'FILE',
          file: previousFile,
        }),
      );
    }
    onClose();
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    const uploadedFile = acceptedFiles[0];
    const isImageUploaded = uploadedFile.type === 'image/png' || uploadedFile.type === 'image/jpeg';

    if (isImageUploaded) {
      setPreviousFile(file);
      setFIle(uploadedFile);
      const payload: ChangeInstitutionLogoDTO = {
        name: uploadedFile.name,
        source: 'COMPUTER',
        type: 'FILE',
        file: uploadedFile,
      };

      dispatch(updateInstitutionLogoAC(payload));
    } else if (acceptedFiles.length === 0) {
      dispatch(setGlobalNotification({variant: 'error', message: 'logo-size-error'}));
    } else {
      dispatch(setGlobalNotification({variant: 'error', message: 'logo-type-error'}));
    }

    // eslint-disable-next-line
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop, maxSize: 20000000});

  const shouldDisableButton = () => {
    if (name === institution?.name && !file) return true;

    return false;
  };

  const handleSave = () => {
    if (name !== institution?.name) dispatch(updateInstitutionNameAC(name));

    if (file) {
      const payload: ChangeInstitutionLogoDTO = {
        name: file.name,
        source: 'COMPUTER',
        type: 'FILE',
        file,
      };

      dispatch(updateInstitutionLogoAC(payload));
      setIsSaved(true);
    }
    handleClose();
  };

  return (
    <div className="institution-editor">
      <div className="institution-editor__header">
        <h3>{t('logo and institution name')}</h3>
        <div className="institution-editor__header-close" role="button" onClick={handleClose}>
          <i className="fal fa-times" />
        </div>
      </div>
      <div className="institution-editor__name">
        <input
          className="add-project__title"
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder={t('Enter institution name')}
        />
      </div>
      <div className="institution-editor__upload" {...getRootProps()}>
        <div className="institution-editor__upload-icon">
          {logoUrl ? (
            <img src={logoUrl} alt="a" />
          ) : (
            <>
              <span>300x300</span>
              <i className="fas fa-image" />
            </>
          )}
        </div>
        <div className="institution-editor__upload-message">
          {!file?.name && (
            <>
              <h3 className="first-letter-capital">
                {t('drag and drop')} {t('or')}
              </h3>
              <span className="first-letter-capital">{t('choose file')}</span>
            </>
          )}
          {file?.name && <h3>{file?.name}</h3>}
        </div>
        <div className="institution-editor__upload-info">
          <h3 className="first-letter-capital">
            *{t('recommended format is 300x300px')}. {t('Maximum file size is 20MB')}.
          </h3>
        </div>
        <input {...getInputProps()} />
      </div>
      <div className="institution-editor__actions">
        <button className="institution-editor__submit" disabled={shouldDisableButton()} onClick={handleSave}>
          <span className="first-letter-capital">{t('save')}</span>
        </button>
        <button className="institution-editor__close" onClick={handleClose}>
          <i className="fal fa-times" />
        </button>
      </div>
    </div>
  );
}
