import moment from 'moment';

export const styleDays = (selectedDate: moment.Moment, day: moment.Moment) => {
  const firstDayOfMonth = selectedDate.clone().startOf('month');
  const lastDayOfMonth = selectedDate.clone().endOf('month');
  let className = '';
  if (day.isBefore(firstDayOfMonth)) className = 'calendar__day--outside-month';
  if (day.isAfter(lastDayOfMonth)) className = 'calendar__day--outside-month';

  return className;
};
