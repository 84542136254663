import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {archiveProjectAC} from 'store/project/archiveProject/actionCreator';
import {setProjectsAC} from 'store/project/setProjects/setProjects';
import {setSelectedProjectAC} from 'store/project/setSelectedProject/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmArchive() {
  const {selectedProject, projects} = useGlobalState((state) => state.project);

  const dispatch = useSetGlobalState();

  const {t} = useTranslation();

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
    dispatch(setSelectedProjectAC(null));
  };

  const handleArchive = () => {
    if (selectedProject) {
      dispatch(archiveProjectAC(selectedProject.id));

      const updatedProjectsList = projects.filter((project) => project.id !== selectedProject.id);

      dispatch(setProjectsAC(updatedProjectsList));
      dispatch(
        setGlobalNotification({
          variant: 'success',
          message: 'Project successfully archived',
          icon: 'fa-credit-card-blank',
        }),
      );
      handleClose();
    }
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__wrap">
        <div className="confirm-dialog--danger">
          <i className="far fa-exclamation-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>{t('archive-confirmation')}</h6>
          <div className="btn__wrap">
            <button className="btn btn--primary btn--large" onClick={handleArchive}>
              {t('archive')}
            </button>
            <button className="btn btn--secondary btn--large" onClick={handleClose}>
              {t('quit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
