import {useEffect} from 'react';

import ContextMenu from 'components/Sidebar/ContextMenu';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {firstLetterCapital} from 'utils/helpers/firstLetterCapital';
import isMobileView from 'utils/helpers/isMobileView';

export const TagDropdown = () => {
  const dispatch = useSetGlobalState();

  const {checkedTags, tagDetails} = useGlobalState((state) => state.tag);
  let offset: [number, number] = [0, 10];
  if (isMobileView()) offset = [-2, 10];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset,
  });
  const isCheckedLengthZero = checkedTags.length === 0;
  const shouldDisableClick = isOpen && isCheckedLengthZero;

  const {t} = useTranslation();
  const matchingTagsNumOfProjects = checkedTags
    .map((tag) => {
      return tagDetails.find((x) => x.id === tag);
    })
    .map((x) => x?.numberOfProject);

  const handleDeleteAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const tagHaveNumOfProjectsLargerThan0 = matchingTagsNumOfProjects.map((x) => x! > 0).includes(true);

    if (!checkedTags.length) return;
    if (!tagHaveNumOfProjectsLargerThan0) {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_DELETE_TAG}));
    } else dispatch(toggleModalAC({isOpen: true, component: ModalComponents.DELETE_TAGS}));
  };

  const handleBulkTogglerOpen = () => {
    if (checkedTags.length > 0) toggleOpen();
  };

  useEffect(() => {
    if (shouldDisableClick) toggleOpen();
    // eslint-disable-next-line
  }, [checkedTags]);

  return (
    <div ref={containerRef}>
      <button
        ref={setReferenceElement}
        className={`header__action header__action--more ${isCheckedLengthZero ? 'pe-none' : ''}`}
        onClick={handleBulkTogglerOpen}
      >
        <i className="far fa-ellipsis-v" />
      </button>
      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ContextMenu>
            <ul className="sidebar__dropdown">
              <li>
                <a href="/" onClick={handleDeleteAll}>
                  <i className="far fa-trash-alt" />
                  {firstLetterCapital(t('delete'))}
                </a>
              </li>
            </ul>
          </ContextMenu>
        </div>
      )}
    </div>
  );
};
