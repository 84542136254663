import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {createUser} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setAddUserDataAC} from 'store/user/setAddUserData/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmRegister() {
  const dispatch = useSetGlobalState();
  const {newUser} = useGlobalState((state) => state.users);
  const {t} = useTranslation();

  const handleCreateUsers = async () => {
    const {roles, email, name, unitName, positionName} = newUser;
    await createUser({roles, email, name, unitName, positionName})
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'User invited successfully', icon: 'fa-user'}));
        newUser.helper!.resetForm();
        dispatch(getUsersACS({}));
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'User invited unsuccessfully'}));
      });

    dispatch(toggleModalAC({isOpen: false, component: null}));
    const defaultAddUserPayload = {roles: ['USER'], email: '', name: '', unitName: '', positionName: ''};
    dispatch(setAddUserDataAC(defaultAddUserPayload));
  };

  const handleCloseModal = () => {
    dispatch(setAddUserDataAC(newUser));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.ADD_USER}));
  };

  const handleCloseModalOnButton = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
    const defaultAddUserPayload = {roles: ['USER'], email: '', name: '', unitName: '', positionName: ''};
    dispatch(setAddUserDataAC(defaultAddUserPayload));
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton onClose={handleCloseModalOnButton} />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--warning">
            <i className="far fa-question-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>{t('confirm-register-invitation')}</h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={handleCreateUsers}>
                {t('send')}
              </button>
              <button className="btn btn--secondary btn--large" onClick={handleCloseModal}>
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
