import produce, {castDraft, Draft} from 'immer';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {IArchiveActions} from 'typescript/interfaces/IArchiveActions';
import {PaginateData} from 'typescript/models/Paginations';
import {SingleProject} from 'typescript/models/SingleProject';

import {ArchiveActions} from './actions';

interface IState {
  archivedProjects: SingleProject[];
  loading: boolean;
  pagination: PaginateData;
  filters: IGetProjectsDTO;
  checkedProjects: number[];
  checkedTags: number[];
}

const initialState: IState = {
  archivedProjects: [],
  loading: false,
  pagination: {last: false, first: false},
  filters: {page: 0, size: 15, active: false, order: 'DESC', sort: 'updatedOn'},
  checkedProjects: [],
  checkedTags: [],
};

const archiveReducer = (state = initialState, action: IArchiveActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case ArchiveActions.SET_ARCHIVED_PROJECTS:
        draft.archivedProjects = castDraft(action.payload);
        break;
      case ArchiveActions.SET_ARCHIVE_LOADING:
        draft.loading = castDraft(action.payload);
        break;
      case ArchiveActions.SET_ARCHIVE_PAGINATION:
        draft.pagination = castDraft(action.payload);
        break;
      case ArchiveActions.SET_ARCHIVE_FILTERS:
        draft.filters = castDraft(action.payload);
        break;
      case ArchiveActions.SET_CHECKED_ARCHIVE_PROJECTS:
        draft.checkedProjects = castDraft(action.payload);
        break;
      case ArchiveActions.SET_CHECKED_ARCHIVE_TAGS:
        draft.checkedTags = castDraft(action.payload);
        break;
    }
  });

export default archiveReducer;
