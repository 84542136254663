import {NotificationContent} from 'typescript/models/Notifications';

import {ListOfNotifications} from './ListOfNotifications';
import {NotificationStatus} from './NotificationStatus';

interface IProps {
  notificationList: () => NotificationContent[];
  handleRead: (id: number, status: boolean) => void;
}

export const NotificationsDropdown = ({notificationList, handleRead}: IProps) => {
  return (
    <ul>
      {notificationList().map((notification) => {
        return (
          <li key={notification.id}>
            <NotificationStatus handleRead={handleRead} notification={notification} />
            <ListOfNotifications notification={notification} handleRead={handleRead} />
          </li>
        );
      })}
    </ul>
  );
};
