import {Tag as TagComponent} from 'components/Tag/Tag';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {deleteTagsBulk} from 'services/tagService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export default function DeleteTags() {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {checkedTags, tagDetails, tagFilters} = useGlobalState((state) => state.tag);

  const checkedTagsList = checkedTags.flatMap((checkedTag) => {
    return tagDetails.filter((tagDetail) => tagDetail.id === checkedTag);
  });

  const undeleteableTags = checkedTagsList.filter((tag) => tag.numberOfProject > 0);
  const deleteableTags = checkedTagsList.filter((tag) => tag.numberOfProject === 0);
  const deleteableTagsLength = deleteableTags.length;
  const undeleteableTagsLength = undeleteableTags.length;

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleDelete = async () => {
    if (!checkedTags.length) return;

    const ids = deleteableTags.map((tag) => tag.id);

    await deleteTagsBulk(ids)
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'Tags successfully deleted', icon: 'fa-tags'}));
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'Tags deletion failure', icon: 'fa-tags'}));
      })
      .finally(() => {
        batch(() => {
          dispatch(getTagDetailsACS(tagFilters));
          dispatch(setCheckedTagsAC([]));
        });
        handleClose();
      });
  };

  return (
    <div className="confirm-dialog confirm-dialog--larger">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        {undeleteableTagsLength > 0 && (
          <div className="confirm-dialog__wrap">
            <div className="confirm-dialog--danger">
              <i className="far fa-exclamation-circle" />
            </div>
            <div className="confirm-dialog__content">
              {deleteableTagsLength > 0 && (
                <h6 className="first-letter-capital">
                  {t("some of this tags are used in projects and can't be deleted")}:
                </h6>
              )}
              {deleteableTagsLength === 0 && (
                <h6 className="first-letter-capital">{t("these tags are used in projects and can't be deleted")}:</h6>
              )}
              <div className="mt-20">
                {undeleteableTags.map((tag) => (
                  <TagComponent variant={tag.color} key={tag.id}>
                    {tag.name}
                  </TagComponent>
                ))}
              </div>
            </div>
          </div>
        )}
        {deleteableTagsLength > 0 && (
          <>
            {undeleteableTagsLength > 0 && <div className="confirm-dialog__divider" />}
            <div className="confirm-dialog__wrap">
              <div className="confirm-dialog--warning">
                <i className="far fa-question-circle" />
              </div>
              <div className="confirm-dialog__content">
                <h6 className="first-letter-capital">
                  {t('are you sure you want to delete')}
                  {deleteableTags.length > 1 ? ` ${t('these')} ` : ` ${t('this')} `}
                  {deleteableTags.length > 1 ? `${t('tags')}?` : `${t('this tag')}?`}
                </h6>
                <div className="mt-20">
                  {deleteableTags.map((tag) => (
                    <TagComponent variant={tag.color} key={tag.id}>
                      {tag.name}
                    </TagComponent>
                  ))}
                </div>
                <div className="btn__wrap">
                  <button className="btn btn--primary btn--large" onClick={handleDelete}>
                    {t('delete')}
                  </button>
                  <button className="btn btn--secondary btn--large" onClick={handleClose}>
                    {t('quit')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
