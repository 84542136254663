import {Routes} from 'constants/routes';

import {useEffect, useRef, useState} from 'react';

import useDidMount from 'hooks/useDidMount';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useHistory, useLocation} from 'react-router';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {getProjectsSagaACS} from 'store/project/getProjects/actionCreator';
import newProjectAC from 'store/project/newProject/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {updateProjectACS} from 'store/project/updateProject/actionCreator';
import {isDeleted} from 'utils/helpers/isDeleted';
import isMobileView from 'utils/helpers/isMobileView';
import './Project.scss';
import './Popup.scss';
import isSmallScreen from 'utils/helpers/isSmallScreen';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import CloseModalButton from '../shared/CloseModalButton';
import Actions from './Actions/Actions';
import {ActivityLogs} from './ActivityLogs/ActivityLogs';
import Comments from './Comments/Comments';
import Description from './Description/Description';
import Documents from './Documents/Documents';
import Header from './Header/Header';
import Members from './Members/Members';
import Organizators from './Organizators/Organizators';
import ProjectDate from './ProjectDate/ProjectDate';
import Tags from './Tags/Tags';

export default function Project() {
  const dispatch = useSetGlobalState();
  const {pathname} = useLocation();
  const history = useHistory();

  const {isNewProject, singleProject, projectFilters} = useGlobalState((state) => state.project);
  const {component} = useGlobalState((state) => state.modal);

  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const {user} = useGlobalState((state) => state.auth);
  const didMount = useDidMount();

  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const isArchived = singleProject?.archived;

  const shouldNotDisableStarButton = showSingleProjectButton(singleProject, user);

  useEffect(() => {
    return () => {
      setErrorMessage(false);
      if (singleProject && didMount) dispatch(updateProjectACS(singleProject));
    };
    // eslint-disable-next-line
  }, [singleProject]);

  useEffect(() => {
    return () => {
      const shouldDisplaySuccessNotification = isNewProject && component !== 'PROJECT';
      if (shouldDisplaySuccessNotification) {
        dispatch(
          setGlobalNotification({
            variant: 'success',
            message: 'Project successfully created',
            icon: 'fa-credit-card-blank',
          }),
        );
      }

      dispatch(setSingleProjectAC(null));
      dispatch(newProjectAC(false));
      dispatch(getProjectsSagaACS(projectFilters));

      if (pathname.includes(`${Routes.DASHBOARD}/`)) history.push(Routes.DASHBOARD);
    };
    // eslint-disable-next-line
  }, []);

  const handleFavoriteClick = () => {
    if (singleProject?.id) {
      const payload = {id: singleProject.id, filters: {...projectFilters}};
      if (singleProject.favorite) dispatch(removeFavoriteProjectAC(payload));
      else dispatch(addFavoriteProjectAC(payload));
    }
  };

  return (
    <div className="project">
      <div className="project__dialog" role="document">
        <div className="project__content">
          <div className="project__close-modal">
            <CloseModalButton />
          </div>
          <div className="container-fluid">
            {isMobileView() && (
              <div className="row">
                <div className="col">
                  {singleProject && <Header singleProject={singleProject} titleRef={titleRef} />}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col">
                {isArchived && isSmallScreen() && <h1 className="project-archived__title text-right">arhivirano</h1>}
                {isDeleted(singleProject) && isSmallScreen() && (
                  <h1 className="project-archived__title text-right">obrisano</h1>
                )}
                <aside className="project__sidebar project__sidebar--mobile">
                  <div className="d-flex align-items-center">
                    <div
                      className={`d-md-none opacity-100  ${
                        !shouldNotDisableStarButton ? 'project__item-disabled' : ''
                      }`}
                      role="button"
                      onClick={handleFavoriteClick}
                    >
                      <i
                        className={`opacity-100 far fa-star project__star ${
                          !shouldNotDisableStarButton ? 'project__item-disabled' : ''
                        } ${singleProject?.favorite ? 'project__star--starred' : ''}`}
                      />
                    </div>
                    {singleProject && <Actions singleProject={singleProject} setErrorMessage={setErrorMessage} />}
                  </div>
                  <div className="d-flex align-items-center">
                    {singleProject && <Documents singleProject={singleProject} />}
                  </div>
                </aside>
              </div>
            </div>
            {!isMobileView() && (
              <div className="row">
                <div className="col">
                  {singleProject && <Header singleProject={singleProject} titleRef={titleRef} />}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-8">
                <section className="project__main">
                  <div className="project__inner">
                    {singleProject && (
                      <>
                        <ProjectDate singleProject={singleProject} />
                        <Organizators singleProject={singleProject} />
                        <Members singleProject={singleProject} />
                        <Tags singleProject={singleProject} />
                      </>
                    )}
                  </div>
                  {singleProject && (
                    <>
                      <Description
                        descriptionRef={descriptionRef}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        singleProject={singleProject}
                      />
                      <Comments singleProject={singleProject} />
                    </>
                  )}
                  {!isNewProject && <ActivityLogs singleProject={singleProject} />}
                </section>
              </div>
              <div className="col-md-4 ">
                <aside className="project__sidebar d-none d-md-block">
                  {singleProject && <Actions singleProject={singleProject} setErrorMessage={setErrorMessage} />}
                  {singleProject && <Documents singleProject={singleProject} />}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
