import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {deleteTag} from 'services/tagService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';

import CloseModalButton from '../shared/CloseModalButton';

export const DeleteSingleTag = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {deleteTagId, tagDetails, tagFilters} = useGlobalState((state) => state.tag);
  const matchingTag = tagDetails.find((tag) => tag.id === deleteTagId);

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleDelete = async () => {
    if (deleteTagId) {
      await deleteTag(deleteTagId)
        .then(() => {
          dispatch(setGlobalNotification({variant: 'success', message: 'Tag successfully deleted', icon: 'fa-tags'}));
          dispatch(getTagDetailsACS(tagFilters));
        })
        .catch(() => {
          dispatch(setGlobalNotification({variant: 'error', message: 'Tags deletion failure'}));
        });
      handleClose();
    }
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__wrap">
        <div className="confirm-dialog--danger">
          <i className="far fa-exclamation-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6 className="first-letter-capital">
            {matchingTag && matchingTag.numberOfProject > 0 ? (
              <>
                {t('tag')} <b>{matchingTag?.name}</b> {t('tag is used on some projects')}
              </>
            ) : (
              <>
                {t('delete tag confirmation')} <b>{matchingTag?.name}</b>?
              </>
            )}
          </h6>
          {matchingTag && matchingTag.numberOfProject === 0 && (
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={handleDelete}>
                {t('delete')}
              </button>
              <button className="btn btn--secondary btn--large" onClick={handleClose}>
                {t('quit')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
