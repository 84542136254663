import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import {Member} from 'typescript/models/Member';

interface IProps {
  membersList: Member[] | [];
}

export const MembersList = ({membersList}: IProps) => {
  return (
    <div>
      {membersList.length > 0 ? (
        membersList.slice(0, 1).map((member) => {
          return (
            <div key={member.id} className="d-flex align-items-baseline">
              <p>{member.name}</p>
              {membersList.length > 1 ? <TruncateBadge num={membersList.length - 1} /> : null}
            </div>
          );
        })
      ) : (
        <p>/</p>
      )}
    </div>
  );
};
