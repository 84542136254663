import './MobileSort.scss';

interface IProps {
  filters: {name: string; value: string}[];
  handleSort: (value: string) => void;
}

export default function MobileSort({filters, handleSort}: IProps) {
  return (
    <div className="mobile-sort">
      {filters.map(({name, value}) => (
        <button key={name} onClick={() => handleSort(value)}>
          <span className="first-letter-capital">{name}</span>
        </button>
      ))}
    </div>
  );
}
