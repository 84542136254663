import moment from 'moment';
import {SingleProject} from 'typescript/models/SingleProject';

export const displayRemainder = (singleProject: SingleProject | null) => {
  let nearestDate: string = '';

  if (singleProject) {
    const currentDate = moment(singleProject.date).format('YYYY-MM-DD').concat(' 00:00:00');
    const datesToBeChecked = singleProject.remainders.map((x) => [x.startDate, x.time].join(' '));

    datesToBeChecked.forEach((date) => {
      const diff = moment(date).diff(moment(currentDate), 'minutes');
      if (diff < 0) {
        if (nearestDate) {
          if (moment(date).diff(moment(nearestDate), 'minutes') > 0) nearestDate = date;
        } else nearestDate = date;
      } else if (diff > 0) {
        if (nearestDate) {
          if (moment(date).diff(moment(nearestDate), 'minutes') < 0) nearestDate = date;
        }
      }
    });
  }

  return nearestDate ? moment(nearestDate).format('DD/MM/YYYY') : '';
};
