export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export function mediaBreakpointUp(breakpoint: Breakpoints) {
  const query = window.matchMedia(breakpoint === 'xs' ? '' : `(min-width:${breakpoints[breakpoint]}px`);

  return query;
}

export function mediaBreakpointDown(breakpoint: Breakpoints) {
  const query = window.matchMedia(breakpoint === 'xs' ? '' : `(max-width:${breakpoints[breakpoint] - 0.02}px`);

  return query;
}

export function mediaBreakpointBetween(min: Breakpoints, max: Breakpoints) {
  const query = window.matchMedia(`(min-width:${breakpoints[min] - 0.02}px) and (max-width:${breakpoints[max]}px)`);

  return query;
}
