import produce, {castDraft, Draft} from 'immer';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagReducerActions} from 'typescript/interfaces/ITagActions';
import {PaginateData} from 'typescript/models/Paginations';
import {Tag} from 'typescript/models/Tag';
import {TagDetail} from 'typescript/models/TagDetails';

import {TagActions} from './actions';

export interface IState {
  tags: Tag[];
  tagDetails: TagDetail[];
  tagFilters: GetTagDetailsDTO;
  pagination: PaginateData;
  checkedTags: number[];
  deleteTagId: number | null;
}

export const initialState: IState = {
  tags: [],
  tagDetails: [],
  tagFilters: {page: 0, size: 15, order: 'DESC', sort: 'updatedOn'},
  pagination: {last: false, first: false},
  checkedTags: [],
  deleteTagId: null,
};

const tagReducer = (state = initialState, action: TagReducerActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case TagActions.SET_TAGS:
        draft.tags = castDraft(action.payload);
        break;
      case TagActions.SET_TAG_DETAILS:
        draft.tagDetails = castDraft(action.payload);
        break;
      case TagActions.SET_TAG_FILTERS:
        draft.tagFilters = castDraft(action.payload);
        break;
      case TagActions.SET_CHECKED_TAGS:
        draft.checkedTags = castDraft(action.payload);
        break;
      case TagActions.SET_TAGS_PAGINATION:
        draft.pagination = castDraft(action.payload);
        break;
      case TagActions.SET_DELETE_TAG_ID:
        draft.deleteTagId = castDraft(action.payload);
        break;
    }
  });

export default tagReducer;
