import {sr, srLatn} from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import './Datepicker.scss';

export type DatePickerType = Date | [Date | null, Date | null] | null;

interface IProps {
  onStartDateChange: (date: DatePickerType) => void;
  onEndDateChange: (date: DatePickerType) => void;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  onReset: () => void;
}

export default function Datepicker({onStartDateChange, onEndDateChange, onReset, startDate, endDate}: IProps) {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const isLanguageLatin = language === 'latin' ? srLatn : sr;

  return (
    <div>
      <div className="datepicker">
        <div className="datepicker__inner">
          <div className={`datepicker__arrow ${startDate ? 'datepicker__wrap' : ''}`}>
            <DatePicker
              className="start-date"
              dateFormat="dd/MM/yyyy"
              placeholderText={`${t('date from')}`}
              selected={startDate}
              onChange={onStartDateChange}
              locale={isLanguageLatin}
            />
          </div>

          <div className={`${endDate ? 'datepicker__wrap' : ''}`}>
            <DatePicker
              className="end-date"
              dateFormat="dd/MM/yyyy"
              placeholderText={`${t('date to')}`}
              selected={endDate}
              onChange={onEndDateChange}
              locale={isLanguageLatin}
            />
          </div>
        </div>
      </div>
      <div className="datepicker__actions">
        <button
          className={`datepicker__reset first-letter-capital ${
            startDate || endDate ? 'datepicker__reset--active' : ''
          }`}
          onClick={onReset}
        >
          <span>{t('delete filter')}</span>
        </button>
      </div>
    </div>
  );
}
