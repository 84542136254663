import * as Yup from 'yup';

const apiSetupRegistrationSchema = Yup.object().shape({
  name: Yup.string().required('Ime je obavezno polje'),
  password: Yup.string().required('Lozinka je obavezna'),
  email: Yup.string().email('Nevažeća e-mail adresa').required('Ovo polje je obavezno'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Lozinke se moraju podudarati')
    .required('Ovo polje je obavezno'),
});

export default apiSetupRegistrationSchema;
