import React from 'react';

import Icons from 'components/Icons/Icons';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import {useTranslation} from 'react-i18next';

interface IProps {
  type: 'regular' | 'table';
}

export default function ZeroState({type}: IProps) {
  const {t} = useTranslation();

  return (
    <TableZeroState type={type}>
      <Icons icon="zeroState" />
      <h3 className="first-letter-capital">{t('currently there are no added tags')}</h3>
      <p className="first-letter-capital">
        {t('if you want to add a tag, click on')} <br />
        <strong>
          <u>{t('tag creation icon')}.</u>
        </strong>
      </p>
    </TableZeroState>
  );
}
