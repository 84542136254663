import {ProjectViewType} from 'typescript/types/projectViewType';

import {AppActions} from '../actions';

export function setProjectViewAC(payload: ProjectViewType) {
  return {
    type: AppActions.SET_PROJECT_VIEW,
    payload,
  };
}
