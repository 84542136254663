import Checkbox from 'components/Checkbox/Checkbox';
import MobileSort from 'components/MobileSort/MobileSort';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setCheckedMembers} from 'store/user/setCheckedMembersList/actionCreator';
import {setUserFitlersAC} from 'store/user/setUserFilters/actionCreator';

import {AddUsersButton} from '../DesktopScreen/AddUsers';
import {UserDropdown} from '../DesktopScreen/UserDropdown';

import './Header.scss';

export const Header = () => {
  const dispatch = useSetGlobalState();
  const {users, checkedMembers, userFilters} = useGlobalState((state) => state.users);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [19, 5],
  });

  const handleCheckAll = () => {
    const listOfUserIds = users?.filter((user) => user.status === 'CALL_EXPIRED').map((user) => user.id);

    if (listOfUserIds) {
      let checkedMembersPayload: (number | undefined)[] = [];
      if (!checkedMembers.length) checkedMembersPayload = [...listOfUserIds];
      dispatch(setCheckedMembers(checkedMembersPayload));
    }
  };

  // eslint-disable-next-line consistent-return
  const handleSort = (value: string) => {
    const filtersCopy = {...userFilters};

    if (value === 'status') {
      if (filtersCopy.active === 'ALL') {
        filtersCopy.active = 'ACTIVE';
        dispatch(setUserFitlersAC(filtersCopy));
        return;
      }

      if (filtersCopy.active === 'ACTIVE') {
        filtersCopy.active = 'INACTIVE';
        dispatch(setUserFitlersAC(filtersCopy));
        return;
      }

      if (filtersCopy.active === 'INACTIVE') {
        filtersCopy.active = 'ALL';
        dispatch(setUserFitlersAC(filtersCopy));
        return;
      }
    }

    if (value === filtersCopy.sort) {
      filtersCopy.order = filtersCopy.order === 'DESC' ? 'ASC' : 'DESC';
    }

    filtersCopy.sort = value;

    dispatch(setUserFitlersAC(filtersCopy));
  };

  const handleSortClick = () => toggleOpen();

  const usersFilters = [
    {name: 'Ime i prezime', value: 'name'},
    {name: 'Status', value: 'status'},
  ];

  return (
    <div className="grid__header">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="grid__nav">
              <div className="grid__inner">
                <div className="check-main mobile-users-header__checkbox">
                  <div className="checkbox">
                    <Checkbox
                      id="checkMainMobile"
                      checked={Boolean(checkedMembers.length)}
                      handleClick={handleCheckAll}
                    />
                  </div>
                </div>
                <UserDropdown />
              </div>
              <div className="grid__inner">
                <div className="header__inner" ref={containerRef}>
                  <div className="header__action" role="button" ref={setReferenceElement} onClick={handleSortClick}>
                    <i className="far fa-sort-alt" />
                  </div>
                  {isOpen && (
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                      <MobileSort filters={usersFilters} handleSort={handleSort} />
                    </div>
                  )}
                </div>
                <AddUsersButton />
                <RoutesDropdown>
                  <i className="far fa-users" />
                </RoutesDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
