import {useEffect, useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {addRemaindersACS} from 'store/project/Remainders/addRemainder/actionCreator';
import {setRemainderTypeAC} from 'store/project/setRemainderType/actionCreator';
import {IDropdownAttributes, IDropdownStyles} from 'typescript/models/Dropdown';
import {TimeStamp} from 'typescript/models/Remainder';
import {SingleProject} from 'typescript/models/SingleProject';

import {SaveAndCloseButton} from './AddCloseButton';
import {AddRemainder} from './AddRemainder/AddRemainder';
import {formatChannel} from './Helpers/formatChannel';
import {stringToMatchingTimeValue} from './Helpers/stringToMatchingValue';
import {Remainders} from './Remainders/Remainders';
import {TypeOfNotifications} from './TypeOfNotifications/TypeOfNotifications';

interface IProps {
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  styles: IDropdownStyles;
  attributes: IDropdownAttributes;
  isOpen: boolean;
  singleProject: SingleProject;
  handleToggle: () => void;
  handleClose: () => void;
}

export const Remainder = (props: IProps) => {
  const dispatch = useSetGlobalState();
  const {handleToggle, singleProject, isOpen, setPopperElement, styles, attributes, handleClose} = props;
  const {t} = useTranslation();

  const {remainderTypes} = useGlobalState((state) => state.project);

  const [addRemainder, setAddRemainder] = useState<boolean>(false);
  const [numOfDays, setNumOfDays] = useState<number | undefined>();
  const [periodOfTime, setPeriodOfTime] = useState<TimeStamp>('dana');
  const [timeStamp, setTimeStamp] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const addRemainderProps = {setNumOfDays, setPeriodOfTime, setTimeStamp, timeStamp, setAddRemainder};
  const projectId = singleProject.id;

  const handleCloseRemainder = () => {
    setAddRemainder(false);
    handleClose();
  };

  useEffect(() => {
    dispatch(setRemainderTypeAC(['e-protocol']));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isOpen) dispatch(setRemainderTypeAC(['e-protocol']));
    // eslint-disable-next-line
  }, [isOpen]);

  const handleAddRemainders = () => {
    if (!remainderTypes.length) {
      setErrorMessage(true);
      return;
    }
    setAddRemainder(!addRemainder);

    const dayOrMonths = stringToMatchingTimeValue(periodOfTime);
    const period: 'ONCE' = 'ONCE';
    const channels = formatChannel(remainderTypes);
    const time = moment(timeStamp).format('HH:mm:ss');
    const endDate = moment(singleProject?.date).clone().format('YYYY-MM-DD');
    const startDate = moment(moment(singleProject?.date).clone().format('YYYY-MM-DD'))
      .subtract(numOfDays, dayOrMonths)
      .format('YYYY-MM-DD');

    const payload = {endDate, startDate, period, channels, projectId, time};
    dispatch(addRemaindersACS(payload));
  };

  const doesRemainderExist = timeStamp && numOfDays;

  return (
    <div ref={setPopperElement} className="position-relative">
      {isOpen && (
        <div id="popup_document" className="popup" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <header className="popup__header popup__header--close">
            <h4>{t('REMAINDER')}</h4>
            <button className="btn-close" onClick={handleCloseRemainder}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body">
            <div>
              <p>{t('add-remainder-desc')}</p>
              <p className="remainder-date">
                <span>{t('EVENT-DATE')}:</span>
                {singleProject?.date && new Date(singleProject.date).toLocaleDateString('en-GB')}
              </p>
            </div>
            {addRemainder ? (
              <AddRemainder {...addRemainderProps} />
            ) : (
              <Remainders singleProject={singleProject} setAddRemainder={setAddRemainder} addRemainder={addRemainder} />
            )}
          </section>
          <div style={{opacity: doesRemainderExist ? 1 : 0.3}}>
            <TypeOfNotifications errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            <SaveAndCloseButton
              errorMessage={errorMessage}
              timeStamp={timeStamp}
              numOfDays={numOfDays}
              handleToggle={handleToggle}
              handleAddRemainder={handleAddRemainders}
            />
          </div>
        </div>
      )}
    </div>
  );
};
