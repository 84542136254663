import {Reaction} from 'typescript/models/Reaction';

import SingleReaction from '../SingleReaction/SingleReaction';

interface IProps {
  commentId: number;
  reactions: Reaction[];
}

const ReactionList = ({commentId, reactions}: IProps) => {
  const seenReactions: string[] = [];

  return (
    <>
      {reactions?.map((reaction) => {
        const {text, id} = reaction;

        if (seenReactions.includes(text)) return null;

        seenReactions.push(text);
        const reactionsList = reactions.filter((r) => r.text === text);

        return <SingleReaction emoji={reaction.text} key={id} reactionsList={reactionsList} commentId={commentId} />;
      })}
    </>
  );
};

export default ReactionList;
