import * as Yup from 'yup';

const passwordCreateValidationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Password confirm is required'),
});

export default passwordCreateValidationSchema;
