function ArrowUpDown({...props}: any) {
  return (
    <svg width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.438 10.5L4.75 12.344V1.78C4.75 1.344 4.406 1 4 1c-.438 0-.75.344-.75.781v10.563L1.531 10.5A.732.732 0 001 10.281a.849.849 0 00-.531.188c-.313.281-.313.75-.031 1.062l2.968 3.25a.755.755 0 001.094 0l2.969-3.25c.281-.312.281-.781-.032-1.062-.25-.281-.718-.25-1 .031zm7.093-6l-2.969-3.25a.755.755 0 00-1.093 0L6.438 4.5c-.282.313-.282.781.03 1.063A.849.849 0 007 5.75a.66.66 0 00.531-.25L9.25 3.687V14.25c0 .438.313.75.75.75a.74.74 0 00.75-.75V3.687l1.688 1.844a.774.774 0 001.062.032c.313-.282.313-.75.031-1.063z"
        fill="#fff"
      />
    </svg>
  );
}

export default ArrowUpDown;
