import React, {useState} from 'react';

import {TimeStamp} from 'typescript/models/Remainder';

interface IProps {
  onClick: (value: TimeStamp) => void;
}

export const SelectTimePeriod = ({onClick}: IProps) => {
  const timePeriods: TimeStamp[] = ['dana', 'nedelje', 'meseca'];

  const [toggleTime, setToggleTime] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState<TimeStamp>('dana');

  const remainingTimePeriods = timePeriods.filter((timePeriod) => timePeriod !== selectedPeriod);

  const handleSelectPeriod = (period: TimeStamp) => {
    onClick(period);
    setSelectedPeriod(period);
    setToggleTime(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setToggleTime(!toggleTime)} className="remainder-time-periods">
      <span>{selectedPeriod}</span>
      <i className="fa fa-sort-down" />
      {toggleTime && (
        <div className="periods">
          {remainingTimePeriods.map((period) => {
            return (
              <div
                key={period}
                role="button"
                tabIndex={0}
                onClick={() => handleSelectPeriod(period)}
                className="periods__single"
              >
                {period}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
