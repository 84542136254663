import LogoSmall from 'images/logo-small.png';
import Logo from 'images/logo.png';
import {AuthLayout} from 'layouts/AuthLayout/AuthLayout';
import {useTranslation} from 'react-i18next';

import {CreatePasswordForm} from './CreatePasswordForm';

export const ForgotPassword = () => {
  const {t} = useTranslation();
  return (
    <AuthLayout pageName="login">
      <img className="d-none d-md-block" width="157" src={Logo} alt="" />
      <img className="d-md-none" width="94" src={LogoSmall} alt="" />
      <h1>{t('create-password')}</h1>
      <CreatePasswordForm />
    </AuthLayout>
  );
};
