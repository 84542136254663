import * as Yup from 'yup';

const registerValidationSchema = Yup.object().shape({
  password: Yup.string().required('Ovo polje je obavezno'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Lozinke se moraju podudarati')
    .required('Ovo polje je obavezno'),
});

export default registerValidationSchema;
