import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {deleteTagsBulk} from 'services/tagService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';

import CloseModalButton from '../shared/CloseModalButton';

export const DeleteTag = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {checkedTags, tagDetails, tagFilters} = useGlobalState((state) => state.tag);
  const checkedTagsList = checkedTags.flatMap((checkedTag) => {
    return tagDetails.filter((tagDetail) => tagDetail.id === checkedTag);
  });

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleDelete = async () => {
    if (!checkedTags.length) return;

    await deleteTagsBulk(checkedTags)
      .then(() => {
        dispatch(setGlobalNotification({variant: 'success', message: 'Tags successfully deleted', icon: 'fa-tags'}));
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'Tags deletion failure', icon: 'fa-tags'}));
      })
      .finally(() => {
        batch(() => {
          dispatch(getTagDetailsACS(tagFilters));
          dispatch(setCheckedTagsAC([]));
        });
      });

    handleClose();
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__wrap">
        <div className="confirm-dialog--danger">
          <i className="far fa-exclamation-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>
            {checkedTagsList.length > 1 ? (
              <>
                {t('delete confirmations')} {checkedTagsList.length} {t('marked tags')}
              </>
            ) : (
              <>
                {t('delete tag confirmation')} <b>{checkedTagsList && checkedTagsList[0]?.name}</b>?
              </>
            )}
          </h6>
          <div className="btn__wrap">
            <button className="btn btn--primary btn--large" onClick={handleDelete}>
              {t('delete')}
            </button>
            <button className="btn btn--secondary btn--large" onClick={handleClose}>
              {t('quit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
