import React, {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import {TableCell, TableRow} from 'components/Table';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setAddUserDataAC} from 'store/user/setAddUserData/actionCreator';
import {setCheckedMembers} from 'store/user/setCheckedMembersList/actionCreator';
import {setConfirmUserIdAC} from 'store/user/setConfirmUserId/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {Member} from 'typescript/models/Member';

import {UserRowStatus} from './UserRowStatus';

interface IProps {
  user: Member;
}

export const UserRow = ({user}: IProps) => {
  const dispatch = useSetGlobalState();

  const {checkedMembers} = useGlobalState((state) => state.users);
  const {users} = useGlobalState((state) => state.users);
  const {t} = useTranslation();

  const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);

  const isOnWait = user.status === 'ON_WAIT';
  const lastUserInList = users && cloneDeep(users).pop();
  const isUserLastInTheList = lastUserInList?.id === user.id;

  const handleCheck = (value: number) => {
    const memberId = value;
    let payload;
    if (checkedMembers.includes(memberId)) {
      const filteredMemberList = checkedMembers.filter((member) => member !== memberId);
      payload = filteredMemberList;
    } else {
      payload = [...checkedMembers, memberId];
    }
    dispatch(setCheckedMembers(payload));
  };

  const handleSingleUser = () => {
    const userPayload = {
      id: user.id,
      roles: user.roles.length > 0 ? user.roles : ['USER'],
      email: user.email,
      name: user.name,
      unitName: user.unit,
      positionName: user.position,
    };

    dispatch(setConfirmUserIdAC(user.id));
    dispatch(setAddUserDataAC(userPayload));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.SINGLE_USER}));
  };

  const handleStatusCheckbox = () => {
    let payload = true;
    if (user.status === 'CALL_EXPIRED') payload = false;
    return payload;
  };

  return (
    <TableRow tabIndex={0} role="button" onClick={handleSingleUser} className="dashboard-table__user-row">
      <TableCell className="dashboard-table__user-check" onClick={(e) => e.stopPropagation()}>
        <div className="table__check-wrap">
          <Checkbox
            id={`${user.id}`}
            onChange={handleCheck}
            checked={checkedMembers.includes(user.id)}
            disabled={handleStatusCheckbox()}
            className="checkbox"
          />
        </div>
      </TableCell>
      <TableCell className="table__empty-row"> </TableCell>
      <TableCell className="dashboard-table__name-tag">
        <p>{user.name}</p>
        {user.roles.includes('ADMIN') && <span>ADMIN</span>}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.position}</TableCell>
      <TableCell>{user.unit}</TableCell>
      <TableCell className="dashboard-table__activity">
        <div role="button" tabIndex={0} className="dashboard-table__status">
          <div>
            {user.status !== 'CALL_EXPIRED' ? (
              <p
                className={`dashboard-table__status dashboard-table__status${isOnWait ? '--wait' : ''}`}
                style={{color: isOnWait ? '#0379bf' : '#717888'}}
              >
                {t(user.status)}
              </p>
            ) : (
              <UserRowStatus
                setDisplayTooltip={setDisplayTooltip}
                displayTooltip={displayTooltip}
                userStatus={user.status}
                isUserLastInTheList={isUserLastInTheList}
              />
            )}
          </div>
        </div>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
