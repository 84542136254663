import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {mediaBreakpointDown} from 'hooks/useMediaBreakpoint/media';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getActivityLogACS} from 'store/project/getActivityLog/actionCreators';
import {SingleProject} from 'typescript/models/SingleProject';
import cutName from 'utils/helpers/cutName';
import {translateMonths} from 'utils/helpers/translateMonths';

interface IProps {
  singleProject: SingleProject | null;
}

export const ActivityLogs = ({singleProject}: IProps) => {
  const dispatch = useSetGlobalState();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const {activityLogs} = useGlobalState((state) => state.project);

  const isLatin = language === 'latin';

  useEffect(() => {
    if (singleProject?.id) dispatch(getActivityLogACS(singleProject.id));
    // eslint-disable-next-line
  }, []);

  const setCutLength = () => {
    if (mediaBreakpointDown('sm').matches) return 40;

    return 55;
  };

  return (
    <div className="project-activity">
      <div className="project-activity__header">
        <span className="d-none d-md-block">
          <i className="far fa-address-book" />
        </span>
        <h3>{t('activities')}</h3>
      </div>
      <div className="project-activity__content">
        <ul>
          {activityLogs.map((activityLog) => {
            const date = moment(activityLog.time).format('MMMM DD. YYYY');
            return (
              <div className="project-title__activity-log" key={activityLog.id}>
                <div>
                  <p className="activity-log__name">
                    {activityLog.user.name} <span>{cutName(activityLog.text, setCutLength())}</span>
                  </p>
                </div>
                <p className="activity-log__date">
                  {isLatin
                    ? date
                    : translateMonths(
                        moment(activityLog.time).format('MMMM'),
                        moment(activityLog.time).format('DD. YYYY'),
                      )}
                </p>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
