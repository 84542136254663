import React from 'react';

import {Remainder} from 'typescript/models/Remainder';

import {RemainderComponent} from './Remainder';

interface IProps {
  remainders: Remainder[] | undefined;
  projectId: number;
}

export const ListOfRemainders = ({projectId, remainders}: IProps) => {
  return (
    <div className="remainders-dropdown">
      {remainders && remainders.length > 0 ? (
        <div>
          {remainders.map((remainder) => {
            return (
              <div key={remainder.id}>
                <ul className="remainders-dropdown__list">
                  <RemainderComponent projectID={projectId} remaindersList={remainders} remainder={remainder} />
                </ul>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
