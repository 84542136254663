import {Tag as TagComponent} from 'components/Tag/Tag';
import TagList from 'components/TagList/TagList';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useTranslation} from 'react-i18next';
import {SingleProject} from 'typescript/models/SingleProject';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

import TagOverview from '../TagOverview/TagOverview';

interface IProps {
  singleProject: SingleProject;
}

export default function Tags({singleProject}: IProps) {
  const {t} = useTranslation();
  const {user} = useGlobalState((state) => state.auth);

  const shouldDisplayButton = showSingleProjectButton(singleProject, user);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [5, 5],
  });

  const handleOpenTagsDropdown = () => {
    toggleOpen();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) toggleOpen();
    if (e.key === 'Enter' && !isOpen) toggleOpen();
  };

  const renderTags = () => {
    if (!singleProject.tags) return null;

    if (singleProject.tags.length > 2) {
      return (
        <>
          {singleProject?.tags.slice(0, 2).map((tag) => {
            return (
              <TagComponent key={`single-tag-${tag.id}`} variant={tag.color}>
                {tag.name}
              </TagComponent>
            );
          })}
          <TagList tags={singleProject!.tags} />
        </>
      );
    }

    return singleProject.tags.map((tag) => {
      return (
        <TagComponent key={`single-tag-${tag.id}`} variant={tag.color}>
          {tag.name}
        </TagComponent>
      );
    });
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleOnKeyDown,
  };

  return (
    <div>
      <div className="project__title">
        <h3>{t('tags')}:</h3>
        <div className="project__text">
          {renderTags()}
          <div ref={containerRef}>
            {shouldDisplayButton && (
              <button
                className={`project__title-more${isOpen ? ' project__title-more--open' : ''} badge-more`}
                onClick={handleOpenTagsDropdown}
                ref={setReferenceElement}
              >
                <i className={`far ${singleProject.tags.length > 0 ? 'fa-pencil' : 'fa-plus'}`} />
              </button>
            )}
            {isOpen && (
              <div
                ref={setPopperElement}
                className="project__tags-overview"
                style={styles.popper}
                {...attributes.popper}
              >
                <TagOverview {...dropdownProps} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
