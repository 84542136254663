import {useEffect, useState} from 'react';

import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';

import {OrganizersList} from './OrganizersList';

interface IProps {
  users: Member[] | null;
  singleProject: SingleProject;
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  styles: {[key: string]: React.CSSProperties};
  attributes: {[key: string]: {[key: string]: string} | undefined};
  handleToggle: () => void;
  handleCheck: (user: Member) => void;
}

export const OrganizersDropdown = (props: IProps) => {
  const {users, singleProject, setPopperElement, styles, attributes, handleToggle, handleCheck} = props;
  const {t} = useTranslation();
  const [listOfOrganizers, setListOfOrganizers] = useState<Member[]>([]);

  const listOfActiveUsers = users?.filter((user) => user.status === 'ACTIVE');

  useEffect(() => {
    if (listOfActiveUsers) setListOfOrganizers(listOfActiveUsers);
    // eslint-disable-next-line
  }, []);

  const handleMemberSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    if (listOfActiveUsers) {
      if (searchValue === '') {
        setListOfOrganizers(listOfActiveUsers);
      } else {
        const filteredListOfOrganizer = listOfActiveUsers.filter(
          (user) =>
            user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            user.email.toLowerCase().includes(searchValue.toLowerCase()),
        );

        setListOfOrganizers(filteredListOfOrganizer);
      }
    }
  };

  const debouncedOrganizerSearch = debounce(handleMemberSearch, 1000);

  return (
    <div id="popup_organizer" className="popup" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <header className="popup__header">
        <h4>{t('organizer')}</h4>
        <button className="btn-close" onClick={handleToggle}>
          <i className="fal fa-times" />
        </button>
      </header>
      <section className="popup__body popup__body--borderless project__organizers">
        <input type="text" placeholder={`${t('Search organizer')}`} onChange={debouncedOrganizerSearch} />
        <h3>{t('project organizer')}</h3>
        <ul className="popup__select">
          {listOfOrganizers.map((user) => (
            <OrganizersList
              key={`user-id-${user.id}`}
              user={user}
              singleProject={singleProject}
              handleCheck={handleCheck}
            />
          ))}
        </ul>
      </section>
    </div>
  );
};
