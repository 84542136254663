import {useTranslation} from 'react-i18next';

import './AuthLayout.scss';
import {TranslationButtons} from './TranslationButtons';

interface IProps {
  children: React.ReactNode;
  pageName: string;
}
export const AuthLayout = ({children, pageName}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className={`page-${pageName}`}>
      <section className="auth-layout">
        <TranslationButtons />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="auth-layout__wrap">
                <div className="auth-layout__inner">{children}</div>
                <footer className="auth-layout__footer">
                  {t('copy-right-first')} © {new Date().getFullYear()} {t('copy-right-second')}
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
