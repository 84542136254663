import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {unarchiveProjectsBulk} from 'services/projectService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import getArchivedProjectsACS from 'store/archive/getArchivedProjects/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import CloseModalButton from '../shared/CloseModalButton';

export default function UnarchiveBulkAction() {
  const {checkedProjects, filters} = useGlobalState((state) => state.archive);

  const {t} = useTranslation();
  const dispatch = useSetGlobalState();

  const handleUnarchive = async () => {
    await unarchiveProjectsBulk(checkedProjects)
      .then(() => {
        dispatch(
          setGlobalNotification({
            variant: 'success',
            message: 'Projects unarchived successfully',
            icon: 'fa-credit-card-blank',
          }),
        );
      })
      .catch(() => {
        dispatch(setGlobalNotification({variant: 'error', message: 'Projects unarchived unsuccessfully'}));
      })
      .finally(() => {
        batch(() => {
          dispatch(toggleModalAC({isOpen: false, component: ModalComponents.UNARCHIVE_BULK_ACTION}));
          dispatch(getArchivedProjectsACS(filters));
        });
      });
  };

  return (
    <div className="confirm-dialog">
      <CloseModalButton />
      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--danger">
            <i className="far fa-exclamation-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>{t('Are you sure you want to unarchive this project selection')}?</h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--uppercase" onClick={handleUnarchive}>
                {t('Return')}
              </button>
              <button className="btn btn--secondary btn--uppercase">{t('Quit')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
