import moment from 'moment';
import 'moment/locale/sr';

export const formatedDateString = (date: string) => {
  const dayOfWeek = moment(date).locale('sr').format('dddd');

  const otherDateFormat = moment(date).format('DD. MMMM YYYY.');

  return `${dayOfWeek}, ${otherDateFormat}`;
};
