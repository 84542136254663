import React from 'react';

export const CheckAll = ({...props}: any) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        width="18"
        height="17.0915"
        rx="2"
        transform="matrix(-1 0 0 1 19 1.06348)"
        fill="#EFF2F4"
        fillOpacity="0.3"
        stroke="#EFF2F4"
        strokeOpacity="0.3"
        strokeWidth="0.5"
      />
    </svg>
  );
};
