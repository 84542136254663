import {useState, useEffect} from 'react';

import {Breakpoints, mediaBreakpointDown, mediaBreakpointUp} from 'hooks/useMediaBreakpoint/media';

/*
 PLEASE USE THIS SPARIGNLY AS IT IMPACTS PREFORMANCE NEGATIVELY.
 CONSIDER USING FUNCTIONS FROM media.ts AND CHECKING UP ON THOSE QUERIES IF RESOLUTION MATCHES.
 THIS SHOULD BE USED ONLY WHEN YOU NEED "REACTIVITY".

 EXAMPLE OF MORE PREFORMANT WAY:
 const mediaQuery = mediaBreakpointUp('lg')
 const isLargerThanLg = mediaQuery.matches

*/

export function useMediaBreakpoint(rule: 'up' | 'down', breakpoint: Breakpoints) {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media = rule === 'up' ? mediaBreakpointUp(breakpoint) : mediaBreakpointDown(breakpoint);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = (mqlEvent: MediaQueryListEvent) => {
      setMatches(mqlEvent.matches);
    };

    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [matches, breakpoint, rule]);

  return matches;
}
