export const translateMonths = (month: string, date: string) => {
  const translatedMonths: {[key: string]: string} = {
    januar: 'Јануар',
    februar: 'Фебруар',
    mart: 'Март',
    april: 'Април',
    maj: 'Maj',
    jun: 'Јун',
    jul: 'Јул',
    avgust: 'Август',
    septembar: 'Септембар',
    oktobar: 'Октобар',
    novembar: 'Новембар',
    decembar: 'Децембар',
  };

  return `${translatedMonths[month]} ${date}`;
};
