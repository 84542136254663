import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {cloneDeep} from 'lodash';
import {getSingleProject} from 'services/projectService';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getProjectsSagaACS} from 'store/project/getProjects/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {history} from 'utils/history/history';

import DashboardCalendar from './DashboardCalendar/DashboardCalendar';
import DashboardTable from './DashboardTable/DashboardTable';
import './Dashboard.scss';
import {DashboardTableMobile} from './DashboardTable/DashboardTableMobile';

interface IProps {
  id?: number;
}

const Dashboard = ({id}: IProps) => {
  const dispatch = useSetGlobalState();

  const appState = useGlobalState((state) => state.app);
  const {projectFilters} = useGlobalState((state) => state.project);
  const cloneOfProjectFilters = cloneDeep(projectFilters);
  const isTablet = useMediaBreakpoint('down', 'lg');
  const numberOfItems = 15;

  const fetchSingleProject = async (projectId: number) => {
    try {
      const singleProject = await getSingleProject(projectId);
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
      dispatch(setSingleProjectAC(singleProject.data));
    } catch (error) {
      history.replace('*');
    }
  };

  useEffect(() => {
    if (id) fetchSingleProject(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const payload = cloneOfProjectFilters;
    delete payload.active;
    payload.size = numberOfItems;
    if (payload.own === undefined) payload.own = true;

    dispatch(getProjectsSagaACS(payload));
    // eslint-disable-next-line
  }, [projectFilters]);

  const renderTable = () => {
    return isTablet ? <DashboardTableMobile /> : <DashboardTable />;
  };

  return (
    <AppLayout pageName="dashboard">
      {appState.projectView === 'table' && renderTable()}
      {appState.projectView === 'calendar' && <DashboardCalendar />}
    </AppLayout>
  );
};

export default Dashboard;
