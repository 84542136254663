/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import {useTranslation} from 'react-i18next';

interface IProps {
  allNotifications: number;
  handleShowAll: () => void;
}

export const NotificationZeroState = ({allNotifications, handleShowAll}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className="zero-state">
      <div className="no-notification-icon">
        <i className="fal fa-bell-slash" />
      </div>

      <div>
        <h2>{t('notification-zero-title')}</h2>
        {allNotifications > 0 && (
          <p>
            {t('notification-zero-redirect')}{' '}
            <span onClick={handleShowAll} style={{cursor: 'pointer'}}>
              {t('notification-zero-redirect-link')}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
