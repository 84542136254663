import * as Yup from 'yup';

const addUserValidationSchema = Yup.object().shape({
  email: Yup.string().email('email-validation').required('Ovo polje je obavezno'),
  name: Yup.string().required(),
  positionName: Yup.string().required(),
  positionId: Yup.number(),
  unitName: Yup.string().required(),
  unitId: Yup.number(),
  roles: Yup.string(),
});

export default addUserValidationSchema;
