import {AxiosResponse} from 'axios';
import {put, call} from 'redux-saga/effects';
import {getProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from '../actions';
import {setProjectLoadingAC} from '../setLoading/actionCreator';
import {setPaginationAC} from '../setPagination/actionCreator';
import {setProjectsAC} from '../setProjects/setProjects';

export function* getProjectsSaga(action: {type: ProjectActions.GET_PROJECTS; payload: IGetProjectsDTO}) {
  try {
    yield put(setProjectLoadingAC(true));
    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(getProjects, action.payload);
    const {last, first} = response.data;

    yield put(setProjectsAC(response.data.content));

    yield put(setPaginationAC({last, first}));
  } catch (error) {
    handleErrorsSaga(error);
  } finally {
    yield put(setProjectLoadingAC(false));
  }
}
