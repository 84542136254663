import React, {useState} from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import Datepicker, {DatePickerType} from 'components/Datepicker/Datepicker';
import MobileSort from 'components/MobileSort/MobileSort';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {setCheckedDeletedProjectsAC} from 'store/deletedProjects/setCheckedDeletedProjects/actionCreator';
import {setDeletedProjectsFiltersAC} from 'store/deletedProjects/setDeletedProjectsFilters/actionCreator';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {SingleProject} from 'typescript/models/SingleProject';

import {DeleteTagFilters} from '../Desktop/DeleteTagFilters';
import {getDeletedProjectsFilters} from '../utils/getDeletedProjectsFilters';
import {DeleteTableDropdown} from './DeleteTableDropdown';

interface IProps {
  deletedProjects: SingleProject[];
  checkedProjects: number[];
}

export const Header = ({deletedProjects, checkedProjects}: IProps) => {
  const {projectFilters: filters} = useGlobalState((state) => state.deletedProjects);
  const dispatch = useSetGlobalState();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset: [30, 7],
  });

  const {
    styles: sortStyles,
    attributes: sortAttributes,
    isOpen: isSortOpen,
    toggleOpen: toggleSortOpen,
    setReferenceElement: setSortRefEl,
    setPopperElement: setSortPopperEl,
    containerRef: sortContainerRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [-50, 12],
  });

  const {
    styles: tagStyles,
    attributes: tagAttributes,
    isOpen: isTagOpen,
    toggleOpen: toggleTagOpen,
    setReferenceElement: setTagRefEl,
    setPopperElement: setTagPopperEl,
    containerRef: tagContainerRef,
  } = useDropdown({
    position: 'bottom',
    offset: [-5, 13],
  });

  const handleCheckAll = () => {
    const listOfDeletedProjects = deletedProjects.map((project) => project.id);
    let checkedPayload: number[] = [];
    if (!checkedProjects.length) checkedPayload = [...listOfDeletedProjects];
    dispatch(setCheckedDeletedProjectsAC(checkedPayload));
  };

  const handleStartDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setStartDate(date);
      const dateFrom = moment(date?.toString()).format('YYYY-MM-DD');
      payload = getDeletedProjectsFilters(filters, 'dateFrom', dateFrom);

      dispatch(setDeletedProjectsFiltersAC({...payload}));
    }
  };

  const handleEndDateChange = (date: DatePickerType) => {
    if (!Array.isArray(date)) {
      let payload = filters;
      setEndDate(date);
      const dateTo = moment(date?.toString()).format('YYYY-MM-DD');
      payload = getDeletedProjectsFilters(filters, 'dateTo', dateTo);

      dispatch(setDeletedProjectsFiltersAC({...payload}));
    }
  };

  const handleReset = () => {
    const payload: IGetProjectsDTO = {...filters};
    setStartDate(null);
    setEndDate(null);

    payload.dateFrom = '';
    payload.dateTo = '';

    dispatch(setDeletedProjectsFiltersAC({...payload}));
  };

  const handleSort = (value: string) => {
    const payload = getDeletedProjectsFilters(filters, value);
    dispatch(setDeletedProjectsFiltersAC(payload));
  };

  const mobileFilters = [
    {name: `${t('projects')}`, value: 'title'},
    {name: `${t('date')} ${t('event')}`, value: 'date'},
    {name: `${t('time')}`, value: 'updatedOn'},
  ];

  return (
    <div className="grid__header">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="grid__nav">
              <div className="grid__inner">
                <div className="check-main">
                  <div className="checkbox">
                    <Checkbox
                      id="checkMainMobile"
                      handleClick={handleCheckAll}
                      checked={Boolean(checkedProjects.length)}
                      className="checkbox"
                    />
                  </div>
                </div>
                <DeleteTableDropdown />
              </div>
              <div className="grid__inner">
                <div className="header__inner">
                  <div className="header__action header__action--filter" ref={sortContainerRef}>
                    <div
                      ref={setSortRefEl}
                      role="button"
                      tabIndex={0}
                      onClick={() => toggleSortOpen()}
                      className="position-relative"
                    >
                      <i className="far fa-sort-alt" />
                    </div>
                    {isSortOpen && (
                      <div
                        id="popup_tag-filter"
                        ref={setSortPopperEl}
                        style={sortStyles.popper}
                        {...sortAttributes.popper}
                        className="tag-dropdown"
                      >
                        <MobileSort filters={mobileFilters} handleSort={handleSort} />
                      </div>
                    )}
                  </div>
                  <div className="header__action header__action--filter" ref={tagContainerRef}>
                    <div
                      ref={setTagRefEl}
                      role="button"
                      tabIndex={0}
                      onClick={() => toggleTagOpen()}
                      className="tag-dropdown"
                    >
                      <i className="far fa-filter" />
                    </div>
                    {isTagOpen && (
                      <div
                        id="popup_tag-filter"
                        ref={setTagPopperEl}
                        style={tagStyles.popper}
                        {...tagAttributes.popper}
                      >
                        <DeleteTagFilters />
                      </div>
                    )}
                  </div>
                  <div ref={containerRef}>
                    <button ref={setReferenceElement} className="header__action" onClick={() => toggleOpen()}>
                      <i className="far fa-calendar-alt" />
                    </button>
                    {isOpen && (
                      <div
                        className="datepicker__position"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <Datepicker
                          startDate={startDate}
                          endDate={endDate}
                          onStartDateChange={handleStartDateChange}
                          onEndDateChange={handleEndDateChange}
                          onReset={handleReset}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <RoutesDropdown>
                  <i className="far fa-trash-alt" />
                </RoutesDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
