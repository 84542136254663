import {useState} from 'react';

import {useTranslation} from 'react-i18next';

import CloseModalButton from '../shared/CloseModalButton';

export default function ConfirmUserInvitation() {
  const [isUserInvited, setUserInvited] = useState<boolean>(false);
  const {t} = useTranslation();

  return isUserInvited ? (
    <div className="confirm-dialog">
      <CloseModalButton />

      <div className="confirm-dialog__wrap confirm-dialog__wrap--text">
        <div className="confirm-dialog--success">
          <i className="far fa-check-circle" />
        </div>
        <div className="confirm-dialog__content">
          <h6>Poziv je uspešno poslat.</h6>
        </div>
      </div>
    </div>
  ) : (
    <div className="confirm-dialog">
      <button className="btn-close">
        <i className="fal fa-times" />
      </button>

      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--warning">
            <i className="far fa-question-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              Da li želite da <em>Aleksandar Perović</em> bude ponovo pozvan?
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={() => setUserInvited(true)}>
                Pošaljite
              </button>
              <button className="btn btn--secondary btn--large">{t('quit')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
