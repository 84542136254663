import {IDeleteUserPayload} from 'typescript/models/User';

import {UserActions} from '../actions';

export const setDeleteUserAC = (payload: number | null) => {
  return {type: UserActions.SET_USER_DELETE, payload};
};

export const deleteUserACS = (payload: IDeleteUserPayload) => {
  return {type: UserActions.DELETE_USER, payload};
};
