import {API} from 'constants/api';

import React, {useEffect, useState} from 'react';

import FileChooser from 'components/FileChooser/FileChooser';
import useClickAway from 'hooks/useClickAway';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import {createComment} from 'store/comments/create/actionCreator';
import {setIsReplying, setParentId} from 'store/comments/reply/actionCreator';
import {setUploadedFrom, setUploadedDocument} from 'store/documents/upload/actionCreator';
import {CreateCommentDTO} from 'typescript/dto/CreateCommentDTO';
import {Member} from 'typescript/models/Member';

import AddEmoji from '../AddEmoji/AddEmoji';
import AddMention from '../AddMention/AddMention';

interface IProps {
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setIsReplied: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddComment = ({comment, setIsReplied, setComment}: IProps) => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const authState = useGlobalState((state) => state.auth);
  const {singleProject} = useGlobalState((state) => state.project);
  const {uploadedDocument, uploadedFrom} = useGlobalState((state) => state.documents);
  const {isReplying, parentId} = useGlobalState((state) => state.comments);
  const {users} = useGlobalState((state) => state.users);

  const [inFocus, setInFocus] = useState(false);
  let usersList: Member[] | null = [];
  if (users) usersList = users.filter((user) => user.status === 'ACTIVE');
  const [listOfUsers, setListOfUsers] = useState<Member[] | null>(usersList);

  const handleLooseFocusCallBack = () => {
    setInFocus(false);
    if (isReplying) {
      dispatch(setIsReplying(false));
      dispatch(setParentId(null));
    }
  };

  const {containerRef: clickAwayRef} = useClickAway<HTMLDivElement>(handleLooseFocusCallBack);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [20, 2],
  });

  const handleCreateComment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (singleProject?.id) {
      const newComment: CreateCommentDTO = {
        text: comment,
        userId: authState.user!.id,
        projectId: singleProject.id,
      };

      if (isReplying) newComment.parentId = parentId!;

      batch(() => {
        dispatch(createComment(newComment, singleProject.id));
        dispatch(setIsReplying(false));
        dispatch(setParentId(null));
      });
      setComment('');
      setIsReplied(false);
    }
  };

  useEffect(() => {
    if (uploadedFrom === 'add-comment' && uploadedDocument) {
      let documentName = uploadedDocument.name?.replace(/ /g, '');

      if (!uploadedDocument.name) documentName = uploadedDocument.url!.replace(/ /g, '');

      let isExternalLink: boolean = false;

      try {
        const docUrl = new URL(uploadedDocument.url ? uploadedDocument.url : '');
        isExternalLink = process.env.REACT_APP_API_URL !== docUrl.origin;
      } catch (error) {
        isExternalLink = false;
      }

      const documentUrl = isExternalLink
        ? uploadedDocument.url
        : `${process.env.REACT_APP_API_URL}${API.PROJECT_SERVICE.BASE}/${singleProject!.id}/document/${
            uploadedDocument.id
          }/download`;

      setComment((currComment) => `${currComment} [${documentName}](${documentUrl})`);

      dispatch(setUploadedDocument(null));
      dispatch(setUploadedFrom(null));
    }
    // eslint-disable-next-line
  }, [uploadedDocument, uploadedFrom]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.currentTarget;
    const activeUsers = users?.filter((user) => user.status === 'ACTIVE');

    const isThereEmptySpaceBefore = value[value.length - 2] === ' ';
    const isEnterPressed = value[value.length - 1] === '\n';

    const isFirstCharacterAtSign = value.length === 1 && value[value.length - 1] === '@';
    if (isFirstCharacterAtSign && activeUsers) {
      toggleOpen(true);
      setListOfUsers(activeUsers);
    } else if (value[value.length - 1] === '@' && isThereEmptySpaceBefore && activeUsers) {
      setListOfUsers(activeUsers);
      toggleOpen(true);
    } else if (isOpen && isEnterPressed) {
      toggleOpen(false);
    }

    if (isOpen && activeUsers) {
      const filteredUsers = activeUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(value.slice(1).toLowerCase()) ||
          user.email.toLowerCase().includes(value.slice(1).toLowerCase())
        );
      });

      let payload = filteredUsers;
      if (value.slice(1) === '') payload = activeUsers;
      setListOfUsers(payload);
    }

    setComment(value);
  };

  const handleInFocus = () => {
    setInFocus(true);
  };

  return (
    <div className="project__textarea project__textarea--comment">
      <div className="project__comment" ref={clickAwayRef}>
        <TextareaAutosize
          id="comment"
          placeholder={`${t('Write comment')}...`}
          value={comment}
          onChange={handleChange}
          minRows={inFocus ? 1 : 2}
          maxRows={4}
          onFocus={handleInFocus}
        />
        {inFocus && (
          <div className="project__comment__action">
            <div className="project__comment__inner">
              <button className="btn btn--primary" disabled={comment ? false : true} onMouseDown={handleCreateComment}>
                <span className="first-letter-capital">{t('send')}</span>
              </button>
              <div className="project__comment__controls">
                {singleProject && (
                  <FileChooser
                    toggler={
                      <span>
                        <i className="far fa-paperclip" />
                      </span>
                    }
                    singleProject={singleProject}
                    togglerClassName="project__comment__controls__control"
                    from="add-comment"
                    start="left"
                    offset={[104, -10]}
                  />
                )}
                <AddMention
                  listOfUsers={listOfUsers}
                  setComment={setComment}
                  styles={styles}
                  attributes={attributes}
                  isOpen={isOpen}
                  toggleOpen={toggleOpen}
                  setReferenceElement={setReferenceElement}
                  setPopperElement={setPopperElement}
                  containerRef={containerRef}
                />
                <AddEmoji setComment={setComment} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddComment;
