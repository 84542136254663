const buildCalendar = (currentlySelectedDay: moment.Moment) => {
  const startOfWeekInMonth = currentlySelectedDay.clone().startOf('month').startOf('week');
  const endOfWeekInMonth = currentlySelectedDay.clone().endOf('month').endOf('week');

  const calendar: moment.Moment[][] = [];
  const day = startOfWeekInMonth.clone().subtract(1, 'day');

  while (day.isBefore(endOfWeekInMonth, 'day')) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone()),
    );
  }

  return calendar;
};

export default buildCalendar;
