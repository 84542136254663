import {useGlobalState} from 'hooks/useGlobalState';
import {ModalComponents} from 'typescript/enums/ModalComponents';

import AddOrganizer from './AddOrganizer/AddOrganizer';
import AddUser from './AddUser/AddUser';
import {ConfirmActivateProject} from './ConfirmActivateProject/ConfirmActivateProject';
import ConfirmArchive from './ConfirmArchive/ConfirmArchive';
import {ConfirmDeleteProject} from './ConfirmDeleteProject/ConfirmDeleteProject';
import {ConfirmDeleteUser} from './ConfirmDeleteUser/ConfirmDeleteUser';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';
import {ConfirmEmailChange} from './ConfirmEmailChange/ConfirmEmailChange';
import ConfirmLeaveProject from './ConfirmLeaveProject/ConfirmLeaveProject';
import ConfirmOrganizer from './ConfirmOrganizer/ConfirmOrganizer';
import ConfirmRegister from './ConfirmRegister/ConfirmRegister';
import ConfirmUnarchiveProject from './ConfirmUnarchiveProject/ConfirmUnarchiveProject';
import {ConfirmUnsend} from './ConfirmUnsend/ConfirmUnsend';
import {ConfirmUserActivationDeactivaiton} from './ConfirmUserActivationDeactivaiton/ConfirmUserActivationDeactivaiton';
import ConfirmUserInvitation from './ConfirmUserInvitation/ConfirmUserInvitation';
import {ConfirmUserReinvite} from './ConfirmUserReinvite/ConfirmUserReinvite';
import DeleteAndUndeletable from './DeleteTags/DeleteAndUndeletable';
import {DeleteSingleTag} from './DeleteTags/DeleteSingleTag';
import {DeleteTag} from './DeleteTags/DeleteTag';
import Project from './Project/Project';
import ProjectDayPreview from './ProjectDayPreview/ProjectDayPreview';
import SendBulkUsersInvitation from './SendBulkUsersInvitation/SendBulkUsersInvitation';
import {SingleUser} from './SingleUser/SingleUser';
import SuccessDialog from './SuccessDialog/SuccessDialog';
import UnarchiveBulkAction from './UnarchiveBulkAction/UnarchvieBulkAction';

export default function ModalContent() {
  const modalState = useGlobalState((state) => state.modal);

  const renderModalContent = (component: ModalComponents | null) => {
    switch (component) {
      case ModalComponents.PROJECT:
        return <Project />;
      case ModalComponents.PROJECT_DAY_PREVIEW:
        return <ProjectDayPreview />;
      case ModalComponents.CONFIRM_DIALOG:
        return <ConfirmDialog />;
      case ModalComponents.ADD_USER:
        return <AddUser />;
      case ModalComponents.SUCCESS_DIALOG:
        return <SuccessDialog />;
      case ModalComponents.CONFIRM_USER_INVITATION:
        return <ConfirmUserInvitation />;
      case ModalComponents.CONFIRM_LEAVE_PROJECT:
        return <ConfirmLeaveProject />;
      case ModalComponents.CONFIRM_ARCHIVE:
        return <ConfirmArchive />;
      case ModalComponents.CONFIRM_REGISTER_INVITATION:
        return <ConfirmRegister />;
      case ModalComponents.CONFIRM_USER_ACTIVATION_DEACTIVATION:
        return <ConfirmUserActivationDeactivaiton />;
      case ModalComponents.SINGLE_USER:
        return <SingleUser />;
      case ModalComponents.DELETE_USER:
        return <ConfirmDeleteUser />;
      case ModalComponents.ADD_ORGANIZER:
        return <AddOrganizer />;
      case ModalComponents.CONFIRM_ORGANIZER:
        return <ConfirmOrganizer />;
      case ModalComponents.CONFIRM_REINVITE:
        return <ConfirmUserReinvite />;
      case ModalComponents.DELETE_TAGS:
        return <DeleteAndUndeletable />;
      case ModalComponents.CONFIRM_UNDO_CALL:
        return <ConfirmUnsend />;
      case ModalComponents.CONFIRM_ACTIVATE_PROJECT:
        return <ConfirmActivateProject />;
      case ModalComponents.CONFIRM_DELETE_PROJECT:
        return <ConfirmDeleteProject />;
      case ModalComponents.CONFIRM_EMAIL_CHANGE:
        return <ConfirmEmailChange />;
      case ModalComponents.CONFIRM_DELETE_TAG:
        return <DeleteTag />;
      case ModalComponents.CONFIRM_SINGLE_TAG_DELETE:
        return <DeleteSingleTag />;
      case ModalComponents.SEND_BULK_USERS_INVITATION:
        return <SendBulkUsersInvitation />;
      case ModalComponents.UNARCHIVE_BULK_ACTION:
        return <UnarchiveBulkAction />;
      case ModalComponents.CONFIRM_UNARCHIVE_PROJECT:
        return <ConfirmUnarchiveProject />;

      default:
        return null;
    }
  };

  return <>{renderModalContent(modalState.component)}</>;
}
