import {IDeleteReaction} from 'typescript/interfaces/IReactionsActions';

import {ReactionsActions} from '../actions';

export function deleteReaction(payload: IDeleteReaction['payload']): IDeleteReaction {
  return {
    type: ReactionsActions.DELETE_REACTION,
    payload,
  };
}
