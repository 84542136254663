import moment from 'moment';

const styleDropdown = (currentlySelectedDay: moment.Moment, day: string): string => {
  const firstDayOfMonth = currentlySelectedDay.clone().startOf('month');
  const lastDayOfMonth = currentlySelectedDay.clone().endOf('month');
  const today = moment(day);

  let style = '';
  if (today.isBefore(firstDayOfMonth) || today.isAfter(lastDayOfMonth)) style = 'outside-month';
  return style;
};

export default styleDropdown;
