import {ISetSelectedUsers} from 'typescript/interfaces/IUserActions';
import {Member} from 'typescript/models/Member';

import {UserActions} from '../actions';

export const setSelectedUsersAC = (payload: Member[]): ISetSelectedUsers => {
  return {
    type: UserActions.SET_SELECTED_USERS,
    payload,
  };
};
