import React from 'react';

import FileChooser from 'components/FileChooser/FileChooser';
import {saveAs} from 'file-saver';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {downloadProjectDocument} from 'services/documentsService';
import {deleteDocument} from 'store/documents/delete/actionCreator';
import {FileTypes} from 'typescript/enums/FileTypes';
import {Document} from 'typescript/models/Document';
import './MobileDocumentsList.scss';

interface IProps {
  documents: Document[];
}

export default function MobileDocumentsList({documents}: IProps) {
  const {singleProject} = useGlobalState((state) => state.project);

  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: [2, 10],
  });

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleDownloadDocument = async (document: Document) => {
    const {name, id} = document;

    if (singleProject?.id) {
      const response = await downloadProjectDocument(singleProject.id, id);

      const url = URL.createObjectURL(response.data);

      saveAs(url, name);
    }
  };

  const handleDeleteDocument = (id: number) => {
    if (singleProject?.id) {
      const payload = {
        projectId: singleProject.id,
        documentId: id,
      };

      dispatch(deleteDocument(payload));
    }
  };

  const hasDocumentsList = documents.length > 0;

  return (
    <>
      <div className="dropdown" ref={containerRef}>
        <div className="dropdown__toggler" ref={setReferenceElement}>
          <span role="button" onClick={handleDropdownOpen}>
            <button className="project__document--toggler">
              <i className="far fa-paperclip" />
            </button>
          </span>
        </div>
        {isOpen && (
          <div
            id="popup_document"
            className="popup file-chooser"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <header className="popup__header">
              <h4>{t('documents')}</h4>
              <button className="btn-close" onClick={handleDropdownOpen}>
                <i className="fal fa-times" />
              </button>
            </header>
            <div className="mobile-documents-list__list">
              {hasDocumentsList && (
                <ul>
                  {documents.map((document) => {
                    return (
                      <li key={`doc-${document.id}`}>
                        {document.type !== FileTypes.FILE && (
                          <a
                            className="mobile-documents-list__item"
                            target="_blank"
                            download
                            rel="noreferrer"
                            href={document.url}
                          >
                            {document.name ? document.name : document.url}
                          </a>
                        )}
                        {document.type === FileTypes.FILE && (
                          <span
                            className="mobile-documents-list__item"
                            role="button"
                            onClick={() => handleDownloadDocument(document)}
                          >
                            {document.name}
                          </span>
                        )}
                        <button
                          className="mobile-documents-list__item__close"
                          onClick={() => handleDeleteDocument(document.id)}
                        >
                          <i className="fal fa-times" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {!hasDocumentsList && (
              <div className="mobile-documents-list__zero-state">
                <p className="first-letter-capital">{t('currently there are no attached files for this project')}.</p>
              </div>
            )}

            {singleProject && (
              <FileChooser
                toggler={
                  <button className="mobile-documents-list__action first-letter-capital">{t('add document')}</button>
                }
                start="bottom"
                noFlex
                singleProject={singleProject}
                offset={[0, 0]}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
