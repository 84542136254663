/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import React from 'react';

import {Emoji} from 'emoji-mart';
import {saveAs} from 'file-saver';
import ReactMarkdown from 'react-markdown';
import httpClient from 'utils/httpClient/httpClient';

import {splitBetween} from '../../Helpers/parseMentions';

interface IProps {
  text: string;
}

const BoldText = ({children}: {children: string}) => {
  const email = splitBetween(children, '>>>', '<<<');

  return <b title={email}>{children.split('>>>')[0]}</b>;
};

type MarkdownContent = React.ReactNode | (React.ReactNode | string)[];

export default function SingleCommentMarkdown({text}: IProps) {
  const parseMention = (content: MarkdownContent) => {
    if (Array.isArray(content)) {
      const newContent: (React.ReactNode | string)[] = [];
      content.forEach((word) => {
        if (React.isValidElement(word)) {
          newContent.push(word);
        } else if (typeof word === 'string') {
          const mentionRegexp = new RegExp(/(@\w+ >>>.*?<<<)/gm);
          const parts = word.split(mentionRegexp);

          parts.forEach((part) => {
            if (part.match(mentionRegexp)) {
              const email = splitBetween(part, '>>>', '<<<');
              newContent.push(<BoldText key={`mention-${email}`}>{part}</BoldText>);
            } else {
              newContent.push(part);
            }
          });
        }
      });

      return newContent;
    }

    return content;
  };

  const parseEmoji = (content: MarkdownContent): MarkdownContent => {
    // Match from a-z, A-Z, 0-9, _ , +, -, for n characters length in multiline.
    if (Array.isArray(content)) {
      const newContent: (React.ReactNode | string)[] = [];
      content.forEach((word, wordIndex) => {
        if (React.isValidElement(word)) {
          newContent.push(word);
        } else if (typeof word === 'string') {
          const emojiRegExp = new RegExp(/(:[a-zA-Z0-9_\-+]+:)/gm);
          const parts = word.split(emojiRegExp);

          parts.forEach((part, partIndex) => {
            if (part.match(emojiRegExp)) {
              newContent.push(<Emoji key={`${part}-${wordIndex}-${partIndex}`} set="google" emoji={part} size={18} />);
            } else {
              newContent.push(part);
            }
          });
        }
      });

      return newContent;
    }

    return content;
  };

  const parseText = (content: React.ReactNode | (React.ReactNode | string)[]) => {
    let newContent = parseEmoji(content);
    newContent = parseMention(newContent);

    return newContent;
  };

  return (
    <ReactMarkdown
      components={{
        p: ({node, ...rest}) => {
          return <p>{parseText(rest.children)}</p>;
        },
        li: ({node, ...rest}) => {
          return <li>{parseText(rest.children)}</li>;
        },
        a: ({node, ...rest}) => {
          const docUrl = new URL(rest?.href ? rest.href : '');

          const isExternalLink = process.env.REACT_APP_API_URL !== docUrl.origin;

          return isExternalLink ? (
            <a {...rest} target="_blank" rel="noopener noreferrer">
              {rest.children}
            </a>
          ) : (
            <button
              className="btn btn--link"
              onClick={async () => {
                httpClient
                  .get(rest?.href ? rest.href : '', {
                    responseType: 'blob',
                  })
                  .then((res) => {
                    const url = URL.createObjectURL(res.data);

                    saveAs(url, rest.children.join(' '));
                  });
              }}
            >
              {rest.children}
            </button>
          );
        },
      }}
    >
      {text}
    </ReactMarkdown>
  );
}
