import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {checkForUsersACS} from 'store/auth/checkForUser/actionCreators';
import {setUserAC} from 'store/auth/setUser/actionCreator';
import {getNotificationsACS} from 'store/notification/getNotifications/actionCreators';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {User} from 'typescript/models/User';

interface IProps {
  children: React.ReactNode;
}

export default function AppSetup({children}: IProps) {
  const dispatch = useSetGlobalState();
  const authState = useGlobalState((state) => state.auth);

  useEffect(() => {
    dispatch(checkForUsersACS());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const stringifiedUser = localStorage.getItem('user');
    if (stringifiedUser) {
      const user: User = JSON.parse(stringifiedUser);

      dispatch(setUserAC(user));
    }
  }, [dispatch]);

  useEffect(() => {
    if (authState.user) {
      dispatch(getUsersACS({page: 0}));
    }
  }, [dispatch, authState.user]);

  useEffect(() => {
    if (authState.user) dispatch(getNotificationsACS({order: 'DESC', sort: 'time'}));
  }, [dispatch, authState.user]);

  return <>{children}</>;
}
