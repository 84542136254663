import React from 'react';

interface IProps {
  handleSelectNumberOfDays: (value: number) => void;
}

export const AddNumberOfDays = ({handleSelectNumberOfDays}: IProps) => {
  return (
    <div className="remainder-days__wrapper">
      <input
        className="remainder-days"
        type="number"
        min={0}
        defaultValue={0}
        step={1}
        onChange={(e) => handleSelectNumberOfDays(+e.target.value)}
      />
    </div>
  );
};
