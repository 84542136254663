import MembersTruncatedList from 'components/MembersTruncatedList/MembersTruncatedList';
import {SingleProject} from 'typescript/models/SingleProject';

interface IProps {
  project: SingleProject;
}

export const Organizers = ({project}: IProps) => {
  const organizersLength = project.organizers.length;
  const firstOrganizerName = project.organizers[0]?.name || '';

  return (
    <div className="table__truncated">
      {organizersLength > 0 ? (
        <MembersTruncatedList membersLength={organizersLength} firstMemberName={firstOrganizerName} />
      ) : (
        <p>/</p>
      )}
    </div>
  );
};
