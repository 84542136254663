import {useTranslation} from 'react-i18next';

export const TranslationButtons = () => {
  const {i18n} = useTranslation();

  return (
    <div className="auth-layout__translation">
      <button
        onClick={() => {
          i18n.changeLanguage('cyrillic');
        }}
        className={`translation-button ${i18n.language === 'cyrillic' ? ' translation-button--active' : ''}`}
      >
        <span>ЋИР</span>
      </button>
      <span>|</span>
      <button
        onClick={() => {
          i18n.changeLanguage('latin');
        }}
        className={`translation-button${i18n.language === 'latin' ? ' translation-button--active' : ''}`}
      >
        <span>LAT</span>
      </button>
    </div>
  );
};
