import {Tag as TagComponent} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setArchiveFiltersAC} from 'store/archive/setArchiveFilters/actionCreator';
import {setCheckedArchiveTagsAC} from 'store/archive/setCheckedTags/actionCreator';
import {SingleProject} from 'typescript/models/SingleProject';
import {Tag} from 'typescript/models/Tag';

interface IProps {
  project: SingleProject;
}

export const Tags = ({project}: IProps) => {
  const dispatch = useSetGlobalState();
  const {filters, checkedTags} = useGlobalState((state) => state.archive);

  const handleTagFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tagID: number) => {
    e.stopPropagation();

    let checkedTagPayload: number[] = [];
    if (checkedTags.includes(tagID)) checkedTagPayload = checkedTags.filter((tag) => tag !== tagID);
    else checkedTagPayload = [...checkedTags, tagID];

    dispatch(setCheckedArchiveTagsAC(checkedTagPayload));

    const tagIds = checkedTagPayload.map(String).join(',');
    const payload = {...filters, tagIds};
    if (tagIds.length) dispatch(setArchiveFiltersAC(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setArchiveFiltersAC(newPayload));
    }
  };

  const displayTagsList = (tagInTags: Tag | undefined) => {
    return (
      <div key={`project-archive-tag-${tagInTags?.id}`}>
        {tagInTags ? <TagComponent variant={tagInTags.color}>{tagInTags.name}</TagComponent> : null}
      </div>
    );
  };

  const renderTags = () => {
    if (project.tags.length <= 3) {
      return project.tags.map((tag) => {
        return (
          <div tabIndex={0} role="button" onClick={(e) => handleTagFilter(e, tag.id)} key={tag.id}>
            {displayTagsList(tag)}
          </div>
        );
      });
    }

    return (
      <>
        {project.tags.slice(0, 3).map((tag) => (
          <div tabIndex={0} role="button" onClick={(e) => handleTagFilter(e, tag.id)} key={tag.id}>
            {displayTagsList(tag)}
          </div>
        ))}
        <TruncateBadge num={project.tags.length - 3} />
      </>
    );
  };

  return <>{renderTags()}</>;
};
