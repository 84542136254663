import React, {useEffect} from 'react';

import ContextMenu from 'components/Sidebar/ContextMenu';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {firstLetterCapital} from 'utils/helpers/firstLetterCapital';
import isMobileView from 'utils/helpers/isMobileView';

export const SideBarDropdown = () => {
  const {t} = useTranslation();
  const dispatch = useSetGlobalState();
  const {checkedProjects} = useGlobalState((state) => state.archive);

  let offset: [number, number] = [-10, 10];
  if (isMobileView()) offset = [-12, 10];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-end',
    offset,
  });

  const isCheckedLengthZero = checkedProjects.length === 0;
  const shouldDisableClick = isOpen && isCheckedLengthZero;

  const handleUnarchiveProjects = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (checkedProjects.length) {
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.UNARCHIVE_BULK_ACTION}));
    }
  };

  const handleBulkTogglerOpen = () => {
    if (checkedProjects.length > 0) toggleOpen();
  };

  useEffect(() => {
    if (shouldDisableClick) toggleOpen();
    // eslint-disable-next-line
  }, [checkedProjects]);

  return (
    <div ref={containerRef}>
      <button
        className={`header__action header__action--more ${isCheckedLengthZero ? 'pe-none' : ''}`}
        onClick={handleBulkTogglerOpen}
        ref={setReferenceElement}
      >
        <i className="far fa-ellipsis-v" />
      </button>
      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ContextMenu>
            <ul className="sidebar__dropdown">
              <li>
                <a href="/" onClick={handleUnarchiveProjects}>
                  <i className="fas fa-undo" />
                  {firstLetterCapital(t('return from archive'))}
                </a>
              </li>
            </ul>
          </ContextMenu>
        </div>
      )}
    </div>
  );
};
