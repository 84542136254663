import TruncateBadge from 'components/TruncateBadge/TruncateBadge';

import './MembersTruncatedList.scss';

interface IProps {
  membersLength: number;
  firstMemberName: string;
}

export default function MembersTruncatedList({membersLength, firstMemberName}: IProps) {
  return (
    <div className="members-truncated-list">
      <p>{firstMemberName}</p>
      {membersLength >= 2 && <TruncateBadge num={membersLength - 1} />}
    </div>
  );
}
