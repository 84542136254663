import moment from 'moment';
import TimePicker from 'react-datepicker';

import {formatTimeInDateFormat} from '../Helpers/formatTimeInDateFormat';

interface IProps {
  handleChange: (value: Date) => void;
  timeFrom: string | undefined;
}

export const TimeFromComponent = ({timeFrom, handleChange}: IProps) => {
  const timeFromPlaceholder = `${moment(new Date().setMinutes(Math.ceil(new Date().getMinutes() / 30) * 30)).format(
    'HH:mm',
  )}`;

  return (
    <div className="date-dropdown__time-from">
      <TimePicker
        selected={timeFrom ? formatTimeInDateFormat(timeFrom) : undefined}
        onChange={handleChange}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="HH:mm"
        placeholderText={timeFromPlaceholder}
      />
    </div>
  );
};
