import React from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setFontSizeAC} from 'store/app/setFontSize/actionCreator';

export default function NavbarFontSizeActions() {
  const dispatch = useSetGlobalState();
  const appState = useGlobalState((state) => state.app);

  const handleFontChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const {value} = e.currentTarget;
    const root = document.querySelector<HTMLHtmlElement>(':root')!;
    root.style.fontSize = `${value}px`;

    dispatch(setFontSizeAC(Number(value)));
  };

  return (
    <>
      <button
        onClick={handleFontChange}
        value="12"
        className={`header__action header__action--small ${appState.fontSize === 12 ? 'header__action--active' : ''}`}
      >
        <span>A</span>
      </button>
      <button
        onClick={handleFontChange}
        value="14"
        className={`header__action header__action--medium ${appState.fontSize === 14 ? 'header__action--active' : ''}`}
      >
        <span>A</span>
      </button>
      <button
        onClick={handleFontChange}
        value="16"
        className={`header__action header__action--large ${appState.fontSize === 16 ? 'header__action--active' : ''}`}
      >
        <span>A</span>
      </button>
    </>
  );
}
