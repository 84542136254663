import React from 'react';

import moment from 'moment';
import TimePicker from 'react-datepicker';

import {formatTimeInDateFormat} from '../Helpers/formatTimeInDateFormat';

interface IProps {
  handleChange: (value: Date) => void;
  timeTo: string | undefined;
}

export const TimeToComponent = ({timeTo, handleChange}: IProps) => {
  const timeToPlaceholder = `${moment(new Date().setMinutes(Math.ceil(new Date().getMinutes() / 30) * 30))
    .add(30, 'minutes')
    .format('HH:mm')}`;
  return (
    <div className="date-dropdown__time-to">
      <TimePicker
        selected={timeTo ? formatTimeInDateFormat(timeTo) : undefined}
        onChange={handleChange}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="HH:mm"
        placeholderText={timeToPlaceholder}
      />
    </div>
  );
};
