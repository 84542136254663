// IN CASE GOOGLE DRIVE AND DROPBOX UPLOAD NEED TO BE ADDED SEE COMMIT
// eda5e6667f4433d41fd7db7aad065307e5077f83(https://github.com/axiomq/eprotokol-app/pull/101)
import {useCallback, useEffect, useRef, useState} from 'react';

import {Placement} from '@popperjs/core';
import useDropdown from 'hooks/useDropdown';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {uploadDocument} from 'store/documents/upload/actionCreator';
import {UploadDocumentDTO} from 'typescript/dto/UploadDocumentDTO';
import {FileSources} from 'typescript/enums/FileSources';
import {FileTypes} from 'typescript/enums/FileTypes';
import {IUploadDocument} from 'typescript/interfaces/IDocumentsActions';
import {SingleProject} from 'typescript/models/SingleProject';
import {UploadedFrom} from 'typescript/types/UploadedFrom';

import './FileChooser.scss';

interface IProps {
  toggler?: React.ReactNode;
  togglerClassName?: string;
  from?: UploadedFrom | null;
  start?: Placement;
  singleProject: SingleProject;
  noFlex?: boolean;
  offset?: [number, number];
  setIsTogglerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const FileChooser = (props: IProps) => {
  const dispatch = useSetGlobalState();

  const {
    singleProject,
    toggler = <div>toggle</div>,
    togglerClassName = '',
    from = null,
    start = 'bottom',
    noFlex = false,
    offset = [104, -25],
    setIsTogglerOpen,
  } = props;

  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: start,
    offset,
  });

  const [link, setLink] = useState<string>('');
  const [linkName, setLinkName] = useState<string>('');

  useEffect(() => {
    if (setIsTogglerOpen) setIsTogglerOpen(isOpen);
    // eslint-disable-next-line
  }, [isOpen]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const document: UploadDocumentDTO = {
        type: FileTypes.FILE,
        source: FileSources.COMPUTER,
        file: acceptedFiles[0],
        name: acceptedFiles[0].name,
      };

      const payload: IUploadDocument['payload'] = {projectId: singleProject.id, document, from};
      dispatch(uploadDocument(payload));
      toggleOpen();
    },
    // eslint-disable-next-line
    [singleProject, toggleOpen, from],
  );

  const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true});

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleChooseFile = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const uploadFile = (file: File) => {
    const document: UploadDocumentDTO = {
      type: FileTypes.FILE,
      source: FileSources.COMPUTER,
      name: file.name,
      file,
    };

    const payload: IUploadDocument['payload'] = {projectId: singleProject.id, document, from};

    dispatch(uploadDocument(payload));
    toggleOpen();
  };

  const handleAttachLink = () => {
    if (singleProject?.id) {
      const document: UploadDocumentDTO = {
        type: FileTypes.URL,
        source: FileSources.URL,
        url: link,
        name: linkName || link,
      };

      const payload: IUploadDocument['payload'] = {projectId: singleProject.id, document, from};

      dispatch(uploadDocument(payload));
      toggleOpen();
      setLink('');
      setLinkName('');
    }
  };

  return (
    <div className={`dropdown ${noFlex && 'file-chooser--no-flex'}`} ref={containerRef}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <span role="button" className={togglerClassName} onClick={handleDropdownOpen}>
          {toggler}
        </span>
      </div>
      {isOpen && (
        <div
          id="popup_document"
          className="popup file-chooser"
          {...getRootProps()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <header className="popup__header">
            <h4>{t('ADD-DOCUMENT')}</h4>
            <button className="btn-close" onClick={handleDropdownOpen}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body">
            <ul>
              <li>
                <span role="button" className="file-chooser__item-title" onClick={handleChooseFile}>
                  {t('add-from-computer')}
                </span>
                <input type="file" ref={inputRef} onChange={(e) => uploadFile(e.target.files![0])} hidden />
                <input {...getInputProps()} />
              </li>
            </ul>
          </section>
          <section className="popup__body">
            <form onSubmit={handleAttachLink}>
              <label htmlFor="">{t('add-link')}</label>
              <input
                type="text"
                placeholder={t('add-link-here-placeholder')}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
              {link && (
                <>
                  <label htmlFor="">{t('link-name')})</label>
                  <input
                    type="text"
                    placeholder={t('add-link-name-placeholder')}
                    onChange={(e) => setLinkName(e.target.value)}
                  />
                </>
              )}
              <button className={`btn ${!link ? 'btn--disabled' : ''}`} disabled={link ? false : true} type="submit">
                {t('attach')}
              </button>
            </form>
          </section>
        </div>
      )}
    </div>
  );
};

export default FileChooser;
