import {MarkAsReadUnreadPayload} from 'typescript/models/MarkAsRead';

import {NotificationActions} from '../actions';

export const markAsReadUnreadACS = (payload: MarkAsReadUnreadPayload) => {
  return {
    type: NotificationActions.MARK_AS_READ,
    payload,
  };
};
