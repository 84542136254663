import sr from 'date-fns/locale/sr-Latn';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {updateProjectACS} from 'store/project/updateProject/actionCreator';
import {IDropdownAttributes, IDropdownStyles} from 'typescript/models/Dropdown';
import {SingleProject} from 'typescript/models/SingleProject';

import {formatedDateString} from './Helpers/formatedDateString';
import {TimeFromComponent} from './TimePicker/TimeFromComponent';
import {TimeToComponent} from './TimePicker/TimeToComponent';

import './DateDropdown.scss';

interface IProps {
  styles: IDropdownStyles;
  attributes: IDropdownAttributes;
  isOpen: boolean;
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  singleProject: SingleProject;
  toggleOpen: () => void;
  date: string | undefined;
  setDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  timeFrom: string | undefined;
  setTimeFrom: React.Dispatch<React.SetStateAction<string | undefined>>;
  timeTo: string | undefined;
  setTimeTo: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const DateDropdown = (props: IProps) => {
  const dispatch = useSetGlobalState();
  const {
    date,
    setDate,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    singleProject,
    toggleOpen,
    isOpen,
    setPopperElement,
    styles,
    attributes,
  } = props;

  const {t} = useTranslation();

  const isDateNotChosen = !singleProject.date && !date;
  const timeFromPlaceholder = `${moment(new Date().setMinutes(Math.ceil(new Date().getMinutes() / 30) * 30)).format(
    'HH:mm',
  )}:00`;
  const timeToPlaceholder = `${moment(new Date().setMinutes(Math.ceil(new Date().getMinutes() / 30) * 30))
    .add(30, 'minutes')
    .format('HH:mm')}:00`;

  const dateValue = date || singleProject.date;

  const handleToggleDropdown = () => {
    toggleOpen();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleToggleDropdown();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) handleToggleDropdown();
    if (e.key === 'Enter' && !isOpen) handleToggleDropdown();
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleKeyDown,
  };

  const handleDateChange = (value: Date) => {
    setDate(value.toLocaleDateString('en-CA'));
  };

  const handleTimeFromChange = (value: Date) => {
    if (value) setTimeFrom(moment(value).format('HH:mm:ss'));
  };

  const handleTimeToChange = (value: Date) => {
    if (value) setTimeTo(moment(value).format('HH:mm:ss'));
  };

  const handleDateChanges = () => {
    if (dateValue) {
      if (!timeFrom && !timeTo) {
        const singleProjectDatePayload = {
          ...singleProject,
          date: dateValue,
          timeFrom: timeFromPlaceholder,
          timeTo: timeToPlaceholder,
        };

        dispatch(updateProjectACS(singleProjectDatePayload));
        dispatch(setSingleProjectAC(singleProjectDatePayload));
      } else if (timeFrom && timeTo) {
        dispatch(updateProjectACS({...singleProject, date: dateValue, timeFrom, timeTo}));
        dispatch(setSingleProjectAC({...singleProject, date: dateValue, timeFrom, timeTo}));
      }
      handleToggleDropdown();
    }
  };

  const displayProperDatePlaceholder = () => {
    let payload = `${formatedDateString(moment().format('YYYY-MM-DD'))}`;
    if (singleProject?.date) payload = `${formatedDateString(singleProject.date)}`;
    else if (date) payload = `${formatedDateString(date)}`;
    return payload;
  };

  const displayProperTimeFrom = () => {
    let payload;
    if (singleProject) payload = singleProject.timeFrom;
    if (timeFrom) payload = timeFrom;
    return payload;
  };

  const displayProperTimeTo = () => {
    let payload;
    if (singleProject) payload = singleProject.timeTo;
    if (timeTo) payload = timeTo;
    return payload;
  };

  const handleDisableSaveButton = () => {
    let payload = false;
    if (isDateNotChosen) payload = true;
    if ((timeFrom && !timeTo) || (timeTo && !timeFrom)) payload = true;
    return payload;
  };

  return (
    <>
      {isOpen && (
        <div
          id="popup_organizer"
          className="popup date-dropdown"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <header className="popup__header">
            <h4>{t('date and time of event')}</h4>
            <button className="btn-close" {...dropdownProps} onClick={handleToggleDropdown}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body popup__body--borderless">
            <div className="date-dropdown__date">
              <DatePicker
                className={dateValue ? 'react-datepicker__date-picked' : ''}
                selected={date ? new Date(date) : null}
                onChange={handleDateChange}
                minDate={new Date()}
                dateFormat="MMMM d, yyyy"
                placeholderText={displayProperDatePlaceholder()}
                locale={sr}
              />
            </div>
          </section>
          <div className="date-dropdown__time">
            <i className="far fa-clock" aria-hidden="true" />
            <TimeFromComponent timeFrom={displayProperTimeFrom()} handleChange={handleTimeFromChange} />
            <div className="date-dropdown__divider" />
            <TimeToComponent timeTo={displayProperTimeTo()} handleChange={handleTimeToChange} />
          </div>
          <div className="date-dropdown__actions">
            <button
              className="btn btn--primary btn--large"
              onClick={handleDateChanges}
              disabled={handleDisableSaveButton()}
            >
              {t('save')}
            </button>
            <button className="btn btn--secondary btn--large" onClick={handleToggleDropdown}>
              {t('quit')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
