import {Member} from 'typescript/models/Member';

import {UserRow} from './UserRow';

interface IProps {
  users: Member[] | null;
}

export const UsersRows = ({users}: IProps) => {
  return (
    <>
      {users?.map((user) => {
        return <UserRow user={user} key={user.id} />;
      })}
    </>
  );
};
