import React, {useEffect, useState} from 'react';

import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {getNotificationsACS} from 'store/notification/getNotifications/actionCreators';
import is404Page from 'utils/helpers/is404Page';

import {NotificationDropdown} from './NotificationDropdown';
import './Notifications.scss';

export const NavbarNotifications = () => {
  const dispatch = useSetGlobalState();
  const location = useLocation();
  const {notifications, notificationCount} = useGlobalState((state) => state.notifications);
  const isValidPage = is404Page(location.pathname);
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom',
    offset: [50, -2],
  });

  const [readMore, setReadMore] = useState(false);

  const isNotificationListBiggerThan3 = notifications.length > 3;

  const handleClick = () => {
    if (isValidPage) toggleOpen();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotificationsACS({order: 'DESC', sort: 'time'}));
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const handleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <div ref={containerRef}>
      <div
        ref={setReferenceElement}
        className={`header__action header__action${notificationCount.UNREAD > 0 ? '--alert' : ''}`}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        style={{marginRight: '0'}}
      >
        <i className="far fa-bell" />
      </div>
      {isOpen && (
        <div className="notification-popup" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <header className="popup__header notification-popup__header">
            <h4>{t('NOTIFICATIONS')}</h4>
            <button onClick={handleClick}>
              <i className="fal fa-times" />
            </button>
          </header>
          <NotificationDropdown readMore={readMore} setReadMore={setReadMore} />
          {isNotificationListBiggerThan3 && (
            <button className="notification-popup-body__read" onClick={handleReadMore}>
              {readMore ? t('read-less') : t('read-more')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
