import {useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSelectedOrganizerAC} from 'store/project/setSelectedOrganizer/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {Member} from 'typescript/models/Member';

import './AddOrganizer.scss';
import AddOrganizerListItem from './AddOrganizerListItem/AddOrganizerListItem';

export default function AddOrganizer() {
  const [showList, setShowList] = useState(false);
  const [isOrganizerSet, setIsOrganizerSet] = useState(false);
  const [search, setSearch] = useState('');
  const [organizer, setOrganizer] = useState<Member | null>(null);

  const {users} = useGlobalState((state) => state.users);
  const {user: currentUser} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();

  const dispatch = useSetGlobalState();

  const handleUserClick = (user: Member) => {
    setOrganizer(user);
    setSearch(user.name);
    setIsOrganizerSet(true);
    setShowList(false);
    return organizer;
  };

  const prepareList = () => {
    const listOfActiveUsers = users?.filter((user) => user.status === 'ACTIVE');

    if (search && !isOrganizerSet) {
      return listOfActiveUsers?.filter(
        (user) => user.name.toLowerCase().includes(search.toLowerCase()) && user.id !== currentUser?.id,
      );
    }

    return listOfActiveUsers?.filter((user) => user.id !== currentUser?.id);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsOrganizerSet(false);
    setOrganizer(null);
  };

  const handleContinue = () => {
    dispatch(setSelectedOrganizerAC(organizer));
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ORGANIZER}));
  };

  const handleQuit = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  return (
    <div className="add-organizer">
      <button className="add-organizer__close" onClick={handleQuit}>
        <i className="fal fa-times" />
      </button>
      <div className="add-organizer__header">
        <i className="far fa-exclamation-circle" />
        <h3>{t('leave-confirm-last')}</h3>
      </div>
      <div className="add-organizer__organizer">
        <div className="add-organizer__select" role="button" onClick={() => setShowList((currState) => !currState)}>
          <input type="text" placeholder={t('search-members')} value={search} onChange={handleSearch} />
          <i className="fal fa-chevron-down" />
        </div>
      </div>
      {showList && (
        <div className="add-organizer__list">
          <ul>
            {prepareList()?.map((user) => (
              <AddOrganizerListItem
                key={user.id}
                user={user}
                handleClick={handleUserClick}
                organizerId={organizer?.id ? organizer.id : null}
              />
            ))}
          </ul>
        </div>
      )}
      <div className={`add-organizer__actions ${!isOrganizerSet && 'add-organizer__actions--disabled'}`}>
        <button className="btn btn--primary" onClick={handleContinue}>
          {t('continue')}
        </button>
        <button className="btn btn--secondary" onClick={handleQuit}>
          {t('quit')}
        </button>
      </div>
    </div>
  );
}
