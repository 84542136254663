import React from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {TableHeader} from 'components/Table';
import {useGlobalState} from 'hooks/useGlobalState';
import {isItSortBy} from 'pages/tag/Helpers/isItSortBy';

import {DateComponent} from './DateComponent';

interface IProps {
  primaryTitle?: string;
  secondaryTitle?: string;
  type?: string;
  sort?: boolean;
  className?: string;
  newRow?: boolean;
  dropdown?: boolean;
  date?: boolean;
  handleSort?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  sortValue?: string;
  deleted?: boolean;
}

export const Header = (props: IProps) => {
  const {filters} = useGlobalState((state) => state.archive);
  const {projectFilters: deleteFilters} = useGlobalState((state) => state.deletedProjects);

  const {
    dropdown = false,
    newRow = false,
    className = '',
    secondaryTitle = '',
    primaryTitle = '',
    type = '',
    sort = false,
    date = false,
    handleSort,
    sortValue = '',
    deleted = false,
  } = props;

  return (
    <TableHeader className={className}>
      {!dropdown ? (
        <>
          {type === 'checkbox' ? (
            <Checkbox id="checkMain" />
          ) : (
            <span className="archive-table__sort">
              {primaryTitle}
              {newRow && <br />}
              {secondaryTitle}
              {sort && (
                <button onClick={handleSort} value={sortValue}>
                  <i
                    className={`fad ${
                      isItSortBy(deleted ? deleteFilters : filters, sortValue) ? 'fa-sort-up' : 'fa-sort-down'
                    }`}
                  />
                </button>
              )}
              {date && <DateComponent />}
            </span>
          )}
        </>
      ) : (
        <RoutesDropdown>
          <div className="routes-dropdown__icon">
            <i className={`far fa${deleted ? '-trash-alt' : '-archive'}`} aria-hidden="true" />
          </div>
        </RoutesDropdown>
      )}
    </TableHeader>
  );
};
