import React from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {SingleProject} from 'typescript/models/SingleProject';
import {showSingleProjectButton} from 'utils/helpers/showSingleProjectButton';

interface IProps {
  titleRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  singleProject: SingleProject;
}

export default function Header({titleRef, singleProject}: IProps) {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {projectFilters} = useGlobalState((state) => state.project);
  const {user} = useGlobalState((state) => state.auth);

  const shouldNotDisableStarButton = showSingleProjectButton(singleProject, user);

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (singleProject) {
      const payload = {...singleProject, title: e.target.value.trimEnd().trimStart()};
      dispatch(setSingleProjectAC(payload));
    }
  };
  const debouncedTitleChange = debounce(handleTitleChange, 1000);

  const handleFavoriteClick = () => {
    const payload = {id: singleProject.id, filters: {...projectFilters}};

    if (singleProject.favorite) dispatch(removeFavoriteProjectAC(payload));
    else dispatch(addFavoriteProjectAC(payload));
  };

  return (
    <div className="project__header">
      <div
        className={`project-star ${!shouldNotDisableStarButton ? 'disabled' : ''}`}
        role="button"
        onClick={handleFavoriteClick}
      >
        <i className={`far fa-star project__star ${singleProject?.favorite && 'project__star--starred'}`} />
      </div>
      <ReactTextareaAutosize
        ref={titleRef}
        placeholder={t('Project name')}
        defaultValue={singleProject?.title}
        onChange={debouncedTitleChange}
        disabled={!showSingleProjectButton(singleProject, user)}
        className="project__header-title"
      />
    </div>
  );
}
