import {INewUserPayload} from 'typescript/models/User';

import {UserActions} from '../actions';

export const setAddUserDataAC = (payload: INewUserPayload) => {
  return {
    type: UserActions.SET_ADD_USERS_DATA,
    payload,
  };
};
