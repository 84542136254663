import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {FileTypes} from 'typescript/enums/FileTypes';
import {IDeleteDocument, IUploadDocument} from 'typescript/interfaces/IDocumentsActions';
import {Document} from 'typescript/models/Document';
import httpClient from 'utils/httpClient/httpClient';

export function getProjectDocuments(projectId: number): AxiosPromise<Document[]> {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${projectId}/document`);
}

export function uploadProjectDocument({projectId, document}: IUploadDocument['payload']): AxiosPromise<Document> {
  const data = new FormData();

  if (document.type === FileTypes.FILE) {
    const payload = {
      name: document.name,
      type: document.type,
      source: document.source,
    };

    const json = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });

    data.append('document', json);
    data.append('file', document.file!);

    return httpClient.post(`${API.PROJECT_SERVICE.BASE}/${projectId}/document`, data);
  }

  const json = new Blob([JSON.stringify(document)], {
    type: 'application/json',
  });
  data.append('document', json);

  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/${projectId}/document`, data);
}

export function deleteProjectDocument({projectId, documentId}: IDeleteDocument['payload']) {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${projectId}/document/${documentId}`);
}

export function downloadProjectDocument(projectId: number, documentId: number) {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${projectId}/document/${documentId}/download`, {
    responseType: 'blob',
  });
}
