import {ICreateReaction} from 'typescript/interfaces/IReactionsActions';

import {ReactionsActions} from '../actions';

export function createReaction(payload: ICreateReaction['payload']): ICreateReaction {
  return {
    type: ReactionsActions.CREATE_REACTION,
    payload,
  };
}
