import {useTranslation} from 'react-i18next';
import {SingleProject} from 'typescript/models/SingleProject';

import {ListOfRemainders} from '../List/ListOfRemainders';

interface IProps {
  singleProject: SingleProject;
  setAddRemainder: React.Dispatch<React.SetStateAction<boolean>>;
  addRemainder: boolean;
}

export const Remainders = ({singleProject, setAddRemainder, addRemainder}: IProps) => {
  const {t} = useTranslation();
  return (
    <div>
      <ListOfRemainders projectId={singleProject.id} remainders={singleProject.remainders} />
      <button className="remainders-dropdown__add-remainder" onClick={() => setAddRemainder(!addRemainder)}>
        <span>+</span> {t('add-remainder')}
      </button>
    </div>
  );
};
