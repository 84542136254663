import {ISetIsReplying, ISetParentId} from 'typescript/interfaces/ICommentsActions';

import {CommentsActions} from '../actions';

export function setIsReplying(isReplying: boolean): ISetIsReplying {
  return {
    type: CommentsActions.SET_IS_REPLYING,
    payload: isReplying,
  };
}

export function setParentId(parentId: number | null): ISetParentId {
  return {
    type: CommentsActions.SET_PARENT_ID,
    payload: parentId,
  };
}
