import {GetTagsDTO} from 'typescript/dto/TagDTO';

import {TagActions} from '../actions';

export function getTagsACS(payload?: GetTagsDTO) {
  return {
    type: TagActions.GET_TAGS,
    payload,
  };
}
