import moment from 'moment';

export const displayTime = (dateString: string, t: any) => {
  const [date, time] = dateString.split(' ');

  const isToday = moment(date).isSame(moment(), 'day');
  const isYesterday = moment(date).isSame(moment().subtract(1, 'day'), 'day');
  let payload;
  if (isToday) payload = time.slice(0, -3).replace(':', '.');
  else if (isYesterday) payload = t('yesterday');
  else payload = moment(date).format('DD/MM/YYYY');
  return payload;
};
