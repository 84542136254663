import {useState} from 'react';

import InstitutionEditor from 'components/InstitutionEditor/InstitutionEditor';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useTranslation} from 'react-i18next';
import {isAdmin} from 'utils/helpers/isAdmin';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import './NavbarEditInstitution.scss';

interface IProps {
  isNotFoundPage: boolean;
}

export default function NavbarEditInstitution({isNotFoundPage}: IProps) {
  const {user} = useGlobalState((state) => state.auth);
  const {institution, logoUrl} = useGlobalState((state) => state.institution);
  const {t} = useTranslation();

  const [mouseEnter, setMouseEnter] = useState(false);

  const {
    styles: editLogoStyles,
    attributes: editLogoAtributes,
    isOpen: isEditLogoOpen,
    toggleOpen: toggleEditLogoOpen,
    setReferenceElement: setEditLogoRefElement,
    setPopperElement: setEditLogoPoperElement,
    containerRef: editLogoContainerRef,
  } = useDropdown({
    position: 'bottom-start',
    offset: [0, 5],
  });

  const handleOpenEditor = () => {
    if (!isAdmin(user!) || isNotFoundPage) return;
    toggleEditLogoOpen();
  };

  const handleMouseEnter = () => {
    if (institution && institution.name.length < 16) return;
    setMouseEnter(true);
  };

  const handleMouseLeave = () => {
    if (institution && institution.name.length < 16) return;
    setMouseEnter(false);
  };

  return (
    <div ref={editLogoContainerRef}>
      <div
        className={`header__logo navbar-edit-institution__name ${user && isAdmin(user) ? '' : 'pe-none'}`}
        role="button"
        tabIndex={0}
        onClick={handleOpenEditor}
        ref={setEditLogoRefElement}
      >
        {logoUrl && <img src={logoUrl} alt="logo" />}
        {!institution?.name && <h1>{t('institution name')}</h1>}
        {institution?.name && (
          <div className="navbar-edit-institution__wrapper">
            <h1 className="position-relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {renderProjectTitle(institution.name, 16)}

              {mouseEnter && <span className="navbar-edit-institution__name-tooltip">{institution.name}</span>}
            </h1>
          </div>
        )}
      </div>

      {isEditLogoOpen && (
        <div
          className="header__edit-institution"
          ref={setEditLogoPoperElement}
          style={editLogoStyles.popper}
          {...editLogoAtributes.popper}
        >
          <InstitutionEditor onClose={() => toggleEditLogoOpen()} />
        </div>
      )}
    </div>
  );
}
