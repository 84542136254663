import {ChangeInstitutionLogoDTO} from 'typescript/dto/InstitutionDTO';
import {IUpdateInstitutionLogo, IUpdateInstitutionName} from 'typescript/interfaces/IInstitutionActions';

import {InstitutionActions} from '../actions';

export const updateInstitutionNameAC = (payload: string): IUpdateInstitutionName => {
  return {
    type: InstitutionActions.UPDATE_INSTITUTION_NAME,
    payload,
  };
};

export const updateInstitutionLogoAC = (payload: ChangeInstitutionLogoDTO): IUpdateInstitutionLogo => {
  return {
    type: InstitutionActions.UPDATE_INSTITUTION_LOGO,
    payload,
  };
};
