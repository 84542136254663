import {useEffect, useState} from 'react';

import {Tag as TagComponent} from 'components/Tag/Tag';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setCheckedTagsProjectAC} from 'store/project/setCheckedTags/actionCreator';
import {setProjectFilters} from 'store/project/setProjectFilters/actionCreator';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';

import './DashboardTagFilter.scss';

export const DashboardTagFilter = () => {
  const dispatch = useSetGlobalState();

  const {tagDetails} = useGlobalState((state) => state.tag);
  const {projectFilters, checkedTags} = useGlobalState((state) => state.project);
  const [checked, setChecked] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getTagDetailsACS({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setChecked(checkedTags);
    // eslint-disable-next-line
  }, [checkedTags]);

  const handleCheckUncheckTag = (tagId: number) => {
    let checkedTagsPayload: number[] = [];
    if (checkedTags.includes(tagId)) checkedTagsPayload = checkedTags.filter((tag) => tag !== tagId);
    else checkedTagsPayload = [...checkedTags, tagId];

    dispatch(setCheckedTagsProjectAC(checkedTagsPayload));

    const tagIds = checkedTagsPayload.map(String).join(',');
    const payload = {...projectFilters, tagIds};
    if (tagIds.length) dispatch(setProjectFilters(payload));
    else {
      const {tagIds: tags, ...newPayload} = payload;
      dispatch(setProjectFilters(newPayload));
    }
  };

  return (
    <div id="popup_organizer" className="popup tag-list">
      <section className="popup__body popup__body--borderless  tag-list__tags">
        <ul className="popup__select">
          {tagDetails.map((tag) => {
            return (
              <li key={`uid-${tag.id}`}>
                <div
                  className="d-flex dashboard-tag-filter"
                  tabIndex={0}
                  role="button"
                  onClick={() => handleCheckUncheckTag(tag.id)}
                >
                  <TagComponent variant={tag.color}>
                    <span className="first-letter-capital">{tag.name}</span>
                  </TagComponent>
                  {checked.includes(tag.id) && (
                    <span className="popup__select__check">
                      <i className="far fa-check" />
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};
