import {useEffect, useState} from 'react';

import {Placement} from '@popperjs/core';
import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import {Pagination} from 'components/Pagination/Pagination';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from 'components/Table';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import './DashboardTable.scss';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {setProjectFilters} from 'store/project/setProjectFilters/actionCreator';
import {isAdmin} from 'utils/getUserRole';
import isMobileView from 'utils/helpers/isMobileView';
import {showPagination} from 'utils/helpers/showPagination';

import DashboardRows from './DashboardRows/DashboardRows';
import {DashboardTagFilter} from './DashboardTagFilter';
import {addProjectFilters} from './Helpers/addFilter';
import {isItSortBy} from './Helpers/isItSortBy';

export default function DashboardTable() {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  let offset: [number, number] = [30, 12];
  let position: Placement = 'bottom';
  if (isMobileView()) {
    position = 'bottom-end';
    offset = [0, 0];
  }

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} =
    useDropdown<HTMLTableCellElement>({
      position,
      offset,
    });

  const {projects, projectFilters, pagination} = useGlobalState((state) => state.project);
  const cloneOfProjectFilters = cloneDeep(projectFilters);
  const {user} = useGlobalState((state) => state.auth);

  const [showTagModal, setShowTagModal] = useState(false);
  const [showAllProjectsModal, setShowAllProjectsModal] = useState(false);
  const [adminFilterChecked, setAdminFilterChecked] = useState(false);

  const hasProjectsList = projects.length > 0;
  const isItSortByFavorite = cloneOfProjectFilters.sort === 'favorite' && cloneOfProjectFilters.order === 'DESC';
  const listOfFavoriteProjects = projects.filter((project) => project.favorite);

  useEffect(() => {
    return () => {
      dispatch(setProjectFilters({page: 0, size: 15, sort: 'updatedOn', order: 'DESC'}));
    };
    // eslint-disable-next-line
  }, []);

  const handleSort = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {value} = e.currentTarget;
    const cantSortByFavorite = value === 'favorite' && !listOfFavoriteProjects.length;
    if (cantSortByFavorite) return;

    let payload = addProjectFilters(cloneOfProjectFilters, value);
    if (isItSortByFavorite) payload = {...cloneOfProjectFilters, active: true, order: 'DESC', sort: 'updatedOn'};

    dispatch(setProjectFilters(payload));
  };

  const handlePageChange = (e: React.MouseEvent, value: 'increment' | 'decrement') => {
    e.stopPropagation();
    let payload;
    let page = cloneOfProjectFilters.page!;
    const isValueIncrement = value === 'increment';
    if (isValueIncrement) payload = {...cloneOfProjectFilters, page: (page += 1)};
    else payload = {...cloneOfProjectFilters, page: (page -= 1)};
    dispatch(setProjectFilters(payload));
  };

  useEffect(() => {
    return () => {
      dispatch(setProjectFilters({page: 0, size: 15, sort: 'updatedOn', order: 'DESC', own: true}));
    };
    // eslint-disable-next-line
  }, []);

  const handleAdminFilterProjects = () => {
    setAdminFilterChecked(!adminFilterChecked);
    let payload = cloneOfProjectFilters;

    if (cloneOfProjectFilters.own) payload = {...cloneOfProjectFilters, own: false};
    else payload = {...cloneOfProjectFilters, own: true};

    dispatch(setProjectFilters(payload));
  };

  const showTagModalOnHover = () => {
    if (isOpen) setShowTagModal(false);
    else setShowTagModal(true);
  };
  const hideTagModalOnMouseLeave = () => {
    setShowTagModal(false);
  };
  const handleShowTagModal = () => {
    setShowTagModal(false);
    toggleOpen();
  };

  return (
    <div>
      <div className="dashboard-table__responsive">
        <Table className="dashboard-table">
          <TableHead>
            <TableRow>
              <TableHeader
                className={`dashboard-table__header-star dashboard-table__header-star${
                  isItSortByFavorite ? '--active' : ''
                }`}
              >
                <button className="btn btn--clear" value="favorite" onClick={handleSort}>
                  <i className="far fa-star" />
                </button>
              </TableHeader>
              <TableHeader
                className={`dashboard-table__project-header ${
                  user && !isAdmin(user) ? 'dashboard-table__project-header--user' : ''
                }`}
              >
                <span className="dashboard-table__sort">
                  {t('projects')}
                  <button className="btn btn--clear" value="title" onClick={handleSort}>
                    <i
                      className={`fad ${isItSortBy(cloneOfProjectFilters, 'title') ? 'fa-sort-up' : 'fa-sort-down'}`}
                    />
                  </button>
                </span>
              </TableHeader>
              {user && isAdmin(user) && (
                <TableHeader className="dashboard-table__checkbox ">
                  <div className="dashboard-table__checkbox-container">
                    <div
                      className="dashboard-table__checkbox position-relative"
                      onMouseEnter={() => setShowAllProjectsModal(true)}
                      onMouseLeave={() => setShowAllProjectsModal(false)}
                    >
                      <Checkbox
                        id="adminCheck"
                        onChange={handleAdminFilterProjects}
                        checked={adminFilterChecked}
                        className="checkbox"
                      />
                      {showAllProjectsModal && (
                        <span className="dashboard-table__tooltip-checkbox">{t('mark-projects')}</span>
                      )}
                    </div>
                    <p>{t('all-projects')}</p>
                  </div>
                </TableHeader>
              )}

              <TableHeader className="dashboard-table__date-header">
                <span className="dashboard-table__sort">
                  {t('date')}
                  <br /> {t('event')}
                  <button className="btn btn--clear" value="date" onClick={handleSort}>
                    <i className={`fad ${isItSortBy(cloneOfProjectFilters, 'date') ? 'fa-sort-up' : 'fa-sort-down'}`} />
                  </button>
                </span>
              </TableHeader>
              <TableHeader className="dashboard-table__tag-header">
                <div ref={containerRef}>
                  {t('tag')}
                  <div ref={setReferenceElement} className="position-relative">
                    <button
                      onClick={handleShowTagModal}
                      onMouseEnter={showTagModalOnHover}
                      onMouseLeave={hideTagModalOnMouseLeave}
                    >
                      <i className={`fa${isOpen ? 's' : 'r'} fa-filter`} />
                    </button>
                    {showTagModal && <span className="table__tag-tooltip">{t('search-by-tags')}</span>}
                  </div>
                  {isOpen && (
                    <div
                      id="popup_tag-filter"
                      ref={setPopperElement}
                      style={styles.popper}
                      {...attributes.popper}
                      className="tag-dropdown"
                    >
                      <DashboardTagFilter />
                    </div>
                  )}
                </div>
              </TableHeader>
              <TableHeader className="dashboard-table__organizers-header">
                <span className="dashboard-table__sort">{t('organizer')}</span>
              </TableHeader>
              <TableHeader className="dashboard-table__members-header">{t('member')}</TableHeader>
              <TableHeader className="dashboard-table__documents-header">{t('documents')}</TableHeader>
              <TableHeader className="dashboard-table__time-header">
                <span className="dashboard-table__sort">
                  {t('time')}
                  <button className="btn btn--clear" value="updatedOn" onClick={handleSort}>
                    <i
                      className={`fad ${
                        isItSortBy(cloneOfProjectFilters, 'updatedOn') ? 'fa-sort-up' : 'fa-sort-down'
                      }`}
                    />
                  </button>
                </span>
              </TableHeader>
              <TableHeader>
                <RoutesDropdown>
                  <div className="routes-dropdown__icon">
                    <i className="far fa-credit-card-blank" aria-hidden="true" />
                  </div>
                </RoutesDropdown>
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasProjectsList && <DashboardRows />}
            {!hasProjectsList && (
              <TableRow className="dashboard-table__zero-state">
                <TableCell colSpan={42}>
                  <div>
                    <Icons icon="zeroState" />
                    <h3 className="first-letter-capital">{t('currently there are no created projects')}</h3>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {showPagination({list: projects, filters: projectFilters}) && (
        <Pagination last={pagination.last} first={pagination.first} handleClick={handlePageChange} />
      )}
    </div>
  );
}
