import React from 'react';

import TimePicker from 'react-datepicker';

interface IProps {
  timeStamp: Date | null;
  setTimeStamp: React.Dispatch<React.SetStateAction<Date | null>>;
}

export const TimePickerComponent = ({setTimeStamp, timeStamp}: IProps) => {
  return (
    <div className="remainder-time">
      <TimePicker
        selected={timeStamp ? timeStamp : null}
        onChange={(date: Date) => setTimeStamp(date)}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="HH:mm"
      />
    </div>
  );
};
