import {useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {getSingleProject} from 'services/projectService';
import {createTag, updateTag} from 'services/tagService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {setTagDetailsRedux} from 'store/tag/getTagDetails/actionCreators';
import {setDeletedTagId} from 'store/tag/setDeletedTagId/actionCreator';
import {setTagsAC} from 'store/tag/setTags/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {Tag} from 'typescript/models/Tag';

import './SingleTag.scss';

interface IProps {
  handleCreate?: () => void;
  handleEdit?: () => void;
  onClick: (e: React.MouseEvent) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
  tag: Tag | null;
  isInTagRoute?: boolean;
}

export default function SingleTag({isInTagRoute, onClick, handleEdit, onKeyDown, handleCreate, tag}: IProps) {
  const colors = [
    ['#b7b1dd', '#aaeff9', '#d7f8c3', '#ffd474', '#f0cbff', '#ffbe82'],
    ['#feb8e5', '#ff6363', '#a8f3d4', '#ffd4d4', '#fff6a6', '#bad4fa'],
  ];

  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {tags, tagDetails} = useGlobalState((state) => state.tag);
  const {singleProject} = useGlobalState((state) => state.project);
  const [color, setColor] = useState<string>(tag ? tag.color : colors[0][0]);
  const [name, setName] = useState<string>(tag ? tag.name : '');
  const canDelete = handleEdit;

  const handleCreateTag = async () => {
    const dto = {color, name: name.trimEnd().trimStart()};

    const response = await createTag(dto);
    const payload = [...tags, response.data];
    dispatch(setTagsAC(payload));

    if (isInTagRoute) {
      dispatch(setGlobalNotification({icon: 'fa-tags', message: 'Tag successfully created', variant: 'success'}));
    }

    if (handleCreate) handleCreate();
  };

  const handleUpdateTag = async (item: Tag) => {
    const dto = {color, name: name.trimEnd().trimStart()};

    const response = await updateTag(item.id, dto);
    const updatedTag = response.data;

    const tagDetailsCopy = cloneDeep(tagDetails);

    const payload = tagDetailsCopy.map((x) => {
      if (x.id === updatedTag.id) {
        x.color = updatedTag.color;
        x.name = updatedTag.name;
      }

      return x;
    });

    dispatch(setTagDetailsRedux(payload));

    if (singleProject?.id) {
      const res = await getSingleProject(singleProject.id);
      dispatch(setSingleProjectAC(res.data));
    }

    if (handleEdit) handleEdit();
  };

  const handleSetColorName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNameLongerThan20 = e.target.value.length > 20;
    if (isNameLongerThan20) return;
    setName(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNameLongerThan20 = e.target.value.length > 20;
    if (isNameLongerThan20) return;
    setColor(e.target.value);
  };

  const handleDeleteTag = () => {
    if (tag?.id) {
      dispatch(setDeletedTagId(tag.id));
      dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_SINGLE_TAG_DELETE}));
    }
  };

  return (
    <div id="popup_badge_change" className="popup">
      <header className="popup__header">
        <h4>{tag?.id ? `${t('change tag')}` : `${t('make new tag')}`}</h4>
        <button className="btn-close" onClick={onClick} onKeyDown={onKeyDown}>
          <i className="fal fa-times" />
        </button>
      </header>
      <section className="popup__body popup__body--borderless">
        <div className="popup-body__name ">
          <label className="popup-body__tag-label first-letter-capital" htmlFor="badge_name">
            {t('tag name')}
          </label>
          <input
            type="text"
            id="badge_name"
            value={name}
            placeholder={t('Enter tag name')}
            onChange={handleSetColorName}
          />
          <label className="first-letter-capital">{t('choose tag color')}</label>
        </div>
        {colors.map((colorArr) => {
          return (
            <div className="custom__row" key={colorArr[0]}>
              {colorArr.map((clr) => {
                return (
                  <div className="custom__col" key={clr}>
                    <div className="badge">
                      <label className="badge__label" style={{background: clr}} htmlFor={clr}>
                        {clr === color && <i className="far fa-check" />}
                        <input
                          className="badge__input"
                          id={clr}
                          type="radio"
                          name="color"
                          value={clr}
                          onChange={handleNameChange}
                        />
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        <div className="btn__wrap single-tag__actions">
          <button
            disabled={!name.length}
            data-primary
            onClick={() => {
              if (!tag) {
                handleCreateTag();
              } else if (tag) {
                handleUpdateTag(tag);
              }
            }}
          >
            <span className="first-letter-capital">{t('save')}</span>
          </button>
          <button data-secondary onClick={onClick}>
            <span className="first-letter-capital">{t('quit')}</span>
          </button>
        </div>
        {canDelete && (
          <div className="btn__wrap btn__wrap-delete">
            <button className="delete-tag first-letter-capital" onClick={handleDeleteTag}>
              {t('delete-tag')}
            </button>
          </div>
        )}
      </section>
    </div>
  );
}
