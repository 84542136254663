import React from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import SingleTag from 'components/Modal/Project/SingleTag/SingleTag.';
import {RoutesDropdown} from 'components/RoutesDropdown/RoutesDropdown';
import {TableHead, TableRow, TableHeader as Th} from 'components/Table';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';
import {getTagDetailsACS} from 'store/tag/getTagDetails/actionCreators';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';
import {setTagFiltersAC} from 'store/tag/setTagFilters/actionCreator';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagDetail} from 'typescript/models/TagDetails';

import {addTagFilters} from '../Helpers/addTagFilters';
import {isItSortBy} from '../Helpers/isItSortBy';
import {TagDropdown} from './TagDropdown';

interface IProps {
  filters: GetTagDetailsDTO;
  listOfTags: TagDetail[];
}

export const TableHeader = ({filters, listOfTags}: IProps) => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const cloneOfTagFilters = cloneDeep(filters);
  const listOfTagIds = listOfTags.map((tag) => tag.id);

  const {checkedTags} = useGlobalState((state) => state.tag);

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    offset: [-5, 5],
    position: 'bottom-end',
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) toggleOpen();
    if (e.key === 'Enter' && !isOpen) toggleOpen();
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleOnKeyDown,
  };

  const sortBy = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {value} = e.currentTarget;
    const sortPayload = {filters: cloneOfTagFilters, sortType: value};
    const payload = addTagFilters(sortPayload);

    dispatch(setTagFiltersAC(payload));
  };

  const handleTagCreate = () => {
    toggleOpen();
    dispatch(getTagDetailsACS(filters));
  };

  const handleCheckAll = () => {
    let checkedPayload: number[] = [];
    if (!checkedTags.length) checkedPayload = [...listOfTagIds];
    dispatch(setCheckedTagsAC(checkedPayload));
  };

  return (
    <TableHead className="tag-table__head">
      <TableRow>
        <Th className="tag-table__checkbox">
          <Checkbox
            id="checkMainMobile"
            onChange={handleCheckAll}
            checked={Boolean(checkedTags.length)}
            className="checkbox"
          />
        </Th>
        <Th className="tag-table__dropdown-header">
          <TagDropdown />
        </Th>
        <Th className="tag-table__tags-header">
          <span>{t('tags')}</span>
          <button value="name" onClick={sortBy}>
            <i className={`fad ${isItSortBy(cloneOfTagFilters, 'name') ? 'fa-sort-up' : 'fa-sort-down'}`} />
          </button>
        </Th>
        <Th className="tag-table__project-header">
          <span className="">{t('projects')}</span>
          <button value="numberOfProject" onClick={sortBy}>
            <i className={`fad ${isItSortBy(cloneOfTagFilters, 'numberOfProject') ? 'fa-sort-up' : 'fa-sort-down'}`} />
          </button>
        </Th>
        <Th className="tag-table__add-icon-header">
          <div ref={containerRef} className={`${listOfTags.length >= 100 ? 'pe-none' : ''}`}>
            <button
              ref={setReferenceElement}
              className={`tag-table__icon ${isOpen ? 'tag-table__icon--open' : ''}`}
              onClick={() => toggleOpen()}
            >
              <i className="far fa-tag" />
            </button>
            {isOpen && (
              <div
                ref={setPopperElement}
                className="project__tags-overview"
                style={styles.popper}
                {...attributes.popper}
              >
                <SingleTag handleCreate={handleTagCreate} {...dropdownProps} tag={null} isInTagRoute />
              </div>
            )}
          </div>
        </Th>
        <Th className="tag-table__menu-header">
          <RoutesDropdown>
            <div className="routes-dropdown__icon">
              <i className="far fa-tags" aria-hidden="true" />
            </div>
          </RoutesDropdown>
        </Th>
      </TableRow>
    </TableHead>
  );
};
