import Checkbox from 'components/Checkbox/Checkbox';
import SingleTag from 'components/Modal/Project/SingleTag/SingleTag.';
import {Tag} from 'components/Tag/Tag';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setCheckedTagsAC} from 'store/tag/setCheckedTags/actionCreator';
import {TagDetail} from 'typescript/models/TagDetails';
import isSmallScreen from 'utils/helpers/isSmallScreen';

interface IProps {
  tag: TagDetail;
}

export default function MobileTableRow({tag}: IProps) {
  const {checkedTags} = useGlobalState((state) => state.tag);

  const dispatch = useSetGlobalState();

  let offset: [number, number] = [50, -70];
  if (isSmallScreen()) offset = [50, -30];

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset,
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' && isOpen) toggleOpen();
    if (e.key === 'Enter' && !isOpen) toggleOpen();
  };

  const dropdownProps: {onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void} = {
    onClick: handleClick,
    onKeyDown: handleOnKeyDown,
  };

  const handleEdit = () => {
    toggleOpen();
  };

  const handleCheck = (value: number) => {
    const checkedTagId = value;

    let checkedPayload: number[] = [];
    if (checkedTags.includes(checkedTagId)) {
      const filteredProjects = checkedTags.filter((checkedTag) => checkedTag !== checkedTagId);
      checkedPayload = filteredProjects;
    } else {
      checkedPayload = [...checkedTags, checkedTagId];
    }
    dispatch(setCheckedTagsAC(checkedPayload));
  };

  return (
    <div className="grid__row" key={tag.id} role="button" onClick={() => {}}>
      <div className="grid__check" role="button" onClick={(e) => e.stopPropagation()}>
        <Checkbox id={`${tag.id}`} onChange={handleCheck} checked={checkedTags.includes(tag.id)} className="checkbox" />
      </div>
      <div className="grid__content" style={{flexDirection: 'row'}}>
        <div className="tag-table__mobile-tag">
          <Tag variant={tag.color}>{tag.name}</Tag>
        </div>
        <div ref={containerRef}>
          <button onClick={handleEdit} className="tag-table__row-icon-edit" ref={setReferenceElement}>
            <i className="far fa-pencil" />
          </button>
          <div
            ref={setPopperElement}
            className="project__tags-overview tag-table__popup"
            style={styles.popper}
            {...attributes.popper}
          >
            {isOpen && <SingleTag handleEdit={handleEdit} {...dropdownProps} tag={tag} />}
          </div>
        </div>
      </div>
      <div className="grid__document tag-table__mobile-project-count">
        <i className="far fa-credit-card-blank" />
        <span>{tag.numberOfProject}</span>
      </div>
    </div>
  );
}
