import {useTranslation} from 'react-i18next';
import {MemberStatus} from 'typescript/models/Member';

interface IProps {
  displayTooltip: boolean;
  userStatus: MemberStatus;
  setDisplayTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  isUserLastInTheList: boolean;
}

export const UserRowStatus = (props: IProps) => {
  const {displayTooltip, isUserLastInTheList, userStatus, setDisplayTooltip} = props;
  const {t} = useTranslation();

  return (
    <div
      className={`dashboard-table__status dashboard-table__status${displayTooltip ? '--tooltip' : ''}`}
      onMouseEnter={() => setDisplayTooltip(true)}
      onMouseLeave={() => setDisplayTooltip(false)}
    >
      <i className="fal fa-exclamation-triangle" aria-hidden="true" />
      {displayTooltip && <span style={{bottom: isUserLastInTheList ? '20px' : ''}}>{t(userStatus)}</span>}
    </div>
  );
};
