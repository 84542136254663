import {Routes} from 'constants/routes';

import {useState} from 'react';
import './AddProject.scss';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {createNewProjectACS} from 'store/project/createNewProject/actionCreator';
import {CreateProjectDTO} from 'typescript/dto/createProjectDTO';
import {CreateProjectPayload} from 'typescript/models/CreateProjectPayload';

interface IProps {
  onClose: () => void;
}

export default function AddProject({onClose}: IProps) {
  const dispatch = useSetGlobalState();
  const location = useLocation();
  const history = useHistory();

  const {t} = useTranslation();

  const {projectFilters} = useGlobalState((state) => state.project);
  const [projectTitle, setProjectTitle] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');

  const isNotInDashboard = location.pathname !== Routes.DASHBOARD;

  const handleSubmit = () => {
    if (!projectTitle || !projectDescription) return;

    const dto: CreateProjectDTO = {
      title: projectTitle,
      description: projectDescription,
    };

    const payload: CreateProjectPayload = {dto, filters: projectFilters};

    dispatch(createNewProjectACS(payload));
    if (isNotInDashboard) history.push(Routes.DASHBOARD);
    onClose();
  };

  return (
    <div className="add-project">
      <input
        value={projectTitle}
        onChange={(e) => setProjectTitle(e.target.value)}
        className="add-project__title"
        type="text"
        placeholder={t('project-title')}
      />
      <textarea
        value={projectDescription}
        onChange={(e) => setProjectDescription(e.target.value)}
        className="add-project__description"
        rows={4}
        cols={37}
        placeholder={`${t('project-description')}...`}
      />
      <div className="add-project__actions">
        <button className="add-project__submit" onClick={handleSubmit}>
          <span className="first-letter-capital">{t('save')}</span>
        </button>
        <button className="add-project__close" onClick={onClose}>
          <i className="fal fa-times" />
        </button>
      </div>
    </div>
  );
}
