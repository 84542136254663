import React, {useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {SingleProject} from 'typescript/models/SingleProject';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';
import {isUserOrganizer} from 'utils/helpers/isUserOrganizator';

import './Description.scss';

interface IProps {
  errorMessage: boolean;
  setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  singleProject: SingleProject;
}

export default function Description({errorMessage, setErrorMessage, descriptionRef, singleProject}: IProps) {
  const [edit, setEdit] = useState<boolean>(false);
  const [editText, setEditText] = useState(singleProject?.description);

  const dispatch = useSetGlobalState();

  const {user} = useGlobalState((state) => state.auth);
  const {t} = useTranslation();

  const handleEdit = () => {
    setErrorMessage(false);
    setEdit(!edit);
  };

  const handleEditProject = () => {
    if (!editText) {
      setErrorMessage(true);
      return;
    }

    dispatch(setSingleProjectAC({...singleProject, description: editText}));
    handleEdit();
  };

  const handleCancel = () => {
    setEditText(singleProject.description);
    handleEdit();
  };

  return (
    <>
      <div className="project__title project__title--main">
        <span className="d-none d-md-block">
          <i className="far fa-align-left" />
        </span>
        <h3>{t('description')}</h3>
        {isNotArchivedOrDeleted(singleProject) && isUserOrganizer(singleProject, user) && (
          <button className={`project__title-more${edit ? ' project__title-more--open' : ''}`} onClick={handleCancel}>
            <i className="far fa-pen" />
          </button>
        )}
      </div>
      <div className="project__inner mb-md-25 project__description-text">
        {edit ? (
          <div className="description-edit-wrapper">
            <TextareaAutosize
              ref={descriptionRef}
              className="description-edit"
              defaultValue={editText}
              onChange={(e) => setEditText(e.target.value)}
              minRows={1}
              maxRows={4}
            />
            <div className="project-description__actions">
              <button className="project-description__submit" onClick={handleEditProject}>
                <span className="first-letter-capital">{t('save')}</span>
              </button>
              <button className="project-description__close" onClick={handleCancel}>
                <i className="fal fa-times" />
              </button>
            </div>
          </div>
        ) : (
          <p>{singleProject?.description}</p>
        )}
        {errorMessage && <p>Opis je obavezno polje</p>}
      </div>
    </>
  );
}
