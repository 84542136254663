import {Tag} from 'components/Tag/Tag';
import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {mediaBreakpointDown} from 'hooks/useMediaBreakpoint/media';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {markAllNotificationsAsReadACS} from 'store/notification/markAllAsReadProject/actionCreator';
import {addFavoriteProjectAC, removeFavoriteProjectAC} from 'store/project/favorites/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';
import isMobileView from 'utils/helpers/isMobileView';
import renderProjectTitle from 'utils/helpers/renderProjectTitle';

import styleDropdown from '../../Helpers/styleDropdown';
import {MembersList} from './MembersList';
import {OrganizersList} from './OrganizersList';

interface IProps {
  singleDay: SingleProject;
  currentlySelectedDay: moment.Moment;
  isFirstWeek: boolean;
}

export const SingleDay = ({currentlySelectedDay, singleDay, isFirstWeek}: IProps) => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {isDropdownOpen} = useGlobalState((state) => state.app);

  const setOffset = () => {
    let offset: [number, number] = [-180, -20];

    if (isFirstWeek) {
      offset = [-45, -20];
      return offset;
    }

    if (mediaBreakpointDown('sm').matches) {
      offset = [-150, -20];
      return offset;
    }

    if (mediaBreakpointDown('md').matches) {
      offset = [-150, -20];
      return offset;
    }

    if (mediaBreakpointDown('lg').matches) {
      offset = [-150, -20];
      return offset;
    }

    if (mediaBreakpointDown('xl').matches) {
      offset = [-150, -20];
      return offset;
    }

    return offset;
  };

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'right-start',
    offset: setOffset(),
    open: isDropdownOpen === singleDay.date ? true : false,
  });

  const {user} = useGlobalState((state) => state.auth);

  const {projectFilters} = useGlobalState((state) => state.project);

  const {notifications} = useGlobalState((state) => state.notifications);
  const matchingNotifications = notifications.filter((notification) => notification.project.id === singleDay.id);

  const isFavorite = singleDay.favorite;
  const titleCutLength = isMobileView() ? 6 : 20;

  const handleViewMore = (e: React.MouseEvent, singleProject: SingleProject) => {
    e.stopPropagation();
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(singleProject));
  };

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const payload = {id: singleDay.id, filters: {}, day: singleDay.date};
    if (isFavorite) dispatch(removeFavoriteProjectAC(payload));
    else dispatch(addFavoriteProjectAC(payload));
  };

  const handleReadAllNotifications = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!matchingNotifications.length) return;
    if (user) {
      const payload = {dto: {projectId: singleDay.id, userId: user.id}, filters: projectFilters};
      dispatch(markAllNotificationsAsReadACS(payload));
    }
  };

  const renderTags = (tags: SingleProject['tags']) => {
    if (tags.length <= 3) {
      return tags.map((tag) => (
        <div className="calendar-dropdown__tag" key={`project-tag-${tag.id}`}>
          <Tag variant={tag.color}>{tag.name}</Tag>
        </div>
      ));
    }

    return (
      <>
        {tags.slice(0, 2).map((tag) => (
          <div className="calendar-dropdown__tag" key={`project-tag-${tag.id}`}>
            <Tag variant={tag.color}>{tag.name}</Tag>
          </div>
        ))}
        <TruncateBadge num={tags.length - 2} />
      </>
    );
  };

  return (
    <div className="dropdown" ref={containerRef}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <button
          className={`calendar__event ${isFavorite && 'event-info__star--starred'}`}
          onClick={(e) => handleDropdownOpen(e)}
        >
          <i className="far fa-star" />
          <span className={`${styleDropdown(currentlySelectedDay, singleDay.date)}`}>
            {renderProjectTitle(singleDay.title, titleCutLength)}
          </span>
        </button>
      </div>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {isOpen && (
          <div className="calendar-dropdown">
            <div className="calendar-dropdown__header">
              <div
                className={`event-info__star ${isFavorite && 'event-info__star--starred'}`}
                role="button"
                onClick={handleFavoriteClick}
              >
                <i className="far fa-star" />
              </div>
              <div className="event-info">
                <div className="event-info__comment">
                  <i className="fal fa-paperclip" />
                  <span>{singleDay.documents.length}</span>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleReadAllNotifications}
                  className={`event-info__notification ${
                    singleDay.unreadNotifications ? 'event-info__notification--active' : ''
                  }`}
                >
                  <i className="fal fa-bell" />
                </div>
              </div>
            </div>
            <div className="calendar-dropdown__content">
              <p>{renderProjectTitle(singleDay.title, 60)}</p>
              <div className="calendar-dropdown__members">
                <h5>{t('organizer')}</h5>
                <OrganizersList organizersList={singleDay.organizers} />
                <h5>{t('member')}</h5>
                <MembersList membersList={singleDay.members} />
              </div>
              <div className="calendar-dropdown__tags">{renderTags(singleDay.tags)}</div>
              <button className="btn btn--primary btn--small" onClick={(e) => handleViewMore(e, singleDay)}>
                {t('See more')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
