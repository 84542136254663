import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import {Member} from 'typescript/models/Member';
import cutName from 'utils/helpers/cutName';
import './ProjectPreviewMembers.scss';

interface IProps {
  members: Member[];
}

export default function ProjectPreviewMembers({members}: IProps) {
  const membersLength = members.length;
  const slicedMembers = members.slice(0, 3);
  const slicedMembersLength = slicedMembers.length;

  return (
    <div className="project-preview-members">
      {slicedMembers.map((member, index) => {
        const isLastMember = index === slicedMembersLength - 1;

        if (isLastMember && membersLength > 3) return <p>{cutName(member.name, 12)}</p>;
        if (isLastMember && membersLength <= 3) return <p>{member.name}</p>;

        return <p>{member.name}, &nbsp;</p>;
      })}
      {membersLength > 3 && (
        <div className="project-preview-members__badge">
          <TruncateBadge num={membersLength - 3} />
        </div>
      )}
    </div>
  );
}
