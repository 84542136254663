import {Routes} from 'constants/routes';

import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons/Icons';
import TableZeroState from 'components/Table/TableZeroState/TableZeroState';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {setCheckedArchiveProjectsAC} from 'store/archive/setCheckedArchiveProjects/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {SingleProject} from 'typescript/models/SingleProject';

import {Tags} from '../Tags';
import {Header} from './Header';

import './MobileTable.scss';

interface IProps {
  archivedProjects: SingleProject[];
}

export const MobileAndTabletTable = ({archivedProjects}: IProps) => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();

  const {checkedProjects} = useGlobalState((state) => state.archive);

  const archivedProjectsList = archivedProjects.length > 0;

  const checkRow = (value: number) => {
    const checkProjectId = value;

    let checkedPayload;
    if (checkedProjects.includes(checkProjectId)) {
      const filteredProjects = checkedProjects.filter((project) => project !== checkProjectId);
      checkedPayload = filteredProjects;
    } else {
      checkedPayload = [...checkedProjects, checkProjectId];
    }
    dispatch(setCheckedArchiveProjectsAC(checkedPayload));
  };

  const handleProjectClick = (project: SingleProject) => {
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
    dispatch(setSingleProjectAC(project));
  };

  return (
    <div className="grid">
      <Header archivedProjects={archivedProjects} checkedProjects={checkedProjects} />
      {archivedProjects.map((project) => {
        return (
          <div className="grid__row" key={project.id} role="button" onClick={() => handleProjectClick(project)}>
            <div className="grid__check" role="button" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                id={`${project.id}`}
                onChange={checkRow}
                checked={checkedProjects.includes(project.id)}
                className="checkbox"
              />
            </div>
            <div className="grid__content">
              <p className="archive-mobile-table__row-title">{project.title}</p>
              <span className="grid__date">{project.date}</span>
              <div className="d-flex">
                <Tags project={project} />
              </div>
            </div>
            <div className="grid__document">
              <i className="far fa-paperclip" style={{color: project.documents.length > 0 ? '#717888' : '#E5EBF1'}} />
              {project.documents.length > 0 && `(${project.documents.length})`}
            </div>
          </div>
        );
      })}
      {!archivedProjectsList && (
        <TableZeroState type="regular">
          <Icons icon="zeroState" />
          <h3 className="first-letter-capital">{t('currently there are no archived projects')}</h3>
          <p className="first-letter-capital">
            {t('if you want to archive a project, you')} <br />
            {t('can do that trough your')} <Link to={Routes.DASHBOARD}>{t('projects list')}.</Link>
          </p>
        </TableZeroState>
      )}
    </div>
  );
};
