import React from 'react';

import {Field} from 'formik';

import './Checkbox.scss';

interface IProps {
  children?: React.ReactNode;
  id: string;
  formikField?: boolean;
  name?: string;
  handleClick?: () => void;
  onChange?: (value: number) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
}
export default function Checkbox(props: IProps) {
  const {
    id,
    children,
    formikField,
    name,
    onChange = () => {},
    handleClick = () => {},
    checked = false,
    className = '',
    disabled,
  } = props;

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(+e.target.value);
  };

  return (
    <div role="button" tabIndex={0} onClick={handleClick} className={className}>
      {formikField ? (
        <Field type="checkbox" name={name} id={`${id}`} />
      ) : (
        <input type="checkbox" id={id} value={id} onChange={handleCheck} checked={checked} disabled={disabled} />
      )}
      <label htmlFor={id} onClick={(e) => e.stopPropagation()}>
        <span>{children}</span>
      </label>
    </div>
  );
}
