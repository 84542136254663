import {NotificationContent} from 'typescript/models/Notifications';

export const displayProperIconText = (notification: NotificationContent) => {
  const payload: {icon: string; notification: string | null} = {icon: '', notification: ''};

  switch (notification.type) {
    case 'TIME':
      payload.icon = 'fa fa-clock';
      payload.notification = 'remainder';
      break;
    case 'DELETE_PROJECT':
      payload.icon = 'fa fa-trash-alt';
      payload.notification = 'project_is_deleted';
      break;
    case 'DOCUMENT':
      payload.icon = 'fa fa-paperclip';
      payload.notification = 'new_document_added';
      break;
    case 'ACTIVATED_ARCHIVE':
      payload.icon = 'fa fa-arrow-rotate-left';
      payload.notification = 'project_was_returned_from_archive';
      break;
    case 'ADD_MEMBER':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'you_are_added_as_member';
      break;
    case 'REMOVE_MEMBER':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'you_are_removed_as_member';
      break;
    case 'ADD_ORGANIZER':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'you_are_added_as_organizer';
      break;
    case 'REMOVE_ORGANIZER':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'you_are_removed_as_organizer';
      break;
    case 'LEAVE_PROJECT':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'project_activated';
      break;
    case 'ACTIVATED_DELETED':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'project_was_returned_from_delete';
      break;
    case 'ACTIVATED':
      payload.icon = 'fa fa-credit-card-blank';
      payload.notification = 'project_activated';
      break;
    case 'COMMENT': {
      payload.icon = 'fa fa-comment-lines';
      break;
    }

    default:
      payload.icon = 'fa fa-credit-card-blank';
      break;
  }

  return payload;
};
