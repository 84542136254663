import {IDisableEnableUsers} from 'typescript/models/UpdatedUser';

import {UserActions} from '../../actions';

export const enableUsersACS = (payload: IDisableEnableUsers) => {
  return {
    type: UserActions.ENABLE_USER,
    payload,
  };
};
