import TruncateBadge from 'components/TruncateBadge/TruncateBadge';
import useDropdown from 'hooks/useDropdown';
import {useTranslation} from 'react-i18next';
import {Member} from 'typescript/models/Member';
import isMobileView from 'utils/helpers/isMobileView';
import './MemberList.scss';

interface IProps {
  members: Member[];
  title?: string;
  subtitle?: string;
}

export default function MemberList({members, title = 'members', subtitle = 'group members'}: IProps) {
  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
  });

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const displayTruncatedList = () => {
    let payload = <TruncateBadge num={members.length! - 2} />;
    if (members.length === 2) payload = <></>;
    else if (isMobileView()) payload = <TruncateBadge num={members.length! - 1} />;
    return payload;
  };

  return (
    <div ref={containerRef}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <span role="button" onClick={handleDropdownOpen}>
          {displayTruncatedList()}
        </span>
      </div>
      {isOpen && (
        <div
          id="popup_organizer"
          className="popup member-list"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <header className="popup__header">
            <h4>{t(`${title}`)}</h4>
            <button className="btn-close" onClick={handleDropdownOpen}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body popup__body--borderless">
            <h3>{t(`${subtitle}`)}</h3>
            <ul className="popup__select">
              {members.map((member) => {
                return (
                  <li key={`uid-${member.id}`}>
                    <p>
                      <span>
                        {member.name}
                        <small>{member.email}</small>
                      </span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      )}
    </div>
  );
}
