/* eslint-disable global-require */
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'latin',
  lng: 'latin',
  resources: {
    cyrillic: {
      translations: require('./locales/sr-cyrillic/translations.json'),
    },
    latin: {
      translations: require('./locales/sr-latin/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['latin', 'cyrillic'];

export default i18n;
