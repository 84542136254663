function ZeroState(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={70} height={56} viewBox="0 0 70 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.89 53.375c0-.875-.437-1.64-1.093-2.078L55.89 41.125V15.094c0-1.86-.766-3.61-2.078-4.922l-8.204-8.203C44.406.766 42.656 0 40.797 0H21c-3.938 0-7 3.172-7 7v1.313L4.156.655A2.16 2.16 0 002.625 0C1.094 0 0 1.313 0 2.625c0 .766.328 1.531.984 1.969l64.641 50.75c.547.547 1.094.656 1.64.656 1.532 0 2.626-1.203 2.626-2.625zM50.75 37.078L19.25 12.36V7.11c0-.984.875-1.75 1.64-1.75h17.5v8.75c0 1.86 1.641 3.391 3.5 3.391h8.86v19.578zM49 50.75H21c-.984 0-1.75-.875-1.75-1.75V25.703L14 21.547V49c0 3.828 3.063 7 6.89 7H49c2.188 0 4.156-1.094 5.469-2.734l-4.157-3.282c-.328.547-.765.766-1.312.766z"
        fill="#CDE4F2"
      />
    </svg>
  );
}

export default ZeroState;
