import React, {useState} from 'react';

import EmojiPicker from 'components/EmojiPicker/EmojiPicker';
import {EmojiData} from 'emoji-mart';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {createReaction} from 'store/reactions/add/actionCreator';
import {CreateReactionDTO} from 'typescript/dto/CreateReactionDTO';
import {Reaction} from 'typescript/models/Reaction';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';

interface IProps {
  commentId: number;
  reactions: Reaction[];
}

const AddReaction = ({commentId, reactions}: IProps) => {
  const dispatch = useSetGlobalState();

  const {singleProject} = useGlobalState((state) => state.project);
  const {user} = useGlobalState((state) => state.auth);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const isAlreadyAdded = (emoji: EmojiData) => {
    let result = false;

    reactions?.forEach((reaction) => {
      if (reaction.text === emoji.id && reaction.user.id === user!.id) {
        result = true;
      }
    });

    return result;
  };

  const handleAddReaction = (emoji: EmojiData) => {
    if (!isNotArchivedOrDeleted(singleProject)) return;
    if (isAlreadyAdded(emoji)) {
      setShowEmojiPicker(false);
      return;
    }

    if (singleProject?.id) {
      const data: CreateReactionDTO = {
        text: emoji.id!,
      };

      const payload = {data, projectId: singleProject.id, commentId};

      dispatch(createReaction(payload));
      setShowEmojiPicker(false);
    }
  };

  const showEmojies = () => {
    setShowEmojiPicker((currState) => !currState);
  };

  return (
    <div className="position-relative">
      <span
        className={`project__reactions__add ${isNotArchivedOrDeleted(singleProject) ? '' : 'project__item-disabled'}`}
        role="button"
        onClick={() => (isNotArchivedOrDeleted(singleProject) ? showEmojies() : {})}
      >
        <img src="/images/reaction.png" alt="" />
      </span>
      {showEmojiPicker && <EmojiPicker onSelect={(emoji: EmojiData) => handleAddReaction(emoji)} />}
    </div>
  );
};

export default AddReaction;
