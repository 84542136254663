import {SingleProject} from 'typescript/models/SingleProject';
import {User} from 'typescript/models/User';
import {isAdmin} from 'utils/getUserRole';

import {isNotArchivedOrDeleted} from './isNotArchivedOrDeleted';
import {isUserOrganizer} from './isUserOrganizator';

export const showSingleProjectButton = (singleProject: SingleProject | null, user: User | null) => {
  let payload = false;
  if (isNotArchivedOrDeleted(singleProject) && user && isAdmin(user)) payload = true;
  else if (isUserOrganizer(singleProject, user)) {
    if (isNotArchivedOrDeleted(singleProject)) payload = true;
    else payload = false;
  }

  return payload;
};
