import {Emoji} from 'emoji-mart';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {createReaction} from 'store/reactions/add/actionCreator';
import {deleteReaction} from 'store/reactions/delete/actionCreator';
import {CreateReactionDTO} from 'typescript/dto/CreateReactionDTO';
import {Reaction} from 'typescript/models/Reaction';
import {isNotArchivedOrDeleted} from 'utils/helpers/isNotArchivedOrDeleted';

interface IProps {
  emoji: string;
  reactionsList: Reaction[];
  commentId: number;
}

const SingleReaction = ({emoji, reactionsList, commentId}: IProps) => {
  const dispatch = useSetGlobalState();

  const {user} = useGlobalState((state) => state.auth);
  const {singleProject} = useGlobalState((state) => state.project);

  const reactionsNumber = reactionsList.length;

  const checkReaction = () => {
    let hasAlreadyReacted = false;
    let reactionId: number | null = null;

    reactionsList.forEach((reaction) => {
      if (reaction.user.id === user?.id) {
        hasAlreadyReacted = true;
        reactionId = reaction.id;
      }
    });

    return {hasAlreadyReacted, reactionId};
  };

  const {hasAlreadyReacted, reactionId} = checkReaction();

  const handleReactionClick = () => {
    if (!isNotArchivedOrDeleted(singleProject)) return;

    if (singleProject?.id) {
      if (hasAlreadyReacted) {
        const payload = {projectId: singleProject.id, commentId, reactionId: reactionId!};

        dispatch(deleteReaction(payload));
        return;
      }

      const data: CreateReactionDTO = {
        text: emoji,
      };

      const payload = {data, projectId: singleProject.id, commentId};

      dispatch(createReaction(payload));
    }
  };

  return (
    <div
      className={`project__reactions__reaction ${hasAlreadyReacted && 'project__reactions__reaction--active'}`}
      role="button"
      onClick={handleReactionClick}
    >
      <Emoji set="google" emoji={emoji} size={16} />
      <span>{reactionsNumber}</span>
    </div>
  );
};

export default SingleReaction;
