import 'emoji-mart/css/emoji-mart.css';
import {EmojiData, Picker} from 'emoji-mart';

interface IProps {
  onSelect: (emoji: EmojiData) => void;
  style?: React.CSSProperties | undefined;
}

const EmojiPicker = ({onSelect, style = {position: 'absolute', bottom: '20px', zIndex: 1}}: IProps) => {
  return <Picker set="google" onSelect={onSelect} style={style} />;
};

export default EmojiPicker;
