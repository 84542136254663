import * as React from 'react';
import './Button.scss';

import {ButtonType, ButtonDisabled} from 'typescript/types/ButtonTypes';

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: ButtonDisabled;
  type?: ButtonType;
  variant?: 'primary' | 'secondary';
  className?: string;
}

const Button = ({children, onClick, disabled, type, variant = 'primary', className = ''}: IProps) => {
  return (
    <button disabled={disabled} onClick={onClick} type={type} className={`button button--${variant} ${className}`}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  type: 'button',
};

export default Button;
