/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import {refreshToken} from 'services/tokenService';
import {logoutAC} from 'store/auth/logout/actionCreator';
import store from 'store/store';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

let refreshingToken: boolean = false;

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 418) {
      store.dispatch(logoutAC({}));
    }

    // Logout after retry
    if (error?.response?.status === 401 && originalRequest._retry) {
      store.dispatch(logoutAC({}));
    }

    // Refresh token and retry request
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        refreshingToken = !refreshingToken;
        if (refreshingToken) {
          const response = await refreshToken();
          refreshingToken = false;

          const newToken = response.data.accessToken;
          if (response.status !== 200) {
            throw new Error('Failed to refresh token.');
          }

          originalRequest.headers.Authorization = newToken;
          localStorage.setItem('token', newToken);
        }
      } catch (err) {
        store.dispatch(logoutAC({}));
      }

      return httpClient(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default httpClient;
