import {ISetSelectedOrganizer} from 'typescript/interfaces/IProjectActions';
import {Member} from 'typescript/models/Member';

import {ProjectActions} from '../actions';

export function setSelectedOrganizerAC(organizer: Member | null): ISetSelectedOrganizer {
  return {
    type: ProjectActions.SET_SELECTED_ORGANIZER,
    payload: organizer,
  };
}
