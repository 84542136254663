import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';
import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';
import {TagDetail} from 'typescript/models/TagDetails';

interface IProps {
  list: SingleProject[] | Member[] | TagDetail[];
  filters: IGetProjectsDTO | GetUsersDTO;
}

export const showPagination = ({list, filters}: IProps) => {
  let payload = false;
  if (list.length >= 14) payload = true;
  else if (filters && filters.page && filters.page > 0) payload = true;
  return payload;
};
