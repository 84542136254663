import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {resendInvitation} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setUserInviteIdAC} from 'store/user/setUserId/actionCreator';

export const ConfirmUserReinvite = () => {
  const dispatch = useSetGlobalState();

  const {inviteUserId, users, userFilters} = useGlobalState((state) => state.users);
  const matchingUserFromList = users?.find((user) => user.id === inviteUserId);
  const {t} = useTranslation();

  const handleModalClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  useEffect(() => {
    return () => {
      dispatch(setUserInviteIdAC(null));
    };
    // eslint-disable-next-line
  }, []);

  const handleSendInvitation = async () => {
    if (inviteUserId) {
      await resendInvitation(inviteUserId)
        .then(() => {
          dispatch(getUsersACS(userFilters));
          dispatch(toggleModalAC({isOpen: false, component: null}));
          dispatch(setGlobalNotification({variant: 'success', message: 'User invited successfully', icon: 'fa-user'}));
        })
        .catch(() => {
          dispatch(setGlobalNotification({variant: 'error', message: 'User invited unsuccessfully'}));
        });
    }
  };

  return (
    <div className="confirm-dialog">
      <button className="btn-close" onClick={handleModalClose}>
        <i className="fal fa-times" />
      </button>

      <div className="confirm-dialog__spacing">
        <div className="confirm-dialog__wrap">
          <div className="confirm-dialog--warning">
            <i className="far fa-question-circle" />
          </div>
          <div className="confirm-dialog__content">
            <h6>
              {t('confirm-to')} <b>{matchingUserFromList?.name}</b> {t('be-called')}
            </h6>
            <div className="btn__wrap">
              <button className="btn btn--primary btn--large" onClick={handleSendInvitation}>
                {t('send')}
              </button>
              <button className="btn btn--secondary btn--large" onClick={handleModalClose}>
                {t('quit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
