import {useEffect} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {updateUser} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {logoutAC} from 'store/auth/logout/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {setUserCredentialsAC} from 'store/user/setUserCredentials/actionCreator';

export const ConfirmEmailChange = () => {
  const dispatch = useSetGlobalState();
  const {t} = useTranslation();
  const {userCredentials, userFilters} = useGlobalState((state) => state.users);

  const handleClose = () => {
    dispatch(toggleModalAC({isOpen: false, component: null}));
  };

  const handleCredentialsChange = async () => {
    if (userCredentials) {
      await updateUser(userCredentials)
        .then(() => {
          dispatch(getUsersACS(userFilters));
          dispatch(
            setGlobalNotification({
              message: 'Email changed successfully',
              variant: 'success',
              icon: 'fa-user',
            }),
          );
          dispatch(logoutAC({}));
        })
        .catch(() => {
          dispatch(setGlobalNotification({message: 'Email unsuccessfully changed', variant: 'error'}));
        });
    }
    handleClose();
  };

  useEffect(() => {
    return () => {
      dispatch(setUserCredentialsAC(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="confirm-organizer">
      <button className="confirm-organizer__close" onClick={handleClose}>
        <i className="fal fa-times" />
      </button>
      <div className={`confirm-organizer__header ${userCredentials ? 'confirm-organizer__header--email-change' : ''}`}>
        <i className="far fa-exclamation-circle" />
        <div className="confirm-organizer__header confirm-organizer__description">
          <h3>{t('confirm-email-change')}</h3>
          <p>
            {t('email-change-description')} <b>{userCredentials?.dto.email}</b>
          </p>
        </div>
      </div>
      <div className="confirm-organizer__actions m-0">
        <button className="btn btn--primary" onClick={handleCredentialsChange}>
          {t('change-email')}
        </button>
        <button className="btn btn--secondary" onClick={handleClose}>
          {t('quit')}
        </button>
      </div>
    </div>
  );
};
