import {Routes} from 'constants/routes';

import React from 'react';

import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';
import './RoutesDropdown.scss';
import {isAdmin} from 'utils/helpers/isAdmin';

import {properPageOffset} from './Helpers/properPageOffset';

interface IProps {
  children: React.ReactNode;
}

export const RoutesDropdown = ({children}: IProps) => {
  const {t} = useTranslation();
  const {user} = useGlobalState((state) => state.auth);
  const location = useLocation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    position: 'bottom-start',
    offset: properPageOffset(location.pathname),
  });

  const canUserSeeNavigation = user?.roles.map((role) => role.includes('ADMIN'));

  return canUserSeeNavigation ? (
    <div ref={containerRef}>
      <div className="routes-dropdown">
        <button ref={setReferenceElement} className="routes-dropdown__toggler" onClick={() => toggleOpen()}>
          {children}
        </button>
        {isOpen && (
          <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="routes-dropdown__content">
            <ul className="routes-dropdown__nav">
              <div className="routes-dropdown__user-routes">
                <li>
                  <NavLink to={Routes.DASHBOARD} className={(isActive) => (isActive ? 'current ' : '')}>
                    <i className="far fa-credit-card-blank" />
                    <span className="first-letter-capital">{t('projects')}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={Routes.ARCHIVE} className={(isActive) => (isActive ? 'current' : '')}>
                    <i className="far fa-archive" />
                    <span className="first-letter-capital">{t('my archive')}</span>
                  </NavLink>
                </li>
              </div>
              {user && isAdmin(user) && (
                <div className="routes-dropdown__admin-routes">
                  <li>
                    <NavLink to={Routes.USERS} className={(isActive) => (isActive ? 'current' : '')}>
                      <i className="far fa-users" />
                      <span className="first-letter-capital">{t('members list')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.TAG} className={(isActive) => (isActive ? 'current' : '')}>
                      <i className="far fa-tags" />
                      <span className="first-letter-capital">{t('tags')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.DELETED_PROJECTS} className={(isActive) => (isActive ? 'current' : '')}>
                      <i className="far fa-trash-alt" />
                      <span className="first-letter-capital">{t('deleted projects')}</span>
                    </NavLink>
                  </li>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
