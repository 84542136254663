import React from 'react';

import './TableZeroState.scss';

interface IProps {
  children: React.ReactNode;
  type?: 'regular' | 'table';
}

export default function TableZeroState({children, type = 'table'}: IProps) {
  return (
    <>
      {type === 'table' && (
        <tr className="table__zero-state">
          <td colSpan={42}>
            <div>{children}</div>
          </td>
        </tr>
      )}
      {type === 'regular' && (
        <div className="table__zero-state">
          <div>{children}</div>
        </div>
      )}
    </>
  );
}
