import MembersTruncatedList from 'components/MembersTruncatedList/MembersTruncatedList';
import {SingleProject} from 'typescript/models/SingleProject';

interface IProps {
  project: SingleProject;
}

export const Members = ({project}: IProps) => {
  const membersLength = project.members.length;
  const firstMemberName = project.members[0]?.name || '';

  return (
    <div className="table__truncated">
      {membersLength > 0 ? (
        <MembersTruncatedList membersLength={membersLength} firstMemberName={firstMemberName} />
      ) : (
        <p>/</p>
      )}
    </div>
  );
};
