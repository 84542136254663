import {Routes} from 'constants/routes';

import GlobalNotification from 'components/GlobalNotification/GlobalNotification';
import ModalPortal from 'components/Modal/ModalPortal';
import ServerErrors from 'components/ServerErrors/ServerErrors';
import {ConnectedRouter} from 'connected-react-router';
import {Archive} from 'pages/archive/Archive';
import Dashboard from 'pages/dashboard/Dashboard';
import DeletedProjects from 'pages/deletedProjects/DeletedProjects';
import {ForgotPassword} from 'pages/ForgotPassword/ForgotPassword';
import Login from 'pages/login/Login';
import {NotFound} from 'pages/NotFound/NotFound';
import {PasswordChange} from 'pages/PasswordChange/PasswordChange';
import {ApiSetupRegistration} from 'pages/register/ApiSetupRegistration/ApiSetupRegistration';
import Register from 'pages/register/Register';
import {TagPage} from 'pages/tag/Tags';
import {Users} from 'pages/users/Users';
import {Switch, Route, Redirect} from 'react-router-dom';
import {history} from 'utils/history/history';

import {getLoggedRouteList} from './getLoggedRouteList';
import PrivateRoute from './PrivateRoute';

export default function AppRouter() {
  const token = localStorage.getItem('token');
  getLoggedRouteList({token});

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.HOME} exact>
          {token ? <Redirect to={Routes.DASHBOARD} /> : <Redirect to={Routes.LOGIN} />}
        </Route>
        <Route path={Routes.LOGIN} exact component={Login} />
        <Route path={Routes.REGISTER} component={Register} exact />
        <Route path={Routes.API_REGISTRATION_SETUP} component={ApiSetupRegistration} exact />
        <Route path={Routes.PASSWORD_CHANGE} component={PasswordChange} exact />
        <Route path={Routes.RESET_PASSWORD} component={ForgotPassword} exact />

        <PrivateRoute path={Routes.DASHBOARD} exact component={Dashboard} />
        <PrivateRoute
          path={`${Routes.DASHBOARD}/:id`}
          render={({match}) => {
            const projectId = Number(match.params.id);
            const shouldNotRedirect = Number.isNaN(projectId);
            if (shouldNotRedirect) return <Route path="*" component={NotFound} />;
            return <Dashboard id={projectId} />;
          }}
        />
        <PrivateRoute path={Routes.USERS} exact component={Users} />
        <PrivateRoute path={Routes.ARCHIVE} exact component={Archive} />
        <PrivateRoute path={Routes.TAG} exact component={TagPage} />
        <PrivateRoute path={Routes.DELETED_PROJECTS} exact component={DeletedProjects} />
        <Route path="*" component={NotFound} />
      </Switch>
      <ServerErrors />
      <GlobalNotification />
      <ModalPortal />
    </ConnectedRouter>
  );
}
