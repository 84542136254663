import {User} from 'typescript/models/User';

export function isUser(user: User) {
  const role = user.roles.find((x) => x === 'USER');

  return role ? true : false;
}

export function isAdmin(user: User) {
  const role = user.roles.find((x) => x === 'ADMIN');

  return role ? true : false;
}
