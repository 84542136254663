import ContextMenu from 'components/Sidebar/ContextMenu';
import useDropdown from 'hooks/useDropdown';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaBreakpoint} from 'hooks/useMediaBreakpoint/useMediaBreakpoint';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useTranslation} from 'react-i18next';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';

export const DeleteTableDropdown = () => {
  const isTablet = useMediaBreakpoint('down', 'lg');
  let offset: [number, number] = [40, 0];
  if (isTablet) offset = [40, -42];

  const {t} = useTranslation();

  const {styles, attributes, isOpen, toggleOpen, setReferenceElement, setPopperElement, containerRef} = useDropdown({
    offset,
  });

  const dispatch = useSetGlobalState();
  const {checkedProjects} = useGlobalState((state) => state.deletedProjects);

  const handleActivateProjects = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!checkedProjects.length) return;
    dispatch(toggleModalAC({isOpen: true, component: ModalComponents.CONFIRM_ACTIVATE_PROJECT}));
  };

  return (
    <div ref={containerRef}>
      <button className="header__action header__action--more" onClick={() => toggleOpen()} ref={setReferenceElement}>
        <i className="far fa-ellipsis-v" />
      </button>
      {isOpen && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ContextMenu>
            <ul className="sidebar__dropdown">
              <li>
                <a href="/" onClick={handleActivateProjects}>
                  <i className="far fa-inbox-out" />
                  {t('activate project')}
                </a>
              </li>
            </ul>
          </ContextMenu>
        </div>
      )}
    </div>
  );
};
