import Dropdown from 'components/Dropdown/Dropdown';

export const DefaultDropdown = () => {
  return (
    <Dropdown
      toggler={(props) => {
        return (
          <button className="header__action header__action--more" {...props}>
            <i className="far fa-ellipsis-v" />
          </button>
        );
      }}
      content={() => {
        return (
          <ul className="sidebar__dropdown">
            <li>
              <a href="/">
                <i className="fal fa-envelope" />
                Pošaljite poziv
              </a>
            </li>
            <li>
              <a href="/">
                <i className="far fa-archive" />
                Arhivirajte
              </a>
            </li>
            <li>
              <a href="/">
                <i className="far fa-edit" />
                Izmenite
              </a>
            </li>
            <li>
              <a href="/">
                <i className="far fa-trash-alt" />
                Obrišite
              </a>
            </li>
          </ul>
        );
      }}
      position="bottom-start"
    />
  );
};
