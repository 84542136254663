import {Member} from 'typescript/models/Member';
import {SingleProject} from 'typescript/models/SingleProject';

interface IProps {
  user: Member;
  handleCheck: (user: Member) => void;
  singleProject: SingleProject;
}

export const OrganizersList = ({user, singleProject, handleCheck}: IProps) => {
  return (
    <li onClick={() => handleCheck(user)} onKeyDown={() => handleCheck(user)}>
      <p>
        <span>
          <small className="name">{user.name}</small>
          <small className="email">{user.email}</small>
        </span>
      </p>
      {singleProject.organizers.find((organizer) => organizer.id === user.id) && (
        <span className="popup__select__check">
          <i className="far fa-check" />
        </span>
      )}
    </li>
  );
};
