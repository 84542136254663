import {useTranslation} from 'react-i18next';

interface IProps {
  handleAddRemainder: () => void;
  handleToggle: () => void;
  numOfDays: number | undefined;
  timeStamp: Date | null;
  errorMessage: boolean;
}

export const SaveAndCloseButton = ({errorMessage, handleAddRemainder, handleToggle, numOfDays, timeStamp}: IProps) => {
  const {t} = useTranslation();
  return (
    <div className="project-remainder__buttons">
      <p>{t('add-remainder-description')}</p>
      <button
        className="btn btn--primary"
        onClick={handleAddRemainder}
        disabled={!errorMessage && (!timeStamp || !numOfDays)}
      >
        {t('save')}
      </button>
      <button className="btn btn--secondary" onClick={handleToggle}>
        {t('quit')}
      </button>
    </div>
  );
};
