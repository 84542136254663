import {Routes} from 'constants/routes';

import {ChangeEvent, Ref} from 'react';

import {DebouncedFunc} from 'lodash';
import './SearchInput.scss';
import {useLocation} from 'react-router';

type Event = ChangeEvent<HTMLInputElement>;
interface IProps {
  placeholder?: string;
  id: string;
  onChange: ((e: Event) => {}) | DebouncedFunc<(e: Event) => Promise<void>> | ((e: Event) => Promise<void>);
  forwardRef?: Ref<HTMLDivElement>;
  onFocus?: () => void;
}
export const SearchInput = ({id, placeholder, onChange, forwardRef, onFocus = () => {}}: IProps) => {
  const location = useLocation();
  const pages = [Routes.DASHBOARD, Routes.ARCHIVE, Routes.USERS, Routes.TAG, Routes.DELETED_PROJECTS];
  const isNotFoundPage = !pages.includes(location.pathname);

  return (
    <div className="search-input" ref={forwardRef}>
      <input
        disabled={isNotFoundPage}
        type="text"
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        autoComplete="off"
      />
      <label htmlFor={id}>
        <i className="fal fa-search" />
      </label>
    </div>
  );
};
