import {useEffect, useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Member} from 'typescript/models/Member';

interface IProps {
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  styles: {[key: string]: React.CSSProperties};
  attributes: {[key: string]: {[key: string]: string} | undefined};
  toggleOpen: () => void;
  isOpen: boolean;
  setReferenceElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  listOfUsers: Member[] | null;
}

const AddMention = ({
  listOfUsers: filteredUserList,
  setComment,
  setPopperElement,
  styles,
  attributes,
  toggleOpen,
  isOpen,
  setReferenceElement,
  containerRef,
}: IProps) => {
  const {users} = useGlobalState((state) => state.users);
  const {t} = useTranslation();

  const [listOfUsers, setListOfUsers] = useState<Member[] | null>([]);

  const handleSelect = (user: Member) => {
    setComment((currentComment) => {
      if (currentComment.includes(user.email)) {
        return currentComment;
      }

      if (currentComment[currentComment.length - 1] === '@') {
        return currentComment.concat(`${user.name.split(' ').join('')}`);
      }
      return currentComment.concat(` @${user.name.split(' ').join('')}`);
    });
    toggleOpen();
  };

  const handleDropdownOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  useEffect(() => {
    setListOfUsers(filteredUserList);
  }, [filteredUserList]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchedValue = e.target.value;

    if (searchedValue === '') setListOfUsers(users);
    else if (listOfUsers) {
      const filteredUsers = listOfUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
          user.email.toLowerCase().includes(searchedValue.toLowerCase())
        );
      });

      setListOfUsers(filteredUsers);
    }
  };

  const debouncedSearch = debounce(handleSearch, 500);

  return (
    <div className="dropdown" ref={containerRef}>
      <div className="dropdown__toggler" ref={setReferenceElement}>
        <span className="project__comment__controls__control" role="button" onClick={(e) => handleDropdownOpen(e)}>
          <img src="/images/at-sign.png" alt="" />
        </span>
      </div>

      {isOpen && (
        <div id="popup_organizer" className="popup" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <header className="popup__header">
            <h4>{t('users')}</h4>
            <button className="btn-close" onClick={(e) => handleDropdownOpen(e)}>
              <i className="fal fa-times" />
            </button>
          </header>
          <section className="popup__body popup__body--borderless">
            <input type="text" placeholder={`${t('Search users')}`} onChange={debouncedSearch} />
            <h3>{t('all users')}</h3>
            <ul className="popup__select">
              {listOfUsers?.map((user) => {
                return (
                  <li key={`uid-${user.id}`} onClick={() => handleSelect(user)} onKeyDown={() => handleSelect(user)}>
                    <p>
                      <span>
                        {user.name}
                        <small>{user.email}</small>
                      </span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      )}
    </div>
  );
};

export default AddMention;
